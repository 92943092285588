import React from 'react'
import { useStoreUtil } from '../../utils/useStore.util'
import { ISourceStore } from './source.interface.store'
import { ISource, sourceFactory } from './source.model.store'
import { useSourceService } from './source.service.store'

const useSourceStore = (): ISourceStore => {
    
    const storeUtil = useStoreUtil()
    const sourceService = useSourceService()

    const [sourceList, setSourceList] = React.useState<ISourceStore['sourceList']>([])

    const getSourceList: ISourceStore['getSourceList'] = async () => {
        const response = await sourceService.getSourceList()
        if(response.isError) return storeUtil.createErrorResult([])
        setSourceList(response.data)
        return storeUtil.createSuccessResult(response.data)
    }

    const getSource: ISourceStore['getSource'] = async (sourceId) => {
        const response = await sourceService.getSource(sourceId)
        if(response.isError) return storeUtil.createErrorResult(sourceFactory())
        setSourceList([...sourceList, response.data])
        return storeUtil.createSuccessResult(response.data)
    }

    const createSource: ISourceStore['createSource'] = async (source) => {
        const response = await sourceService.createSource(source)
        if(response.isError) return storeUtil.createErrorResult(null)
        setSourceList([...sourceList, response.data])
        return storeUtil.createSuccessResult(null)
    }

    const updateSource: ISourceStore['updateSource'] = async (sourceUpdated) => {
        const response = await sourceService.updateSource(sourceUpdated)
        if(response.isError) return storeUtil.createErrorResult(null)
        setSourceList(sourceList.map(sourse=> sourse.id === sourceUpdated.id ? sourceUpdated : sourse))
        return storeUtil.createSuccessResult(null)
    }

    const deleteSource: ISourceStore['deleteSource'] = async (sourceId) => {
        const response = await sourceService.deleteSource(sourceId)
        if(response.isError) return storeUtil.createErrorResult(null)
        setSourceList(sourceList.filter(source=> source.id !== sourceId))
        return storeUtil.createSuccessResult(null)
    }

    const syncSource: ISourceStore['syncSource'] = async (sourceId) => {
        const response = await sourceService.syncSource(sourceId)
        if(response.isError) return storeUtil.createErrorResult(null)
        return storeUtil.createSuccessResult(null)
    }

    return { sourceList, getSourceList, getSource, createSource, updateSource, deleteSource, syncSource }
}

export { useSourceStore }