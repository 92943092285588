import { ICodeEditor, TForm, IInputNumber, IInputOptions, IInputText, getValueToForm } from "FormGenerator"
import { advancedCalculatorNodeFormScheme, binaryNodeFormScheme, calculatorNodeFormScheme, callBureauNodeFormScheme, callPolicyNodeFormScheme, finishNodeFormScheme, rootNodeFormScheme, TOptionNodeType } from "../schemes/node.form.scheme"
import { IFieldGroup, IRisk } from "../stores/risk/risk.model.store"
import { IAdvancedCalculatorNode, IBox, ILineConector, IPolicyNode, ITrace, TDiagram, TNode } from "../stores/trace/trace.model.store"
import { setup } from '../utils/setup'


export const addCurrencyCountryLimitsToFinishNodeSchema = (formData: TForm) => {
    return formData.map(input => {
        if (input.id === '5') return { ...input, label: setup.country === 'Argentina' ? 'Con limite en ARS:' : 'Con limite en MXN:' }
        if (input.id === '6') return { ...input, label: setup.country === 'Argentina' ? 'Limite en ARS:' : 'Limite en MXN:' }
        return input
    })
}

export const preloadFormDataValuesForPolicyNodeForm = (formData: TForm, policyNode: IPolicyNode): TForm => {


    return formData.map(formDataInput => {
        const newFormDataInputKey =
            formDataInput.type === 'text' || formDataInput.type === 'number' || formDataInput.type === 'code' || formDataInput.type === 'options'
                ? formDataInput.label : ''
        const inputPolicyNodeFinded = policyNode.properties.inputs.find(nodeInput => nodeInput.key === newFormDataInputKey)

        if(inputPolicyNodeFinded) return({...formDataInput, value: inputPolicyNodeFinded.value})

        return formDataInput
    })
}

export const mapFieldGroupToIForm = (fieldGroupList: Array<IFieldGroup>): TForm => {
    const newFormFormReturn: TForm = []
    let currentIdForIterator: string = '10'

    fieldGroupList.forEach(fieldGroup => {
        fieldGroup.fields.forEach(field => {
            if (field.type === 'number') {
                const newInputNumber: IInputNumber = {
                    id: currentIdForIterator,
                    type: 'number',
                    label: field.name,
                    value: '',
                    maxValue: 99999999999,
                    minValue: 0,
                    placeholder: field.description,
                    required: field.required,
                    error: false,
                    disabled: false,
                    size: 12,
                    hidden: false,
                }

                newFormFormReturn.push(newInputNumber)
            }
            if (field.type === 'string') {
                const newInputText: IInputText = {
                    id: currentIdForIterator,
                    type: 'text',
                    label: field.name,
                    value: '',
                    placeholder: field.description,
                    required: field.required,
                    error: false,
                    minLength: 0,
                    maxLength: 5000,
                    disabled: false,
                    size: 12,
                    regex: '',
                    hidden: false
                }
                newFormFormReturn.push(newInputText)
            }
            if (field.type === 'option') {
                const newInputOption: IInputOptions = {
                    id: currentIdForIterator,
                    type: 'options',
                    label: field.name,
                    value: '',
                    placeholder: field.description,
                    required: field.required,
                    options: field.list.map(item => ({ value: item.value, label: item.label })),//Array<{ value: string, label: string }>,
                    error: false,
                    disabled: false,
                    size: 12,
                    hidden: false
                }
                newFormFormReturn.push(newInputOption)
            }
            if (field.type === 'json') {
     
                const newJsonInput: ICodeEditor = {
                    id: currentIdForIterator,
                    type: 'code',
                    label: field.name,
                    value: '',
                    language: "json",
                    required: field.required,
                    error: false,
                    disabled: false,
                    size: 12,
                    hidden: false
                }

                newFormFormReturn.push(newJsonInput)

            }
            const newCurrentId = Number(currentIdForIterator) + 1
            currentIdForIterator = String(newCurrentId)
        })
    })

    return newFormFormReturn
}

/////


export const onChangeAdvanceCalculatorNodeHandler = (formData: TForm): TForm => {
    return formData.map(input=>{

        if(input.id === '2' && input.type === 'text' && /![a-zA-Z0-9_]*/.test(input.value)) {
            return {...input, error: true}
        }

        return input
    })
}

export const onChangeFinishNodeHandler = (formData: TForm): TForm => {

    const isPesosLimitAviable = getValueToForm(formData, '5')
    const isDolarsLimitAviable = getValueToForm(formData, '7')

    let newFormData: TForm = [...formData]

    newFormData = newFormData.map(item => {
        if (item.id === '6' && isPesosLimitAviable === 'true') return { ...item, required: true, disabled: false, placeholder: 'Campo Requerido...' }
        if (item.id === '6' && isPesosLimitAviable === 'false') return { ...item, value: '', required: false, disabled: true, placeholder: '' }

        if (item.id === '8' && isDolarsLimitAviable === 'true') return { ...item, required: true, disabled: false, placeholder: 'Campo Requerido...' }
        if (item.id === '8' && isDolarsLimitAviable === 'false') return { ...item, value: '', required: false, disabled: true, placeholder: '' }

        return item
    })

    return newFormData
}


export const addPreloadDataToFormDataSchema = (formData: TForm, node: TNode): TForm => {
    // todo: refactoriar esto para que se deje de comparar numero

    if (node.nodeType === 0) return formData

    if (node.nodeType === 1) return formData.map(input => {
        if (input.id === '2') return { ...input, value: node.properties.binary_condition }
        return input
    })

    if (node.nodeType === 2) return formData.map(input => {
        if (input.id === '2') return { ...input, value: node.properties.name }
        if (input.id === '3') return { ...input, value: node.properties.type }
        if (input.id === '4') return { ...input, value: node.properties.formula }
        return input
    })

    if (node.nodeType === 3) return formData.map(input => {
        if (input.id === '2') return { ...input, value: node.properties.result_name }
        if (input.id === '3') return { ...input, value: String(node.properties.risk_policy_id) }

        return input
    })

    if (node.nodeType === 4) {
        const pesosLimitFinded = node.properties.amount_limits.find(limit => limit.currency === 'ARS' || limit.currency === 'MXN')
        const dolarsLimitFinded = node.properties.amount_limits.find(limit => limit.currency === 'USD')

        return formData.map(input => {
            if (input.id === '2') return { ...input, value: node.properties.opinion }
            if (input.id === '3') return { ...input, value: String(node.properties.status) }
            if (input.id === '5') return { ...input, value: pesosLimitFinded && pesosLimitFinded.value ? 'true' : 'false' }
            if (input.id === '6') return {
                ...input,
                value: pesosLimitFinded && pesosLimitFinded.value ? pesosLimitFinded.value : '',
                disabled: pesosLimitFinded && pesosLimitFinded.value ? false : true,
                placeholder: pesosLimitFinded && pesosLimitFinded.value ? 'Campo Requerido...' : ''
            }
            if (input.id === '7') return { ...input, value: dolarsLimitFinded && dolarsLimitFinded.value ? 'true' : 'false' }
            if (input.id === '8') return {
                ...input,
                value: dolarsLimitFinded && dolarsLimitFinded.value ? dolarsLimitFinded.value : '',
                disabled: dolarsLimitFinded && dolarsLimitFinded.value ? false : true,
                placeholder: dolarsLimitFinded && dolarsLimitFinded.value ? 'Campo Requerido...' : ''
            }
            return input
        })
    }

    if (node.nodeType === 5) return formData.map(input => {

        if (input.id === '2') return { ...input, value: node.properties.id_persona }
        if (input.id === '3') return {
            ...input,
            value: node.properties.bureau_list.find(number => number === 0) !== undefined ? 'true' : 'false'
        }
        if (input.id === '4') return {
            ...input,
            value: node.properties.bureau_list.find(number => number === 1) !== undefined ? 'true' : 'false'
        }
        return input
    })


    if (node.nodeType === 6) return formData.map(input => {
        if (input.id === '2') return { ...input, value: node.properties.name }
        if (input.id === '3') return { ...input, list: node.properties.arguments_in }
        if (input.id === '4') return { ...input, value: node.properties.return_type }
        if (input.id === '5') return { ...input, value: node.properties.function }
        return input
    })

    return []
}

export const getFormDataShema = (nodeType: TNode['nodeType']): TForm => {
    if (nodeType === 0) return rootNodeFormScheme
    if (nodeType === 1) return binaryNodeFormScheme
    if (nodeType === 2) return calculatorNodeFormScheme
    if (nodeType === 3) return callPolicyNodeFormScheme
    if (nodeType === 4) return finishNodeFormScheme
    if (nodeType === 5) return callBureauNodeFormScheme
    if (nodeType === 6) return advancedCalculatorNodeFormScheme
    return []
}


export const createBox = (nodeTypeSelected: TOptionNodeType, idEnabled: number, coordinates: { x: number, y: number }): IBox => {

    let newBox: IBox = {
        id: String(idEnabled),
        type:
            nodeTypeSelected === 'root' ? 'root' :
                nodeTypeSelected === 'binary' ? 'binary' :
                    nodeTypeSelected === 'finish' ? 'finish' :
                        nodeTypeSelected === 'calculator' ? 'calculator' :
                            nodeTypeSelected === 'advancedCalculator' ? 'advancedCalculator' :
                                nodeTypeSelected === 'bureau' ? 'bureau' :
                                    nodeTypeSelected === 'policy' ? 'policy'
                                        : 'root',
        data: {
            onRemove: () => { },
            label: `${nodeTypeSelected}Node:${String(idEnabled)}`,
        },
        position: {
            x: -(coordinates.x),
            y: -(coordinates.y)
        }
    }
    return newBox
}



export const createIdEnabled = (diagram: TDiagram) => {

    const idEnabled: number = diagram.reduce(
        (acumulator, current) => Number(current.id) > acumulator ? Number(current.id) : acumulator
        , 0) + 1

    return idEnabled
}


export const createNodeTrace = (
    nodeTypeSelected: TOptionNodeType,
    form: TForm,
    idEnabled: number
): TNode => {

    if (nodeTypeSelected === 'root') return {
        id: idEnabled,
        nodeType: 0,
        properties: {},
        slots: [0, 0],
    }

    if (nodeTypeSelected === 'binary') return {
        id: idEnabled,
        nodeType: 1,
        properties: {
            binary_condition: getValueToForm(form, '2')
        },
        out_true: 0,
        out_false: 0
    }

    if (nodeTypeSelected === 'bureau') {

        const newBureauList: Array<number> = []

        if (getValueToForm(form, '3') === 'true') newBureauList.push(0)
        if (getValueToForm(form, '4') === 'true') newBureauList.push(1)

        return {
            id: idEnabled,
            nodeType: 5,
            properties: {
                bureau_list: newBureauList,
                id_persona: getValueToForm(form, '2')
            },
            slots: []
        }
    }

    if (nodeTypeSelected === 'policy') {
        const dinamicInputs = form.filter(input => Number(input.id) >= 10)
        return {
            id: idEnabled,
            nodeType: 3,
            properties: {
                inputData: {},
                result_name: getValueToForm(form, '2'),
                risk_policy_id: Number(getValueToForm(form, '3')),
                inputs: dinamicInputs.map(input => {

                    if (input.type === 'number' || input.type === 'text' || input.type === 'options' || input.type === 'code') {
                        return {
                            key: input.label,
                            type: input.type === 'text' ? 'string' : input.type === 'number' ? 'number' : 'json',
                            value: input.value
                        }
                    }
                    // aka
                    return {
                        key: '',
                        type: 'string',
                        value: ''
                    }
                })
            },
            out: 0
        }
    }

    if (nodeTypeSelected === 'calculator') return {
        id: idEnabled,
        nodeType: 2,
        properties: {
            name: getValueToForm(form, '2'),
            type: getValueToForm(form, '3') === 'string' ? 'string' : 'number',
            formula: getValueToForm(form, '4'),
        },
        slots: []
    }

    if (nodeTypeSelected === 'advancedCalculator') {
        const advancedCalculatorNode: IAdvancedCalculatorNode = {
            id: idEnabled,
            nodeType: 6,
            properties: {
                name: getValueToForm(form, '2'),
                arguments_in: getValueToForm(form, '3') ? getValueToForm(form, '3').split(',') : [],
                return_type:
                    getValueToForm(form, '4') === 'List' ? 'List' :
                        getValueToForm(form, '4') === 'Dict' ? 'Dict' :
                            getValueToForm(form, '4') === 'int' ? 'int' :
                                getValueToForm(form, '4') === 'bool' ? 'bool' :
                                    getValueToForm(form, '4') === 'str' ? 'str' : 'str',
                function: getValueToForm(form, '5')
            },
            out: 0
        }
        return advancedCalculatorNode
    }

    if (nodeTypeSelected === 'finish') {
        const amount_limits: Array<{ currency: 'ARS' | 'MXN' | 'USD', value: string }> = []

        if (getValueToForm(form, '5') === 'true') {
            amount_limits.push({
                currency: setup.country === 'México' ? 'MXN' : 'ARS',
                value: getValueToForm(form, '6')
            })
        }

        if (getValueToForm(form, '7') === 'true') {
            amount_limits.push({
                currency: 'USD',
                value: getValueToForm(form, '8')
            })
        }

        return {
            id: idEnabled,
            nodeType: 4,
            properties: {
                opinion: getValueToForm(form, '2'),
                status: getValueToForm(form, '3') === '0' ? 0
                    : getValueToForm(form, '3') === '1' ? 1
                        : getValueToForm(form, '3') === '2' ? 2
                            : getValueToForm(form, '3') === '3' ? 3
                                : 0,
                amount_limits: amount_limits
            },

            slots: []
        }
    }

    return {
        id: idEnabled,
        nodeType: 0,
        properties: {},
        slots: [],
    }
}


export const getPreloadDataToNodeFormData = (node: TNode) => {
    const formDataSchemaObtained = getFormDataShema(node.nodeType)
    if (node.nodeType === 4) return addPreloadDataToFormDataSchema(addCurrencyCountryLimitsToFinishNodeSchema(formDataSchemaObtained), node)
    else return addPreloadDataToFormDataSchema(formDataSchemaObtained, node)
}

export const createUpdateTraceNode = (form: TForm, nodeTrace: TNode): TNode => {

    if (nodeTrace.nodeType === 0) {
        return { ...nodeTrace }
    }

    if (nodeTrace.nodeType === 1) {
        return {
            ...nodeTrace,
            properties: {
                binary_condition: getValueToForm(form, '2')
            }
        }
    }

    if (nodeTrace.nodeType === 2) {
        return {
            ...nodeTrace,
            properties: {
                name: getValueToForm(form, '2'),
                type: getValueToForm(form, '3') === 'string' ? 'string' : 'number',
                formula: getValueToForm(form, '4')
            }
        }
    }

    if (nodeTrace.nodeType === 3) {
        const dinamicFormsFiltered = form.filter(inputFormData=> Number(inputFormData.id) >= 10)
        const policyNode: IPolicyNode = {
            ...nodeTrace,
            id: nodeTrace.id,
            nodeType: 3,
            properties: {
                result_name: getValueToForm(form, '2'),
                risk_policy_id: Number(getValueToForm(form, '3')),
                inputData: {},
                inputs: dinamicFormsFiltered.map(dinamicInputFormData=>{
                    return({
                        "key": 
                            dinamicInputFormData.type === 'text' ||
                            dinamicInputFormData.type === 'number' ||
                            dinamicInputFormData.type === 'options' ||
                            dinamicInputFormData.type === 'code' ? dinamicInputFormData.label : '',
                        "type": 
                            dinamicInputFormData.type === 'text' ? 'string' :
                            dinamicInputFormData.type === 'number' ? 'number' :
                            dinamicInputFormData.type === 'options' ? 'string' :
                            dinamicInputFormData.type === 'code' ? 'json' : 'string',
                        "value":     
                            dinamicInputFormData.type === 'text' ||
                            dinamicInputFormData.type === 'number' ||
                            dinamicInputFormData.type === 'options' ||
                            dinamicInputFormData.type === 'code' ? dinamicInputFormData.value : ''
                    })
                })
            },
            out: nodeTrace.out
        }

        return policyNode
    }


    if (nodeTrace.nodeType === 4) {


        const amount_limits: Array<{ currency: 'ARS' | 'MXN' | 'USD', value: string }> = []

        if (getValueToForm(form, '5') === 'true') {
            amount_limits.push({
                currency: setup.country === 'México' ? 'MXN' : 'ARS',
                value: getValueToForm(form, '6')
            })
        }

        if (getValueToForm(form, '7') === 'true') {
            amount_limits.push({
                currency: 'USD',
                value: getValueToForm(form, '8')
            })
        }

        return {
            id: nodeTrace.id,
            nodeType: 4,
            properties: {
                opinion: getValueToForm(form, '2'),
                status: getValueToForm(form, '3') === '0' ? 0
                    : getValueToForm(form, '3') === '1' ? 1
                        : getValueToForm(form, '3') === '2' ? 2
                            : getValueToForm(form, '3') === '3' ? 3
                                : 0,
                amount_limits: amount_limits
            },

            slots: []
        }
    }

    if (nodeTrace.nodeType === 5) {
        const newBureauList: Array<number> = []

        if (getValueToForm(form, '3') === 'true') newBureauList.push(0)
        if (getValueToForm(form, '4') === 'true') newBureauList.push(1)

        return {
            ...nodeTrace,
            properties: {
                bureau_list: newBureauList,
                id_persona: getValueToForm(form, '2')
            }
        }
    }

    const advancedCalculatorNode: IAdvancedCalculatorNode = {
        id: nodeTrace.id,
        nodeType: 6,
        properties: {
            name: getValueToForm(form, '2'),
            arguments_in: getValueToForm(form, '3') ? getValueToForm(form, '3').split(',') : [],
            return_type:
                getValueToForm(form, '4') === 'List' ? 'List' :
                    getValueToForm(form, '4') === 'Dict' ? 'Dict' :
                        getValueToForm(form, '4') === 'int' ? 'int' :
                            getValueToForm(form, '4') === 'bool' ? 'bool' :
                                getValueToForm(form, '4') === 'str' ? 'str' : 'str',
            function: getValueToForm(form, '5')
        },
        out: nodeTrace.out
    }

    console.log('advancedCalculatorNode', advancedCalculatorNode)

    return advancedCalculatorNode


}




export const removeNodeConectionUsingElements = (elements: TDiagram, trace: ITrace['trace']): ITrace['trace'] => {
    
    let nodeIdElementFinded: number = Number(elements.find(element => element.type !== 'line')?.id) || 0
    const targetIdList = elements.filter(element => element.type === 'line').map(line => line.type === 'line' ? Number(line.target) : 0)
    const sourceIdList = elements.filter(element => element.type === 'line').map(line => line.type === 'line' ? Number(line.source) : 0)

    const targetId = targetIdList[0] ? targetIdList[0] : 0
    const sourceId = sourceIdList[0] ? sourceIdList[0] : 0

    const newTrace: ITrace['trace'] = [...trace]
        .filter(node => node.id !== nodeIdElementFinded)
        .map(node => {
            if(node.id === sourceId) {
                if (node.nodeType === 1) return {
                    ...node,
                    out_true: targetIdList.find(targetId => targetId === node.out_true) ? 0 : node.out_true,
                    out_false: targetIdList.find(targetId => targetId === node.out_false) ? 0 : node.out_false
    
                }
                if (node.nodeType === 6 || node.nodeType === 3) return {
                    ...node,
                    out: targetIdList.find(targetId => targetId === node.out) ? 0 : node.out,
                }
                else return {
                    ...node,
                    slots: node.slots.filter(slot => !targetIdList.find(targetId => targetId === slot))
                }
            }else{
                return {...node}
            }
        })

    return newTrace
}

const addSlotsToBinaryNode = (line: ILineConector, node: TNode) => {
    if (line.sourceHandle === 'a' && node.nodeType === 1) {
        const newNode = { ...node }
        newNode.out_true = Number(line.target)
        return newNode
    }
    if (line.sourceHandle === 'b' && node.nodeType === 1) {
        const newNode = { ...node }
        newNode.out_false = Number(line.target)
        return newNode
    }
    return { ...node }
}

const addSlotsToNoBinaryNode = (line: ILineConector, node: TNode) => {
    const newNode = { ...node }
    if (newNode.nodeType === 1 || newNode.nodeType == 6 || newNode.nodeType == 3) return newNode
    newNode.slots = [Number(line.target)]
    return newNode
}

const addOutToNode = (line: ILineConector, node: IAdvancedCalculatorNode | IPolicyNode): IAdvancedCalculatorNode | IPolicyNode => ({
    ...node,
    out: Number(line.target)
})

const addSlotsToNode = (line: ILineConector, node: TNode) => {
    if (node.nodeType === 1) return addSlotsToBinaryNode(line, node)
    if (node.nodeType === 6) return addOutToNode(line, node)
    if (node.nodeType === 3) return addOutToNode(line, node)
    return addSlotsToNoBinaryNode(line, node)
}

export const addNodeConnectionUsingElement = (line: ILineConector, trace: ITrace['trace']): ITrace['trace'] => {

    const newTrace: ITrace['trace'] = [...trace].map(node => {
        if (node.id === Number(line.source)) return addSlotsToNode(line, node)
        else return node
    })
    return newTrace
}


/////////////////////////////////////////////////////

export const pushNewAnyListToAnyRecord = <anyType>(newAny: anyType, anyRecord: Array<anyType>): Array<anyType> => {

    if (anyRecord.length > 20) {
        return anyRecord.reduce((acumulator, current, index) => {
            if (index === 0) return acumulator
            return [...acumulator, current]
        }, [] as Array<anyType>)
    } else {
        return [...anyRecord, newAny]
    }

}


export const onRemoveNodeToTrace = (newTrace: ITrace['trace'], nodeIdForRemove: number): ITrace['trace'] => {
    return [...newTrace].filter(node => node.id !== nodeIdForRemove)
}


export const onRemoveNodeToDiagram = (diagram: TDiagram, nodeId: string): TDiagram => {
    const elementNodeFinded = [...diagram].find(element => element.id === nodeId)
    if (!elementNodeFinded) return [...diagram]

    const elementsLineFiltered = [
        ...diagram.filter(element => element.type === 'line' && (element.source === nodeId || element.target === nodeId))
    ]

    const newElementList = elementsLineFiltered.reduce((acumulator, current) => {
        return [...acumulator, current]
    }, [{ ...elementNodeFinded }] as TDiagram)

    const idListForDelete: Array<string> = newElementList.map(element => element.id)

    return diagram.filter(element => !idListForDelete.includes(element.id))

}


//////////

export const getNodeTypeForDiagram = (node: TNode): TOptionNodeType => {
    if (node.nodeType === 0) return 'root'
    if (node.nodeType === 1) return 'binary'
    if (node.nodeType === 2) return 'calculator'
    if (node.nodeType === 4) return 'finish'
    if (node.nodeType === 5) return 'bureau'
    if (node.nodeType === 6) return 'advancedCalculator'
    return 'root'
}

export const duplicateTraceNode = (node: TNode, idEnabled: number): TNode => {
    if (node.nodeType === 1) return { ...node, id: idEnabled, out_true: 0, out_false: 0 }
    if (node.nodeType === 6 || node.nodeType === 3) return { ...node, id: idEnabled, out: 0 }
    else return { ...node, id: idEnabled, slots: [] }
}

export const addRiskPolicyListToCallPolicyRiskNodeSheme =
    (riskList: Array<IRisk>, policyRiskNodeSheme: TForm): TForm => {
        return [...policyRiskNodeSheme].map(input => {
            if (input.id === '3' && input.type === 'options') return { ...input, options: riskList.map(risk => ({ value: String(risk.id), label: `#${risk.id}-${risk.name}` })) }
            else return input
        })
    }