import { FC, useEffect, useState } from 'react'
import { EaseInAnimation, PrimaryButton, Title } from '../elements/Element'
import { IAppStore } from '../stores/app/app.interface.store'
import {Row, Col} from '../elements/Grid.element'
import { useFile } from '../utils/useFile.util'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { IBureauStore } from '../stores/bureau/bureau.interface.store';

interface INipBureauReportPageProps {
    appStore: IAppStore
    bureauStore: IBureauStore
}

export const NipBureauReportPage: FC<INipBureauReportPageProps> = props => {

    const file = useFile()

    const [minValue, setMinValue] = useState<Date>(new Date());
    const [maxValue, setMaxValue] = useState<Date>(new Date());

    const onChangeMinValue = (value: Date) => {
        setMinValue(value)
    }

    const onChangeMaxValue = (value: Date) => {
        setMaxValue(value)
    }

    const onSubmit = async () => {

        const newMinDate = new Date(minValue)
        newMinDate.setHours(0)
        newMinDate.setMinutes(0)
        newMinDate.setSeconds(0)
        newMinDate.setMilliseconds(0)

        const newMaxDate = new Date(maxValue)
        newMaxDate.setHours(23)
        newMaxDate.setMinutes(59)
        newMaxDate.setSeconds(59)
        newMaxDate.setMilliseconds(999)

        props.appStore.showLoader()
        const result = await props.bureauStore.executeDownloadNipReportCSV({
            start_date: newMinDate.toISOString(),
            end_date: newMaxDate.toISOString(),
        })
        props.appStore.hideLoader()
    
        if(result.isError) return props.appStore.openApiErrorAlert()

        const newFileName: string = `nip-report-${new Date().toString}`
        file.downloadFile(new Blob([result.data]), `${newFileName}.csv`)
    }

    return (
        <EaseInAnimation>
            <br/>            
            <Row horizontalAlign='center' verticalAlign='center'>
                <Col size={12} horizontalAlign='center' verticalAlign='center'>
                    <Title>
                        Informe NIP Bureau
                    </Title>
                </Col>
            </Row>
            
            <Row horizontalAlign='center' verticalAlign='up'>
                <Col size={6} horizontalAlign='center' verticalAlign='center'>
                    <br/>
                    <label>Fecha de inicio</label>
                    <br/>
                    <Calendar onChange={onChangeMinValue} value={minValue} maxDate={maxValue}/>
                </Col>
                <Col size={6} horizontalAlign='center' verticalAlign='center'>
                    <br/>
                    <label>Fecha de finalizacion</label>
                    <br/>
                    <Calendar onChange={onChangeMaxValue} value={maxValue} minDate={minValue} maxDate={new Date()}/>
                </Col>
                <Row horizontalAlign='center' verticalAlign='center'>
                    <br/>
                    <br/>
                </Row>
                <Row horizontalAlign='center' verticalAlign='center'>
                    <Col size={12} horizontalAlign='center' verticalAlign='center'>
                        <PrimaryButton disabled={false} onClick={onSubmit}>Descargar</PrimaryButton>
                    </Col>
                </Row>
            </Row>
            <br/>
        </EaseInAnimation>
    )
}