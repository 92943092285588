import React from 'react';
import { AlertCardCloseButton, AlertCardElement, AlertCardText } from './Alert.element';

interface IAlertComponentProps {
    isOpen: boolean
    onClose: () => void
    text: string
    status: 'danger' | 'success'
}
export const AlertComponent : React.FC<IAlertComponentProps> = (props) => {
    return(
        <AlertCardElement isOpen={props.isOpen} status={props.status}>
            <AlertCardText>{props.text}</AlertCardText>
            <AlertCardCloseButton onClick={props.onClose}/>
        </AlertCardElement>
    )
}