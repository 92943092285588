import React, { useState } from 'react';

import ReactFlow, {
    MiniMap,
    Controls,
    Background,
    Edge,
    Connection
} from 'react-flow-renderer';

import { ILineConector, TDiagram, TElements } from '../../stores/trace/trace.model.store';
import { DiagramMockComponent } from './Diagram.mock.component';
import { edgeTypes, nodeTypes, TraceTitleContainer, TraceTitleInput, TraceTitleLabel } from './Elements.component';

const onLoad = (reactFlowInstance: any) => reactFlowInstance.fitView();

interface IDiagramComponentProps {
    title: string
    onChangeTitle: (title: string) => void
    diagram: TDiagram
    onMoveNode: (diagram: TDiagram) => void
    onNodeDoubleClick: (id: string) => void
    onRemoveLine: (elements: TDiagram) => void
    onConnectElements: (line: ILineConector) => void
    onMoveScreen: (params: {x: number, y: number}) => void
}

export const DiagramComponent = (props: IDiagramComponentProps) => {

    const onNodeDragStop = (event: any, node: any) => {
        // props.onMoveScreen({x: node.position.x, y: node.position.y})
        props.onMoveNode(props.diagram.map((ele: any) => ele.id === node.id ? node : ele))
    }

    const onElementClick = (event: any, element: any) => element.type === 'line' && props.onRemoveLine([element])


    const onConnect = (params: Edge<any> | Connection) => {
        const conectionFinded = props.diagram.find((element) => {
            if (element.type !== 'line') return false
            if (
                element.type === 'line'
                // porque puede ser un nodo binario
                && (element.source === params.source && !element.sourceHandle) // porque no tiene sourcehndle
                || (element.source === params.source && element.sourceHandle === params.sourceHandle)
                || element.id === `line-${params.source}-to-${params.target}`
                // || element.target === params.target // este impide que hayan nodos compartidos de arriba hacia abajo
            ) return true

            return false
        })

        if (!conectionFinded) {

            const newLineElement: ILineConector = {
                type: 'line',
                id: `line-${params.source}-to-${params.target}`,
                source: params.source || '',
                target: params.target || '',
                animated: true,
                sourceHandle: params.sourceHandle || '',
            }

            props.onConnectElements(newLineElement)
        }
    }

    const onNodeDoubleClickHandler = (event: any, node: any) => {
        props.onNodeDoubleClick(node.id)
    }

    const onMoveScreenHandler = (params: { x:number, y: number}) => {
        props.onMoveScreen({x:params.x, y: params.y})
        
    }
    return (
        process.env.NODE_ENV === 'test' ? (
            <DiagramMockComponent
                diagramProcessed={props.diagram}
                onElementClickHandler={onElementClick}
                onNodeDragStopHandler={onNodeDragStop}
                onNodeDoubleClickHandler={onNodeDoubleClickHandler}
                onConnectHandler={onConnect}
            />
        ) : (
            <ReactFlow
                onMoveEnd={(e:any)=>onMoveScreenHandler(e)}
                elements={props.diagram}
                onElementClick={onElementClick}
                onConnect={onConnect}
                onNodeDragStop={onNodeDragStop}
                onLoad={onLoad}
                onNodeDoubleClick={onNodeDoubleClickHandler}
                snapToGrid={true}
                key="diagram"
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
            >
                <MiniMap style={{ background: '#497d7d', boxShadow: '1px 1px 5px grey', top: '10px' }} />
                <Controls style={{ background: 'red', boxShadow: '1px 1px 5px grey' }} />
                <Background color="black" />
                <TraceTitleContainer>
                    {/* <TraceTitleLabel>Titulo:</TraceTitleLabel> */}
                    <TraceTitleInput value={props.title} onChange={e=> props.onChangeTitle(e.target.value)}/>
                </TraceTitleContainer>
            </ReactFlow>
        )

    )
}