import { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { ITableGeneratorComponent, TableGeneratorComponent, TItem } from 'table-generator'
import { Button, CenterText, EaseInAnimation, FloatPlusButton, Modal, ModalContent, Title, Text } from '../elements/Element'
import { IAppStore } from '../stores/app/app.interface.store'
import { ICircuitStoreObject } from '../stores/circuit/circuit.interface.store'

interface CircuitPageProps {
    circuitStore: ICircuitStoreObject
    appStore: IAppStore
}

export const CircuitPage: FC<CircuitPageProps> = (props) => {

    const history = useHistory()

    const [isOpenDeleteAlert, setIsOpenDeleteAlert] = useState<boolean>(false)
    const [circuitIdForDeleteSelected, setCircuitIdForDeleteSelected] = useState<number>(0)

    useEffect(() => { onInit() }, [])

    const onInit = async () => {
        props.appStore.showLoader()
        const response = await props.circuitStore.getCircuitList()
        if (response.isError) props.appStore.openApiErrorAlert()
        props.appStore.hideLoader()
    }

    const bodyList: ITableGeneratorComponent['bodyList'] = props.circuitStore.circuitList.map(circuit => {

        const newRow: Array<TItem> = [
            {
                type: 'text',
                text: String(circuit.id)
            },
            {
                type: 'text',
                text: circuit.name
            },
            {
                type: 'actions',
                key: String(circuit.id),
                actions: [
                    {
                        type: 'stages',
                        text: 'Stages',
                        disabled: false
                    },
                    {
                        type: 'modify',
                        text: 'Modificar',
                        disabled: false
                    },
                    {
                        type: 'delete',
                        text: 'Eliminar',
                        disabled: false
                    }
                ]
            }
        ]

        return newRow
    })

    const onActionTableHandler = (type: string, key: string) => {
        if(type==='stages') return history.push(`/circuit/${key}/stage`)

        if(type==='modify') {
            history.push(`/circuit/${key}`)
        }

        if(type==='delete') {
            setCircuitIdForDeleteSelected(Number(key))
            setIsOpenDeleteAlert(true)
        }
    }

    const onClickHandlerFromFloatPlusButton = () => {
        history.push(`/circuit/create`)
    }

    const onConfirmDeleteCircuit = async () => {
        props.appStore.showLoader()
        const result = await props.circuitStore.deleteCircuit(circuitIdForDeleteSelected)
        if(result.isError) props.appStore.openApiErrorAlert()
        setIsOpenDeleteAlert(false)
        setCircuitIdForDeleteSelected(0)
        props.appStore.hideLoader() 
    }

    return (
        <EaseInAnimation>


            <Modal
                size="S"
                level='low'
                title=""
                isOpen={isOpenDeleteAlert}
                onClose={() => setIsOpenDeleteAlert(false)}
            >
                <ModalContent>
                    <CenterText>
                        <Title>Confirmar</Title>
                        <Text>¿Quieres eliminar el circuito #{circuitIdForDeleteSelected}?</Text>
                        <br />
                        <Button data-testid="confirmDeleteElementButton" onClick={onConfirmDeleteCircuit}>Aceptar</Button>
                    </CenterText>
                    <br />
                </ModalContent>
            </Modal>


            <br></br><br></br>

            <Title>Lista de Circuitos</Title>
            <TableGeneratorComponent
                headerList={['Id', 'Nombre', 'Acciones']}
                bodyList={bodyList}
                onAction={onActionTableHandler}
                tools={[]}
                paginationList={[]}
            />
            <FloatPlusButton data-testid="FloatPlusButton" onClick={onClickHandlerFromFloatPlusButton} />
            
        </EaseInAnimation>
    )
}