export interface ISource {
    "id": number
    "name": string
    "type": "GoogleSheets"
    "description": string
    "config": Array<{
        "type": "str"
        "key": string | 'url'
        "value": string
    }>
}


export const sourceFactory = (): ISource => ({
    "id": 0,
    "name": '',
    "type": "GoogleSheets",
    "description": '',
    "config": []
})

// {
//     "type": "str",
//     "key": "url",
//     "value": "https://docs.google.com/spreadsheets/d/13_8WW8toCsmo_FxIIh7fmlSRXYv48WBp4cilFS-sygs/edit#gid=0"
// }