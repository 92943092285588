import React from 'react'
import { useParams } from 'react-router'
import { FormGenerator, TForm } from 'FormGenerator'
import { CenterText, EaseInAnimation, Loader, Title } from '../elements/Element'
import { mapFieldGroupToIForm } from '../helpers/EditTrace.helper'
import {  getParamsFromExecuteParamsForm, getPreloadExecuteParamsFormFromRisk } from '../helpers/ExecuteRisk.helper'
import { IAppStore } from '../stores/app/app.interface.store'
import { IExecuteStore } from '../stores/execution/execute.interface.store'
import { useRiskHelper } from '../stores/risk/risk.helper.store'
import { IRiskStore } from '../stores/risk/risk.interface.store'
import { IExecuted, IRisk } from '../stores/risk/risk.model.store'

interface ExecuteRiskPageProps {
    executeStore: IExecuteStore
    riskStore: IRiskStore
    appStore: IAppStore
}

export const ExecuteRiskPage = (props: ExecuteRiskPageProps) => {

    const riskHelper = useRiskHelper()

    const { riskId, executeId } = useParams<{ riskId: string, executeId: string }>()

    const [formSheme, setFormScheme] = React.useState<TForm>([])
    const [isLoadingData, setIsLoadingData] = React.useState<boolean>(false)

    const [result, setResult] = React.useState<IExecuted>(riskHelper.createExecuted())

    React.useEffect(() => {
        onInit()
    }, [])

    const onInit = async () => {
        setIsLoadingData(true)
        if(executeId) onInitWithPreloadData()
        else onInitWithNewValues()
        setIsLoadingData(false)
    }

    const onInitWithPreloadData = async () => {
        const executedResult = await props.executeStore.getExecutionById(Number(executeId))
        const riskResult = await props.riskStore.getRisk(executedResult.data.risk_policy_id)

            if(executedResult.isError || riskResult.isError) {
                props.appStore.openApiErrorAlert()
                return
            }

            if(executedResult.isSuccess || riskResult.isSuccess) {
                setFormScheme(getPreloadExecuteParamsFormFromRisk(riskResult.data, executedResult.data))
                return
            }
    }

    const onInitWithNewValues = async () => {
        const result = await props.riskStore.getRisk(Number(riskId))
        if(result.isError) props.appStore.openApiErrorAlert()
        if(result.isSuccess) setFormScheme(mapFieldGroupToIForm(result.data.fields_group))
    }

    const onExecuteHandler = async () => {
        setIsLoadingData(true)
        const newParams = getParamsFromExecuteParamsForm(formSheme)
        const result = await props.riskStore.executeRisk(Number(riskId), newParams)
        
        
        if(result.isError) props.appStore.openApiErrorAlert()
        if(result.isSuccess) setResult(result.data)
        setIsLoadingData(false)
    }

    return (
        <div>
            <Loader isOpen={isLoadingData} />
            <EaseInAnimation>

                <br></br><br></br><br></br>
                <Title>Ejecutar Politica de Riesgo #{riskId}</Title>
                <div style={{ padding: '50px' }}>
                    <FormGenerator
                        dataTestId="forExecuteRisk"
                        form={formSheme}
                        setForm={setFormScheme}
                        onSubmit={onExecuteHandler}
                        submitText="Ejecutar"
                        isShowSubmit={true}
                        actions={[]}
                        onAction={()=>{}}
                        onValidForm={()=>{}}
                        onInvalidForm={()=>{}}
                    />
                    <br></br><br></br>

                    <div>
                        {result.response && <Title>Resultado: #{result.execution_id}</Title>}
                        <br></br>
                        <CenterText>
                            <b>{result.response}</b>
                        </CenterText>
                    </div>
                </div>
            </EaseInAnimation>

        </div>
    )
}