import React from 'react'
import { ISourceStore } from '../stores/source/source.interface.store'
import { TableGeneratorComponent } from 'table-generator'
import { Button, CenterText, EaseInAnimation, FloatPlusButton, Modal, Title, Text, ModalContent, FormWrapper } from '../elements/Element'
import { IAppStore } from '../stores/app/app.interface.store'
import { ISource, sourceFactory } from '../stores/source/source.model.store'
import { FormGenerator, TForm, getValueToForm } from 'FormGenerator'
import { sourceFormSheme } from '../schemes/source.sheme'

interface ISourceListPageProps {
    sourceStore: ISourceStore
    appStore: IAppStore
}

const SourceListPage: React.FC<ISourceListPageProps> = props => {

    const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] = React.useState<boolean>(false)
    const [sourceTarget, setSourceTarget] = React.useState<ISource>(sourceFactory())
    const [sourceForm, setSourceForm] = React.useState<TForm>([])
    const [isOpenSourceForm, setIsOpenSourceForm] = React.useState<boolean>(false)

    React.useEffect(() => {
        onInit()
    }, [])

    const onInit = async () => {
        props.appStore.showLoader()
        const result = await props.sourceStore.getSourceList()
        if (result.isError) props.appStore.openApiErrorAlert()
        props.appStore.hideLoader()
    }

    const onActionTableSourceListHandler = (type: string, key: string) => {
        if (type === 'delete') onActionDeleteHandler(Number(key))
        if (type === 'modify') onActionModifyHandler(Number(key))
        if (type === 'async') onAsnycSourceHandler(Number(key))
    }

    const onAsnycSourceHandler = async (sourceId: number) => {
        props.appStore.showLoader()
        const result = await props.sourceStore.syncSource(sourceId)
        if(result.isError) props.appStore.openApiErrorAlert()
        props.appStore.hideLoader()
    }

    const onActionDeleteHandler = (sourceId: number) => {
        const sourceFinded = props.sourceStore.sourceList.find(source => source.id === sourceId)
        if (sourceFinded === undefined) return
        setSourceTarget(sourceFinded)
        setIsOpenDeleteConfirmModal(true)
    }

    const onActionModifyHandler = (sourceId: number) => {
        const sourceFinded = props.sourceStore.sourceList.find(source => source.id === sourceId)
        if (sourceFinded === undefined) return
        setSourceTarget(sourceFinded)
        setSourceForm([...sourceFormSheme].map(input => {
            if(input.id === '1') return {
                ...input,
                value: sourceFinded.name
            }

            if(input.id === '2') return {
                ...input,
                value: sourceFinded.description
            }

            if(input.id === '3') return {
                ...input,
                value: sourceFinded.config[0].value
            }

            return input
        }))

        setIsOpenSourceForm(true)
    }

    const onCreateHandler = () => {
        setSourceForm([...sourceFormSheme])
        setSourceTarget(sourceFactory())
        setIsOpenSourceForm(true)
    }

    const onSubmitSourceFormHandler = async () => {

        if(sourceTarget.id === 0) {
            props.appStore.showLoader()

            const result = await props.sourceStore.createSource({
                ...sourceFactory(),
                name: getValueToForm(sourceForm, '1'),
                description: getValueToForm(sourceForm, '2'),
                config: [{
                    key: 'url',
                    type: 'str',
                    value: getValueToForm(sourceForm, '3')
                }]
            })

            if(result.isError) props.appStore.openApiErrorAlert()
            if(result.isSuccess) setIsOpenSourceForm(false)
            props.appStore.hideLoader()
            return
        }

        if(sourceTarget.id) {
            props.appStore.showLoader()
            const result = await props.sourceStore.updateSource({
                ...sourceTarget,
                name: getValueToForm(sourceForm, '1'),
                description: getValueToForm(sourceForm, '2'),
                config: [{
                    key: 'url',
                    type: 'str',
                    value: getValueToForm(sourceForm, '3')
                }]
            })
            if(result.isError) props.appStore.openApiErrorAlert()
            if(result.isSuccess) setIsOpenSourceForm(false)
            props.appStore.hideLoader()
            return
        }
    }

    const onDeleteConfirmHandler = async () => {
        props.appStore.showLoader()
        const result = await props.sourceStore.deleteSource(sourceTarget.id)
        if (result.isError) {
            props.appStore.openApiErrorAlert()
            props.appStore.hideLoader()
            setIsOpenDeleteConfirmModal(false)
            setSourceTarget(sourceFactory())
            return
        }


        props.appStore.hideLoader()
        setIsOpenDeleteConfirmModal(false)
        setSourceTarget(sourceFactory())
    }

    return (
        <EaseInAnimation>


            <Modal
                size="S"
                title=""
                level="low"
                isOpen={isOpenDeleteConfirmModal}
                onClose={() => setIsOpenDeleteConfirmModal(false)}
            >
                <CenterText>
                    <Title>Eliminar fuente de datos</Title>
                    <Text>¿Quieres Eliminar #{sourceTarget.id} - {sourceTarget.name}?</Text>
                    <br />
                    <Button onClick={onDeleteConfirmHandler}>Confirmar</Button>
                </CenterText>
                <br />
            </Modal>

            <Modal
                size="S"
                title=""
                level="low"
                isOpen={isOpenSourceForm}
                onClose={() => setIsOpenSourceForm(false)}
            >
                <ModalContent>
                    <CenterText>
                        <Title>{sourceTarget.id ? `Modificar fuente añadida #${sourceTarget.id}` : 'Agregar fuente añadida'}</Title>
                    </CenterText>
                    <br />
                    <FormWrapper>
                        <FormGenerator
                            dataTestId='createRiskForm'
                            form={sourceForm}
                            setForm={setSourceForm}
                            onSubmit={onSubmitSourceFormHandler}
                            submitText="Guardar"
                            isShowSubmit={true}
                            actions={[]}
                            onAction={() => { }}
                            onValidForm={() => { }}
                            onInvalidForm={() => { }}
                        />
                    </FormWrapper>
                </ModalContent>
            </Modal>

            <br />
            <Title>Fuentes de datos añadidas</Title>
            <TableGeneratorComponent
                headerList={['Id', 'Tipo', 'Nombre', 'Descripción', 'Acciones']}
                paginationList={[]}
                bodyList={props.sourceStore.sourceList.map(source => [
                    {
                        type: 'text',
                        text: String(source.id)
                    },
                    {
                        type: 'text',
                        text: source.type
                    },
                    {
                        type: 'text',
                        text: source.name
                    },
                    {
                        type: 'text',
                        text: source.description
                    },
                    {
                        type: 'actions',
                        key: String(source.id),
                        actions: [
                            {
                                type: 'async',
                                text: 'Sincronizar',
                                disabled: false
                            },
                            {
                                type: 'modify',
                                text: 'Modificar',
                                disabled: false
                            },
                            {
                                type: 'delete',
                                text: 'Eliminar',
                                disabled: false
                            }
                        ]
                    }
                ])}
                onAction={onActionTableSourceListHandler}
                tools={[]}
            />
            <FloatPlusButton onClick={onCreateHandler} />
        </EaseInAnimation>
    )
}

export { SourceListPage }