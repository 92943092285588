import React from 'react'
import { useParams } from 'react-router'

import { EaseInAnimation, PrimaryButton } from '../elements/Element'
import { IAppStore } from '../stores/app/app.interface.store'
import { IRiskStore } from '../stores/risk/risk.interface.store'
import { FormCreator, IFormSection, TFormSectionList } from 'FormCreator'
import { IFieldGroup, IRisk } from '../stores/risk/risk.model.store'
import { useRiskHelper } from '../stores/risk/risk.helper.store'
import { translateFieldGroupListToSectionList, translateSectionListToFieldGroupList } from '../helpers/RiskForm.helper'
import { Row } from '../elements/Grid.element'
import { useFile } from '../utils/useFile.util'

interface IRiskParamsPage {
    riskStore: IRiskStore
    appStore: IAppStore
}

export const RiskFormsPage = (props: IRiskParamsPage) => {
    // content: "📥";
    // content: "📤";

    const file = useFile()

    const params = useParams<{ id: string }>()
    const riskHelper = useRiskHelper()
    const [currentRisk, setCurrentRisk] = React.useState<IRisk>(riskHelper.createRisk())
    const [currentSections, setCurrentSections] = React.useState<Array<IFormSection>>([])

    React.useEffect(() => {
        onInit()
    }, [])

    const onInit = async () => {
        props.appStore.showLoader()
        const result = await props.riskStore.getRisk(Number(params.id))
        props.appStore.hideLoader()
        if (result.isError) return props.appStore.openApiErrorAlert()
        setCurrentSections(translateFieldGroupListToSectionList(result.data.fields_group))
        setCurrentRisk(result.data)
    }

    const onSubmit = async (sections: TFormSectionList) => {
        props.appStore.showLoader()
        const result = await props.riskStore.updateRisk({
            ...currentRisk,
            fields_group: translateSectionListToFieldGroupList(sections)
        })
        if (result.isError) return props.appStore.openApiErrorAlert()
        setCurrentSections(sections)
        props.appStore.hideLoader()
    }

    const onExportFormHandler = () => {
        const fieldsGroup = translateSectionListToFieldGroupList(currentSections)
        file.saveData(fieldsGroup, `risk-${currentRisk.id}-forms.json`)
    }

    const onImportFormHandler = async () => {
        const fieldGroup = await file.readFile<IFieldGroup[]>()
        const formSection: IFormSection[]  = translateFieldGroupListToSectionList(fieldGroup)
        setCurrentSections(formSection)
    }

    return (
        <EaseInAnimation>
            <Row horizontalAlign='center' verticalAlign='center'>
                <PrimaryButton disabled={false} style={{ margin: '10px', backgroundColor: '#497d7d' }} onClick={() => onImportFormHandler()}>Importar</PrimaryButton>
                <PrimaryButton disabled={false} style={{ margin: '10px', backgroundColor: '#497d7d' }} onClick={() => onExportFormHandler()}>Exportar</PrimaryButton>
            </Row>
            <Row horizontalAlign='center' verticalAlign='center'>
              <br/>
            </Row>
            <FormCreator
                preloadSectionList={currentSections}
                onSubmit={(sections) => onSubmit(sections)}
            />
        </EaseInAnimation>
    )
}