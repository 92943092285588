import axios from 'axios'
import { setup } from './setup'

export type TApiClientResponse<IData> = Promise<IApiClientResponse<IData>>
interface IApiClientResponse<IData> {
    status: number
    isSuccess: boolean
    isError: boolean
    data: IData
}

export const useApiClient = () => {

    const getHeader = () => ({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + setup.token,
    })

    const get = async <IData>(url: string): TApiClientResponse<IData> => {

        try {
            const response = await axios({
                method: 'GET',
                url: url,
                headers: getHeader()
            })
            return {
                status: response.status,
                isSuccess: true,
                isError: false,
                data: response.data
            }

        } catch (e: any) {
            console.error(e)
            return {
                status: e.response && e.response.status ? e.response.status: 0,
                isSuccess: false,
                isError: true,
                data: e
            }
        }
    }

    const post = async <IData>(url: string, params: any): TApiClientResponse<IData> => {
        try {
            const response = await axios({
                method: 'POST',
                url: url,
                headers: getHeader(),
                data: params
            })
            return {
                status: response.status,
                isSuccess: true,
                isError: false,
                data: response.data
            }

        } catch (e: any) {
            console.error(e)
            return {
                status: e.response && e.response.status ? e.response.status: 0,
                isSuccess: false,
                isError: true,
                data: e
            }
        }
    }


    const put = async  <IData>(url: string, params: any): TApiClientResponse<IData> => {

        try {
            const response = await axios({
                method: 'PUT',
                url: url,
                headers: getHeader(),
                data: params
            })
            return {
                status: response.status,
                isSuccess: true,
                isError: false,
                data: response.data
            }

        } catch (e: any) {
            console.error(e)
            return {
                status: e.response && e.response.status ? e.response.status: 0,
                isSuccess: false,
                isError: true,
                data: e
            }
        }
    }

    const del = async  <IData>(url: string, id?: number): TApiClientResponse<IData> => {

        try {
            const response = await axios({
                method: 'DELETE',
                url: url,
                headers: getHeader()
            })

            return {
                status: response.status,
                isSuccess: true,
                isError: false,
                data: response.data
            }

        } catch (e: any) {
            console.error(e)
            return {
                status: e.response && e.response.status ? e.response.status: 0,
                isSuccess: false,
                isError: true,
                data: e
            }
        }
    }

    const patch = async <IData>(url: string, params: any): TApiClientResponse<IData> => {
        try {
            const response = await axios({
                method: 'PATCH',
                url: url,
                headers: getHeader(),
                data: params
            })

            return {
                status: response.status,
                isSuccess: true,
                isError: false,
                data: response.data
            }

        } catch (e: any) {
            console.error(e)
            return {
                status: e.response && e.response.status ? e.response.status: 0,
                isSuccess: false,
                isError: true,
                data: e
            }
        }
    }

    const postArraybuffer = async <IData>(url: string, params: any): TApiClientResponse<IData> => {
        try {
            const response = await axios({
                method: 'POST',
                url: url,
                headers: getHeader(),
                data: params,
                responseType: 'arraybuffer'
            })
            return {
                status: response.status,
                isSuccess: true,
                isError: false,
                data: response.data
            }

        } catch (e: any) {
            console.error(e)
            return {
                status: e.response && e.response.status ? e.response.status: 0,
                isSuccess: false,
                isError: true,
                data: e
            }
        }
    }

    const getArraybuffer = async <IData>(url: string): TApiClientResponse<IData> => {
        try {
            const response = await axios({
                method: 'GET',
                url: url,
                headers: getHeader(),
                responseType: 'arraybuffer'
            })
            return {
                status: response.status,
                isSuccess: true,
                isError: false,
                data: response.data
            }

        } catch (e: any) {
            console.error(e)
            return {
                status: e.response && e.response.status ? e.response.status: 0,
                isSuccess: false,
                isError: true,
                data: e
            }
        }
    }

    return {
        get,
        post,
        put,
        patch,
        del,
        postArraybuffer,
        getArraybuffer
    }
}

export const apiResponseFactory = <IData>(status: number, data: IData): IApiClientResponse<IData> => ({
    status: status,
    isSuccess: status >= 200 && status < 300 ? true : false,
    isError: status >= 200 && status < 300 ? false : true,
    data: data
})