import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { NavigationComponent } from './components/Navigation.component';
import { All, Content, Loader } from './elements/Element';

import { RiskListPage } from './pages/RiskList.page'
import { RiskEditPage } from './pages/RiskEdit.page'
import { RiskParamsPage } from './pages/RiskParams.page'

import { useRiskStore } from './stores/risk/risk.use.store'
import { useAuthStore } from './stores/auth/auth.use.store'
import { NoAuthPage } from './pages/NoAuth.page'
import { EditTracePage } from './pages/EditTrace.page'
import { useTraceStore } from './stores/trace/trace.use.store';
import { useSetupStore } from './stores/setup/setup.use.store';
import { TraceListPage } from './pages/TraceList.page'
import { ExecuteRiskPage } from './pages/ExecuteRisk.page'
import { SearchExecutionPage } from './pages/SearchExecution.page';
import { useExecuteStore } from './stores/execution/execute.use.store';
import { AlertComponent } from './components/AlertComponent/Alert.component';
import { useAppStore } from './stores/app/app.use.store';
import { attachProps } from '@ionic/react/dist/types/components/utils';
import { CircuitPage } from './pages/Circuit.page';
import { useCircuitStore } from './stores/circuit/circuit.use.store';
import { CircuitStagePage } from './pages/CircuitStage.page';
import { CircuitAddStagePage } from './pages/CircuitAddStage.page';
import { CircuitCreateStagePage } from './pages/CircuitCreateStage.page';
import { CircuitCreatePage } from './pages/CircuitCreate.page';
import { CircuitStageStatesPage } from './pages/CircuitStageStates.page';
import { CircuitStageCreateStatusPage } from './pages/CircuitStageCreateStatus.page';
import { SourceListPage } from './pages/SourceList.page'
import { RiskFormsPage } from './pages/RiskForms.page';
import { useSourceStore } from './stores/source/source.use.store';
import { BureauPage } from './pages/Bureau.page';
import { useBureauStore } from './stores/bureau/bureau.use.store';
import { NipBureauReportPage } from './pages/NipBureauReport.page';
import { BureauHistoryPage } from './pages/BureauHistory.page';

const App = () => {

  const setupStore = useSetupStore()
  const authStore = useAuthStore()
  const traceStore = useTraceStore()
  const riskStore = useRiskStore({traceStore})
  const executeStore = useExecuteStore()
  const appStore = useAppStore()
  const circuitStore = useCircuitStore({})
  const sourceStore = useSourceStore()
  const bureauStore = useBureauStore()

  React.useEffect(() => {
    onInit()
  }, [])

  const onInit = async () => {
    const getSetupDataResult = await setupStore.getSetupData()
    if(getSetupDataResult.isError) return appStore.openApiErrorAlert()
    const authenticateResult = await authStore.authenticate()
    if(authenticateResult.isError) return appStore.openApiErrorAlert()
  }

  // para probar 

  return (
    <All>
      <AlertComponent isOpen={appStore.isOpenAlert} onClose={appStore.closeAlert} text={appStore.message} status={appStore.status}/>
      <Router>
        <NavigationComponent setupStore={setupStore}/>
        <Loader isOpen={authStore.isAuthenticating || appStore.isLoading} />
        <Content>
          {setupStore.isSetupDataLoaded && authStore.isFinishedAuthenticate && authStore.isAuthenticated ? (
            <Switch>
              <Route exact path="/">
                <RiskListPage riskStore={riskStore} appStore={appStore}/>
              </Route>
              <Route path="/risk/:id" exact>
                <RiskEditPage riskStore={riskStore} appStore={appStore} />
              </Route>
              <Route path="/risk/:id/forms" exact>
                <RiskFormsPage riskStore={riskStore} appStore={appStore}/>
              </Route>
              <Route path="/risk/:id/params" exact>
                <RiskParamsPage riskStore={riskStore} appStore={appStore}/>
              </Route>
              <Route path="/risk/:riskId/execute" exact>
                <ExecuteRiskPage riskStore={riskStore} appStore={appStore} executeStore={executeStore}/>
              </Route>
              <Route path="/risk/:riskId/execute/:executeId" exact>
                <ExecuteRiskPage riskStore={riskStore} appStore={appStore} executeStore={executeStore}/>
              </Route>
              <Route path="/risk/:riskId/trace/:traceId" exact>
                <EditTracePage riskStore={riskStore} traceStore={traceStore} appStore={appStore} />
              </Route>
              <Route path="/risk/:riskId/trace" exact>
                <TraceListPage traceStore={traceStore} riskStore={riskStore} appStore={appStore} />
              </Route>
              <Route path="/search/execution" exact>
                <SearchExecutionPage executeStore={executeStore} appStore={appStore} />
              </Route>

              <Route path="/circuit" exact>
                <CircuitPage circuitStore={circuitStore} appStore={appStore}/>
              </Route>

              <Route path="/circuit/create" exact>
                <CircuitCreatePage circuitStore={circuitStore} appStore={appStore}/>
              </Route>

              <Route path="/circuit/:circuitId" exact>
                <CircuitCreatePage circuitStore={circuitStore} appStore={appStore}/>
              </Route>

              <Route path="/circuit/:circuitId/stage" exact>
                <CircuitStagePage circuitStore={circuitStore} appStore={appStore}/>
              </Route>

              <Route path="/circuit/:circuitId/stage/add" exact>
                <CircuitAddStagePage circuitStore={circuitStore} appStore={appStore}/>
              </Route>

              <Route path="/circuit/:circuitId/stage/create" exact>
                <CircuitCreateStagePage circuitStore={circuitStore} appStore={appStore}/>
              </Route>

              <Route path="/circuit/:circuitId/stage/:stageId" exact>
                <CircuitCreateStagePage circuitStore={circuitStore} appStore={appStore}/>
              </Route>

              <Route path="/circuit/:circuitId/stage/:stageId/status" exact>
                <CircuitStageStatesPage circuitStore={circuitStore} appStore={appStore}/>
              </Route>

              <Route path="/circuit/:circuitId/stage/:stageId/status/create" exact>
                <CircuitStageCreateStatusPage circuitStore={circuitStore} appStore={appStore}/>
              </Route>

              <Route path="/circuit/:circuitId/stage/:stageId/status/:statusId" exact>
                <CircuitStageCreateStatusPage circuitStore={circuitStore} appStore={appStore}/>
              </Route>

              <Route path="/sourceList" exact>
                <SourceListPage sourceStore={sourceStore} appStore={appStore}/>
              </Route>

              {setupStore.currentCountry === 'México' ? (
                <Route path="/bureau" exact>
                  <BureauPage bureauStore={bureauStore} appStore={appStore}/>
                </Route>
              ) : null}

              {setupStore.currentCountry === 'México' ? (
                <Route path="/nipBureauReport" exact>
                  <NipBureauReportPage
                    appStore={appStore}
                    bureauStore={bureauStore}
                  />
                </Route>
              ) : null}

              {setupStore.currentCountry === 'México' ? (
                <Route path="/bureauHistory" exact>
                  <BureauHistoryPage
                    appStore={appStore}
                    bureauStore={bureauStore}
                  />
                </Route>
              ) : null}
            
              <Redirect to="/" />
            </Switch>
          ) : null}
          {!authStore.isAuthenticated && authStore.isFinishedAuthenticate ? (
            <NoAuthPage />
          ) : null}
        </Content>
      </Router>
    </All>
  );
}

export default App;
