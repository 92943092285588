import React from 'react'
import { useHistory, useParams } from 'react-router'
import { IRiskStore } from '../stores/risk/risk.interface.store'
import { FormGenerator, getValueToForm, TForm } from 'FormGenerator'
import { DanggerButton, Description, EaseInAnimation, FormWrapper, Loader, Modal, Title, Button, CenterText } from '../elements/Element'
import { IRisk } from '../stores/risk/risk.model.store'
import { IAppStore } from '../stores/app/app.interface.store'
import { createRiskFormScheme } from '../schemes/risk.form.sheme'
import { Row, Col } from '../elements/Grid.element'
import { useFile } from '../utils/useFile.util'


interface RiskEditPage {
    riskStore: IRiskStore
    // sourceStore: ISourceStore
    appStore: IAppStore
}

export const RiskEditPage = (props: RiskEditPage) => {

    const file = useFile()
    const param = useParams<{ id: string }>()
    const history = useHistory()


    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [isOpenConfirm, setIsOpenConfirm] = React.useState<boolean>(false)
    const [formData, setFormData] = React.useState<TForm>([...createRiskFormScheme])
    const [risk, setRisk] = React.useState<IRisk>({
        id: 0,
        name: '',
        type: 1,
        description: '',
        schema: [],
        fields_group: [],
        active_trace_id: 0,
        is_active: false
    })

    React.useEffect(() => {
        onInit()
    }, [])

    const onInit = async () => {
        setIsLoading(true)
        const result = await props.riskStore.getRisk(Number(param.id))
        if (result.isError) {
            props.appStore.openApiErrorAlert()
            return
        }
        setRisk(result.data)
        const newFormData = [...formData]
        newFormData[0] = { ...newFormData[0], value: result.data.name }
        newFormData[1] = { ...newFormData[1], value: result.data.description }
        newFormData[2] = { ...newFormData[2], value: String(result.data.type) }
        setFormData(newFormData)
        setIsLoading(false)
    }

    const setFormDataHandler = (formDataChanged: TForm) => {
        setFormData(formDataChanged)
    }

    const onSubmitHandler = async () => {
        setIsLoading(true)
        const result = await props.riskStore.getRisk(Number(param.id))
        if (result.isError) props.appStore.openApiErrorAlert()
        if (result.isSuccess) {
            const newRisk: IRisk = { ...result.data }
            newRisk.name = getValueToForm(formData, '2')
            newRisk.description = getValueToForm(formData, '3')
            newRisk.type = getValueToForm(formData, '4') === '1' ? 1 : 2
            const updateRiskResult = await props.riskStore.updateRisk(newRisk)
            if (updateRiskResult.isError) props.appStore.openApiErrorAlert()
        }

        setIsLoading(false)
    }

    const onDeleteHandler = () => {
        setIsOpenConfirm(true)
    }

    const onConfirmDeleteHandler = async () => {
        setIsOpenConfirm(false)
        setIsLoading(true)
        const result = await props.riskStore.deleteRisk(Number(param.id))
        setIsLoading(false)
        if (result.isSuccess) history.push('/')
        if (result.isError) props.appStore.openApiErrorAlert()
    }

    const onRedirectToForms = () => history.push(`/risk/${param.id}/forms`)


    const copyRiskHandler = async (riskId: number) => {
        setIsLoading(true)
        const result = await props.riskStore.copyRisk(riskId)
        if (result.isError) {
            setIsLoading(false)
            props.appStore.openApiErrorAlert()
        }
        if (result.isSuccess) {
            setIsLoading(false)
            history.push('/')
        }
    }

    const activateRiskHandler = async (riskId: number, action: 'activate' | 'deactivate') => {
        setIsLoading(true)
        const riskObrainedResult = await props.riskStore.getRisk(riskId)
        if (riskObrainedResult.isError) {
            props.appStore.openApiErrorAlert()
            setIsLoading(false)
            return
        }

        const riskUpdatedResult = await props.riskStore.updateRisk({
            ...riskObrainedResult.data,
            is_active: action === 'activate' ? true : false
        })

        if (riskUpdatedResult.isError) {
            props.appStore.openApiErrorAlert()
            setIsLoading(false)
            return
        }

        setRisk({
            ...riskObrainedResult.data,
            is_active: action === 'activate' ? true : false
        })

        setIsLoading(false)
        return
    }

    const onExportHandler = () => {
        const riskCopy = {...risk}
        file.saveData(riskCopy, `risk-${riskCopy.id}.json`)
    }

    const onImportHandler = async () => {
        const riskFromFile: IRisk = await file.readFile()
        riskFromFile.id = risk.id
        props.appStore.showLoader()
        const result = await props.riskStore.updateRisk(riskFromFile)
        props.appStore.hideLoader()
        if(result.isError) return props.appStore.openApiErrorAlert()
        setRisk(riskFromFile)
    }

    return (
        <div>
            <Loader isOpen={isLoading} />
            <Modal title="" size="S" level="low" isOpen={isOpenConfirm} onClose={() => setIsOpenConfirm(false)}>
                <FormWrapper>
                    <Description>Eliminar Política de Riesgo #{param.id}</Description>

                    <br />
                    <Row horizontalAlign='center' verticalAlign='center'>
                        <DanggerButton onClick={() => onConfirmDeleteHandler()}>Eliminar</DanggerButton>
                    </Row>
                </FormWrapper>
            </Modal>


            <EaseInAnimation>
                <FormWrapper>
                    <Row horizontalAlign='right' verticalAlign='center'>
                        <Col size={2} horizontalAlign='right' verticalAlign='center'>
                            <DanggerButton onClick={() => onDeleteHandler()}>Eliminar</DanggerButton>
                        </Col>

                        <Col size={12} horizontalAlign='center' verticalAlign='center'>
                            <Title>Editar #{param.id}</Title>
                        </Col>
                    </Row>
                    <br></br>
                    <FormGenerator
                        dataTestId="forEditRisk"
                        form={formData}
                        setForm={setFormDataHandler}
                        onSubmit={onSubmitHandler}
                        submitText="Guardar"
                        isShowSubmit={true}
                        actions={[]}
                        onAction={() => { }}
                        onValidForm={() => { }}
                        onInvalidForm={() => { }}
                    />
                </FormWrapper>
                <hr />
                <br /><br />
                <CenterText>

                    {/*
                    <span style={{ margin: '5px' }}>
                        <Button onClick={() => onExportHandler()}>Exportar</Button>
                    </span>
                    <span style={{ margin: '5px' }}>
                        <Button onClick={() => onImportHandler()}>Importar</Button>
                    </span>
                    */}

                    <span style={{ margin: '5px' }}>
                        <Button onClick={() => copyRiskHandler(risk.id)}>Duplicar</Button>
                    </span>
                    <span style={{ margin: '5px' }}>
                        <Button onClick={() => onRedirectToForms()}>Formularios</Button>
                    </span>
                    <span style={{ margin: '5px' }}>
                        {risk.is_active === true ? (
                            <Button onClick={() => activateRiskHandler(risk.id, 'deactivate')}>Desactivar</Button>
                        ) : null}

                        {risk.is_active === false ? (
                            <Button onClick={() => activateRiskHandler(risk.id, 'activate')}>Activar</Button>
                        ) : null}
                    </span>
                </CenterText>
                <br />
            </EaseInAnimation>
        </div>
    )
}