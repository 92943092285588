import { TForm } from "FormGenerator"
import { ICircuitModel, IStageModel, IStatusModel } from "../stores/circuit/circuit.model.store";

export const addPreloadDataToCircuitFormScheme = (formData: TForm, circuit: ICircuitModel): TForm => formData.map(input=>{
    if(input.id === '1') return {...input, value: circuit.name}
    else return input
})

export const addPreloadDataToStageFormScheme = (formData: TForm, stage: IStageModel): TForm => formData.map(input=>{
    if(input.id === '1') return {...input, value: stage.name}
    else return input
})


export const addPreloadDataToStatusFormScheme = (formData: TForm, status: IStatusModel): TForm => formData.map(input=>{
    if(input.id === '1') return {...input, value: status.name}
    if(input.id === '2') return {...input, value: status.description}
    else return input
})

