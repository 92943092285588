import { TApiClientResponse, useApiClient } from "../../utils/apiClient.use.util"
import { setup } from '../../utils/setup'

interface IRiskService {
    authenticate: () => TApiClientResponse<boolean>
}

export const useAuthService = () : IRiskService => {
    const apiClient = useApiClient()

    const authenticate = async () => {
        const response = await apiClient.get<boolean>(`${setup.authApiUrl}`)
        if(response.isError && response.status === 401) return {
                status: 200,
                isSuccess: true,
                isError: false,
                data: false
            }
        if(response.isSuccess) return  {...response, data: true}
        return response
    }

    return { authenticate }
}