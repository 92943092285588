export const setup = {
    panelAppUrl: '',
    signInAppUrl: '',
    authApiUrl: '',
    riskApiUrl: '',
    cookieAuthKey: '', 
    country: '', 
    token: '',
    controlPanelApiUrl: '',
    bureauApiUrl: ''
}