import { ITrace, TBoxType, TDiagram } from "./trace.model.store"

// todo: este codigo tiene sus dias condenados

export const useTraceHelper = () => {
    
    const createTrace = (): ITrace => ({
        id: 0,
        title: '',
        diagram: [],
        is_active: false,
        risk_policy_id: 0,
        trace: []
    })

    return {createTrace}
}

