
export const useFile = () => {

    return {
        readFile: <T>(): Promise<T> => {
            return new Promise((resolve, reject) => {
                const inputFile = document.createElement('input')
                inputFile.type = 'file'
                inputFile.accept = ".json"
                inputFile.click()
                inputFile.onchange = (event: any) => {
                    var reader = new FileReader();
                    reader.onload = (event: any) => {
                        var obj: T = JSON.parse(event.target.result);
                        resolve(obj)
                    }

                    reader.readAsText(event.target.files[0]);
                }
            })
        },

        saveData: (function () {
            const a: any = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            return (data: any, fileName: string) => {
                const json = JSON.stringify(data)
                const blob = new Blob([json], { type: "octet/stream" })
                const url = window.URL.createObjectURL(blob)
                a.href = url
                a.download = fileName
                a.click()
                window.URL.revokeObjectURL(url)
            };
        }()),

        downloadFile: (blob: Blob, fileName: string) => {
            const url = window.URL.createObjectURL(blob); // new Blob([data])
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
