import { TForm } from "FormGenerator"

export type TOptionNodeType = 'root' | 'binary' | 'finish' | 'calculator' | 'advancedCalculator' | 'bureau' | 'policy'

export const nodeTypeFormScheme: TForm = [
    {
        id: '0',
        type: 'title',
        value: 'Crear Nodo',
        size: 12,
        hidden: false,
    },
    {
        id: '1',
        type: 'options',
        label: 'Tipo:',
        value: '',
        placeholder: 'Campo Requerido...',
        required: true,
        error: false,
        disabled: false,
        size: 12,
        hidden: false,
        options: [
            { value: 'binary' as TOptionNodeType, label: 'Binario' },
            { value: 'finish' as TOptionNodeType, label: 'Respuesta' },
            { value: 'calculator' as TOptionNodeType, label: 'Calculador' },
            { value: 'advancedCalculator' as TOptionNodeType, label: 'Calculador Avanzado' },
            { value: 'bureau' as TOptionNodeType, label: 'Bureau' },
            { value: 'policy' as TOptionNodeType, label: 'Política de Riesgo' },   
        ],
    }
]

export const rootNodeFormScheme: TForm = [{
    id: '1',
    type: 'title',
    value: 'Nodo Principal',
    size: 12,
    hidden: false,
}]

export const binaryNodeFormScheme: TForm = [
    {
        id: '1',
        type: 'title',
        value: 'Nodo Binario',
        size: 12,
        hidden: false,
    },
    {
        id: '2',
        type: 'code',
        language: 'python',
        label: 'Condicion:',
        value: '',
        required: true,
        error: false,
        disabled: false,
        size: 12,
        hidden: false,
    }
]

export const finishNodeFormScheme: TForm = [
    {
        id: '1',
        type: 'title',
        value: 'Nodo de Respuesta',
        size: 12,
        hidden: false,
    },
    {
        id: '2',
        type: 'text',
        label: 'Opinion:',
        value: '',
        placeholder: 'Campo Requerido...',
        required: true,
        error: false,
        minLength: 1,
        maxLength: 500,
        disabled: false,
        size: 12,
        regex: '',
        hidden: false,
    },
    {
        id: '3',
        type: 'options',
        label: 'Status',
        value: '',
        placeholder: 'Campo Requerido...',
        required: true,
        size: 12,
        disabled: false,
        options: [
            { value: '0', label: 'Error' },
            { value: '1', label: 'Aceptado' },
            { value: '2', label: 'Rechazado' },
            { value: '3', label: 'No Procesado' },
        ],
        error: false,
        hidden: false,
    },

    //////////////
    {
        id: '4',
        type: 'title',
        value: ' ',
        size: 12,
        hidden: false,
    },
    {
        id: '5',
        type: 'options',
        label: 'Con limite en ARS',
        value: 'false',
        placeholder: 'Campo Requerido...',
        required: true,
        disabled: false,
        size: 12,
        options: [
            { value: 'true', label: 'Si' },
            { value: 'false', label: 'No' },
        ],
        error: false,
        hidden: false,
    },
    {
        id: '6',
        type: 'code',
        label: 'Limite en ARS:',
        language: 'python',
        value: '',
        required: true,
        error: false,
        disabled: true,
        size: 12,
        hidden: false,
    },
    {
        id: '7',
        type: 'options',
        label: 'Con limite en USD:',
        value: 'false',
        placeholder: '',
        required: true,
        disabled: false,
        size: 12,
        options: [
            { value: 'true', label: 'Si' },
            { value: 'false', label: 'No' },
        ],
        error: false,
        hidden: false,
    },
    {
        id: '8',
        type: 'code',
        label: 'Limite en USD:',
        language: 'python',
        value: '',
        required: true,
        error: false,
        disabled: true,
        size: 12,
        hidden: false,
    }
]

export const calculatorNodeFormScheme: TForm = [
    {
        id: '1',
        type: 'title',
        value: 'Nodo Calculador',
        size: 12,
        hidden: false,
    },
    {
        id: '2',
        type: 'text',
        label: 'Nombre:',
        value: '',
        placeholder: 'Campo Requerido...',
        required: true,
        error: false,
        minLength: 0,
        maxLength: 100,
        disabled: false,
        size: 12,
        regex: '',
        hidden: false,
    },
    {
        id: '3',
        type: 'options',
        label: 'Tipo:',
        value: '',
        placeholder: 'Campo Requerido...',
        required: false,
        options: [{ value: 'string', label: 'Texto' }, { value: 'number', label: 'Numero' }],
        error: false,
        disabled: false,
        size: 12,
        hidden: false,
    },
    {
        id: '4',
        type: 'code',
        label: 'Formula:',
        value: '',
        required: true,
        error: false,
        disabled: false,
        language: 'python',
        size: 12,
        hidden: false,
    }
]

export const advancedCalculatorNodeFormScheme: TForm = [
    {
        id: '1',
        type: 'title',
        value: 'Nodo Calculador Avanzado',
        size: 12,
        hidden: false,
    },
    {
        id: '2',
        type: 'text',
        label: 'Nombre:',
        value: '',
        placeholder: 'Campo Requerido...',
        required: true,
        error: false,
        minLength: 0,
        maxLength: 100,
        disabled: false,
        size: 12,
        regex: '^[a-zA-Z][a-zA-Z0-9_]+[a-zA-Z0-9]$',
        hidden: false,
    },
    {
        id: '3',
        type: 'list',
        label: 'Argumentos',
        value: '',
        list: [],
        placeholder: 'Campo Requerido...',
        required: false,
        error: false,
        minLength: 1,
        maxLength: 100,
        disabled: false,
        size: 12,
        errorList: false,
        minOptions: 1,
        maxOptions: 100,
        hidden: false,
    },
    {
        id: '4',
        type: 'options',
        label: 'Tipo:',
        value: '',
        placeholder: 'Campo Requerido...',
        required: true,
        options: [
            { value: 'List', label: 'List' },
            { value: 'Dict', label: 'Dict' },
            { value: 'int', label: 'int' },
            { value: 'bool', label: 'bool' },
            { value: 'str', label: 'str' }
        ],
        error: false,
        disabled: false,
        size: 12,
        hidden: false,
    },
    {
        id: '5',
        type: 'code',
        label: 'Formula:',
        value: '',
        required: true,
        error: false,
        disabled: false,
        language: 'python',
        size: 12,
        hidden: false,
    }
]

export const callBureauNodeFormScheme: TForm = [
    {
        id: '1',
        type: 'title',
        value: 'Nodo Bureau',
        size: 12,
        hidden: false,
    },
    {
        id: '2',
        type: 'text',
        label: 'ID de la persona:',
        value: '',
        placeholder: 'Campo Requerido...',
        required: true,
        error: false,
        minLength: 0,
        maxLength: 100,
        disabled: false,
        size: 12,
        regex: '',
        hidden: false,
    },
    {
        id: '3',
        type: 'options',
        label: 'Consultar con AFIP:',
        value: '', // 0
        placeholder: 'Campo Requerido',
        required: true,
        options: [{ value: 'true', label: 'Si' }, { value: 'false', label: 'No' }],
        error: false,
        disabled: false,
        size: 12,
        hidden: false,
    },
    {
        id: '4',
        type: 'options',
        label: 'Consultar con NOSIS:',
        value: '', // 1
        placeholder: 'Campo Requerido',
        required: true,
        options: [{ value: 'true', label: 'Si' }, { value: 'false', label: 'No' }],
        error: false,
        disabled: false,
        size: 12,
        hidden: false,
    }
]

export const callPolicyNodeFormScheme: TForm = [
    {
        id: '1',
        type: 'title',
        value: 'Nodo Politica de Riesgo',
        size: 12,
        hidden: false,
    },
    {
        id: '2',
        type: 'text',
        label: 'Nombre de resultado:',
        value: '',
        placeholder: 'Campo Requerido...',
        required: true,
        error: false,
        minLength: 0,
        maxLength: 100,
        disabled: false,
        size: 12,
        regex: '',
        hidden: false,
    },
    {
        id: '3',
        type: 'options',
        label: 'Id politica de riesgo:',
        value: '',
        placeholder: 'Campo Requerido',
        required: true,
        options: [],
        error: false,
        disabled: false,
        size: 12,
        hidden: false,
    }
]