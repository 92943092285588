import { TForm } from "FormGenerator"

export const circuitFormScheme: TForm = [

    {
        id: '1',
        type: 'text',
        label: 'Nombre:',
        value: '',
        placeholder: 'Campo Requerido...',
        required: true,
        error: false,
        minLength: 1,
        maxLength: 500,
        disabled: false,
        size: 12,
        regex: '',
        hidden: false,
    },
]


export const stageFormScheme: TForm = [
    {
        id: '1',
        type: 'text',
        label: 'Nombre:',
        value: '',
        placeholder: 'Campo Requerido...',
        required: true,
        error: false,
        minLength: 1,
        maxLength: 500,
        disabled: false,
        size: 12,
        regex: '',
        hidden: false,
    },
]


export const statusFormScheme: TForm = [
    {
        id: '1',
        type: 'text',
        label: 'Nombre:',
        value: '',
        placeholder: 'Campo Requerido...',
        required: true,
        error: false,
        minLength: 1,
        maxLength: 500,
        size: 12,
        disabled: false,
        regex: '',
        hidden: false,
    },
    {
        id: '2',
        type: 'text',
        label: 'Descripción:',
        value: '',
        placeholder: 'Campo Requerido...',
        required: true,
        error: false,
        minLength: 1,
        maxLength: 500,
        disabled: false,
        size: 12,
        regex: '',
        hidden: false,
    },
]