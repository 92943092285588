import { TApiClientResponse, useApiClient } from "../../utils/apiClient.use.util"
import { setup } from "../../utils/setup"
import { ICircuitModel, IStageModel, IStatusModel } from "./circuit.model.store"

interface ICircuitServiceObject {
    getCircuitList: () => TApiClientResponse<Array<ICircuitModel>>
    getCircuit: (circuitId: number) => TApiClientResponse<ICircuitModel>
    createCircuit: (name: string) => TApiClientResponse<ICircuitModel>
    updateCircuit: (circuitId: number, name: string) => TApiClientResponse<null>
    deleteCircuit: (circuitId: number) => TApiClientResponse<null>
    
    assignStageToCircuit: (stage: IStageModel, circuitId: number) => TApiClientResponse<null>
    denyStageToCircuit: (stageId: number, circuitId: number) => TApiClientResponse<null>

    getStageList: () => TApiClientResponse<Array<IStageModel>>
    getStage: (stageId: number) => TApiClientResponse<IStageModel>
    createStage: (name: string) => TApiClientResponse<IStageModel>
    updateStage: (stageId: number, name: string) => TApiClientResponse<null>
    deleteStage: (stageId: number) => TApiClientResponse<null>
    changeStageOrderToCircuit: (circuitId: number, stageList: Array<IStageModel>) => TApiClientResponse<null>
    
    changeStatusOrderToStage: (stageId: number, statusList: Array<IStatusModel>) => TApiClientResponse<null>
    createStatus: (stageId: number, status: IStatusModel) => TApiClientResponse<IStatusModel>
    updateStatus: (stageId: number, status: IStatusModel) => TApiClientResponse<null>
    deleteStatusToStage: (stageId: number, statusId: number) => TApiClientResponse<null>
}

type TUseCircuitService = () => ICircuitServiceObject

export const useCircuitService: TUseCircuitService = () => {

    const apiClient = useApiClient()
    const gateway: string = 'v2/circuits'


    //////////////// circuits

    const getCircuitList: ICircuitServiceObject['getCircuitList'] = async () => {
        const resposne = await apiClient.get<Array<ICircuitModel>>(`${setup.controlPanelApiUrl}/${gateway}/`)
        return resposne
    }

    const getCircuit: ICircuitServiceObject['getCircuit'] = async (circuitId) => {
        const response = await apiClient.get<ICircuitModel>(`${setup.controlPanelApiUrl}/${gateway}/${circuitId}`)
        return response
    }

    const createCircuit: ICircuitServiceObject['createCircuit'] = async (name) => {
        const response = await apiClient.post<ICircuitModel>(`${setup.controlPanelApiUrl}/${gateway}/`, {
            name: name,
            is_active: true,
        })

        return response
    }

    const updateCircuit: ICircuitServiceObject['updateCircuit'] = async (circuitId, name) => {
        const response = await apiClient.patch<null>(`${setup.controlPanelApiUrl}/${gateway}/${circuitId}`, {
            name: name,
            is_active: true,
        })

        return response
    }
    
    
    const deleteCircuit: ICircuitServiceObject['deleteCircuit'] = async (circuitId) => {
        const response = await apiClient.del<null>(`${setup.controlPanelApiUrl}/${gateway}/${circuitId}`)
        return response
    }


    ////////////////////////

    const assignStageToCircuit: ICircuitServiceObject['assignStageToCircuit'] = async (stage, circuitId) => {

        const response = await apiClient.post<null>(`${setup.controlPanelApiUrl}/${gateway}/${circuitId}/stages`, {
            "stage_id": stage.id,
            "order": stage.order,
            "name": stage.name, // todo: hay campos de mas
            "is_active": true
        })

        return response
    }

    const denyStageToCircuit: ICircuitServiceObject['denyStageToCircuit'] = async(stageId, circuitId) => {
        const response = await apiClient.del<null>(`${setup.controlPanelApiUrl}/${gateway}/${circuitId}/stages/${stageId}`)
        return response
    }

    //////////////// stages

    const getStageList: ICircuitServiceObject['getStageList'] = async () => {
        const response = await apiClient.get<Array<IStageModel>>(`${setup.controlPanelApiUrl}/${gateway}/stages/`)
        return response
    }

    const getStage: ICircuitServiceObject['getStage']  = async (stageId) => {
        const response = await apiClient.get<IStageModel>(`${setup.controlPanelApiUrl}/${gateway}/stages/${stageId}`)
        return response
    }
    
    const createStage: ICircuitServiceObject['createStage'] = async (name: string) => {

        const response =  await apiClient.post<IStageModel>(`${setup.controlPanelApiUrl}/${gateway}/stages/`, {
            name: name,
            is_active: true
        })

        return response
    }


    const updateStage:ICircuitServiceObject['updateStage'] = async (stageId, name) => {
        const response = await apiClient.patch<null>(`${setup.controlPanelApiUrl}/${gateway}/stages/${stageId}`, {
            name: name,
            is_active: true,
        })
        return response
    }


    const deleteStage: ICircuitServiceObject['deleteStage'] = async (stageId) => {
        const response = await apiClient.del<null>(`${setup.controlPanelApiUrl}/${gateway}/stages/${stageId}`)
        return response
    }

    const changeStageOrderToCircuit: ICircuitServiceObject['changeStageOrderToCircuit'] = async (circuitId, stageList) => {

        const newObject: any = {}

        stageList.forEach(stage=>{
            newObject[stage.id] = stage.order
        })

        const response =  await apiClient.post<null>(`${setup.controlPanelApiUrl}/${gateway}/${circuitId}/stages-order`, {orders: newObject})

        return response
    }


    //////////// status

    const changeStatusOrderToStage: ICircuitServiceObject['changeStatusOrderToStage'] = async (stageId, statusList) => {

        const newObject: any = {}

        statusList.forEach(status=>{
            newObject[status.id] = status.order
        })

        const response =  await apiClient.post<null>(`${setup.controlPanelApiUrl}/${gateway}/stages/${stageId}/statuses-order`, {orders: newObject})
        
        return response
    }

    const deleteStatusToStage: ICircuitServiceObject['deleteStatusToStage'] = async (stageId, statusId) => {
        const response =  await apiClient.del<null>(`${setup.controlPanelApiUrl}/${gateway}/stages/${stageId}/statuses/${statusId}`)
        return response
    }


    const createStatus: ICircuitServiceObject['createStatus'] = async (stageId, status) => {
        const response =  await apiClient.post<IStatusModel>(`${setup.controlPanelApiUrl}/${gateway}/stages/${stageId}/statuses`, {
            name: status.name, 
            order: status.order,
            description: status.description,
        })
        return response
    }

    const updateStatus: ICircuitServiceObject['updateStatus'] = async(stageId, status) => {

        const response =  await apiClient.patch<null>(`${setup.controlPanelApiUrl}/${gateway}stages/${stageId}/statuses/${status.id}`, {
            name: status.name,
            description: status.description,
            order: status.order,
            isActive: true
        })

        return response
    }



    return {
        getCircuitList, 
        getCircuit, 
        createCircuit, 
        deleteCircuit, 
        updateCircuit, 
        createStage,
        updateStage,
        getStageList, 
        getStage, 
        deleteStage,
        assignStageToCircuit,
        denyStageToCircuit,
        changeStageOrderToCircuit,
        changeStatusOrderToStage,
        createStatus,
        updateStatus,
        deleteStatusToStage
    }
}