import { TForm } from "FormGenerator";

export const searchBureauRequestsSheme: TForm = [
    {
        id: 'fullName',
        type: 'text',
        label: 'Nombre completo',
        size: 6,
        disabled: false,
        error: false,
        hidden: false,
        regex: '',
        maxLength: 100,
        minLength: 3,
        placeholder: '',
        required: false,
        value: ''
    },
    {
        id: 'rfc',
        type: 'text',
        label: 'RFC',
        size: 6,
        disabled: false,
        error: false,
        hidden: false,
        regex: '',
        placeholder: '',
        required: false,
        value: '',
        maxLength: 15,
        minLength: 10,
    }
]