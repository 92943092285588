import { TForm } from "FormGenerator"


export const states: Array<{ value: string, label: string }> = [
    { value: "AGS", label: "Aguascalientes" },
    { value: "BCN", label: "Baja California" },
    { value: "BCS", label: "Baja California Sur" },
    { value: "CAM", label: "Campeche" },
    { value: "CDMX", label: "Ciudad de México" },
    { value: "CHS", label: "Chiapas" },
    { value: "CHI", label: "Chihuahua" },
    { value: "COA", label: "Coahuila" },
    { value: "COL", label: "Colima" },
    { value: "DGO", label: "Durango" },
    { value: "EM", label: "Estado de México" },
    { value: "GTO", label: "Guanajuato" },
    { value: "GRO", label: "Guerrero" },
    { value: "HGO", label: "Hidalgo" },
    { value: "JAL", label: "Jalisco" },
    { value: "MICH", label: "Michoacán" },
    { value: "MOR", label: "Morelos" },
    { value: "NAY", label: "Nayarit" },
    { value: "NL", label: "Nuevo León" },
    { value: "OAX", label: "Oaxaca" },
    { value: "PUE", label: "Puebla" },
    { value: "QRO", label: "Querétaro" },
    { value: "QR", label: "Quintana Roo" },
    { value: "SLP", label: "San Luis Potosí" },
    { value: "SIN", label: "Sinaloa" },
    { value: "SON", label: "Sonora" },
    { value: "TAB", label: "Tabasco" },
    { value: "TAM", label: "Tamaulipas" },
    { value: "TLAX", label: "Tlaxcala" },
    { value: "VER", label: "Veracruz" },
    { value: "YUC", label: "Yucatán" },
    { value: "ZAC", label: "Zacatecas" }
]


export const nipSheme: TForm = [
    {
        id: '0',
        type: 'title',
        value: 'Ingresar NIP',
        size: 12,
        hidden: false,
    },
    {
        id: '1',
        label: 'NIP',
        type: 'text',
        value: '',
        placeholder: 'Campo requerido...',
        regex: '^[0-9]+$',
        required: true,
        error: false,
        minLength: 4,
        maxLength: 4,
        disabled: false,
        size: 12,
        hidden: false,
    },
]


export const emailSheme: TForm = [
    {
        id: '0',
        type: 'title',
        value: 'Ingresar Email',
        size: 12,
        hidden: false,
    },
    {
        id: '1',
        label: 'Email',
        type: 'email',
        value: '',
        placeholder: 'Campo requerido...',
        required: true,
        error: false,
        minLength: 0,
        maxLength: 500,
        disabled: false,
        size: 12,
        hidden: false,
    }
]

export const bureauPersonTypeCheme: TForm = [
    {
        id: '1',
        type: 'options',
        label: 'Tipo de Persona',
        value: '',
        placeholder: 'Campo requerido...',
        required: true,
        options: [
            { value: 'PF', label: 'Persona Física' },
            { value: 'PFAE', label: 'Persona Física con Actividad Empresarial' },
            { value: 'PM', label: 'Persona Moral' }
        ],
        error: false,
        disabled: false,
        size: 6,
        hidden: false,
    }
]


/*

persona fisica
{
    "apellidoPaterno": "ADAMS",
    "apellidoMaterno": "ARETIA",
    "primerNombre": "DIMITRI",
    "segundoNombre": "ARNULFO",
    "nombre": null,
    "rfc": "BEAS50121757A",
    "nacionalidad": "MX",
    "calle": "NEGOCIO. AVENIDA PENSAMIENTO MANZANA 2",
    "nroCalle": "2",
    "coloniaPoblacion": "PASO LIMON",
    "delegacionMunicipio": "CUAJIMALPA DE MORELOS",
    "ciudad": null,
    "estado": "CDMX",
    "cp": "05120"
}

*/


export const bureauPFsheme: TForm = [
    {
        id: '1',
        type: 'text',
        label: 'Apellido Paterno',
        value: '',
        placeholder: 'Campo requerido...',
        regex: '^[a-zA-Z0-9., ]*$',
        required: true,
        error: false,
        minLength: 0,
        maxLength: 1000,
        disabled: false,
        size: 6,
        hidden: false,
    },
    {
        id: '2',
        type: 'text',
        label: 'Apellido Materno',
        value: '',
        placeholder: 'Campo requerido...',
        regex: '^[a-zA-Z0-9., ]*$',
        required: true,
        error: false,
        minLength: 0,
        maxLength: 1000,
        disabled: false,
        size: 6,
        hidden: false,
    },
    {
        id: '3',
        type: 'text',
        label: 'Primer Nombre',
        value: '',
        placeholder: 'Campo requerido...',
        regex: '^[a-zA-Z0-9., ]*$',
        required: true,
        error: false,
        minLength: 0,
        maxLength: 1000,
        disabled: false,
        size: 6,
        hidden: false,
    },
    {
        id: '4',
        type: 'text',
        label: 'Segundo Nombre',
        value: '',
        placeholder: '',
        regex: '^[a-zA-Z0-9., ]*$',
        required: false,
        error: false,
        minLength: 0,
        maxLength: 1000,
        disabled: false,
        size: 6,
        hidden: false,
    },
    {
        id: '5',
        label: 'RFC',
        placeholder: 'Campo requerido...',
        type: 'text',
        required: true,
        regex: '^[a-zA-Z0-9]+$',
        error: false,
        value: '',
        maxLength: 15,
        minLength: 10,
        disabled: false,
        size: 6,
        hidden: false,
    },
    {
        id: '6',
        type: 'text',
        label: 'Calle',
        value: '',
        placeholder: 'Campo requerido...',
        regex: '^[a-zA-Z0-9., ]*$',
        required: true,
        error: false,
        minLength: 0,
        maxLength: 1000,
        disabled: false,
        size: 6,
        hidden: false,
    },
    {
        id: '7',
        type: 'number',
        label: 'NRO Calle',
        value: '',
        maxValue: 100000,
        minValue: 0,
        placeholder: 'Campo requerido...',
        required: true,
        error: false,
        disabled: false,
        size: 6,
        hidden: false,
    },
    {
        id: '8',
        type: 'text',
        label: 'Colonia Población',
        value: '',
        placeholder: '',
        regex: '^[a-zA-Z0-9., ]*$',
        required: false,
        error: false,
        minLength: 0,
        maxLength: 1000,
        disabled: false,
        size: 6,
        hidden: false,
    },
    {
        id: '9',
        type: 'text',
        label: 'Delegación Municipio',
        value: '',
        placeholder: 'Campo requerido...',
        regex: '^[a-zA-Z0-9., ]*$',
        required: true,
        error: false,
        minLength: 0,
        maxLength: 1000,
        disabled: false,
        size: 6,
        hidden: false,
    },
    {
        id: '10',
        type: 'text',
        label: 'Ciudad',
        value: '',
        placeholder: 'Campo requerido...',
        regex: '^[a-zA-Z0-9., ]*$',
        required: true,
        error: false,
        minLength: 0,
        maxLength: 1000,
        disabled: false,
        size: 6,
        hidden: false,
    },
    {
        id: '11',
        type: 'text',
        label: 'CP',
        value: '',
        placeholder: 'Campo requerido...',
        regex: '^[a-zA-Z0-9., ]*$',
        required: true,
        error: false,
        minLength: 0,
        maxLength: 1000,
        disabled: false,
        size: 6,
        hidden: false,
    },
    {
        id: '12',
        type: 'options',
        label: 'Estado',
        value: '',
        placeholder: 'Campo requerido...',
        required: true,
        options: [...states],
        error: false,
        disabled: false,
        size: 6,
        hidden: false,
    }
]

///////////////////////

export const bureauPFAEsheme = [...bureauPFsheme]

///////////////////////


export const bureauPMSheme: TForm = [
    {
        id: '1',
        type: 'text',
        label: 'Nombre',
        value: '',
        placeholder: 'Campo requerido...',
        regex: '^[a-zA-Z0-9., ]*$',
        required: true,
        error: false,
        minLength: 0,
        maxLength: 1000,
        disabled: false,
        size: 6,
        hidden: false,
    },
    {
        id: '2',
        type: 'text',
        label: 'Calle',
        value: '',
        placeholder: 'Campo requerido...',
        regex: '^[a-zA-Z0-9., ]*$',
        required: true,
        error: false,
        minLength: 0,
        maxLength: 1000,
        disabled: false,
        size: 6,
        hidden: false,
    },
    {
        id: '3',
        type: 'number',
        label: 'NRO Calle',
        value: '',
        maxValue: 100000,
        minValue: 0,
        placeholder: 'Campo requerido...',
        required: true,
        error: false,
        disabled: false,
        size: 6,
        hidden: false,
    },

    {
        id: '5',
        label: 'RFC',
        placeholder: 'Campo requerido...',
        type: 'text',
        required: true,
        regex: '^[a-zA-Z0-9]+$',
        error: false,
        value: '',
        minLength: 10,
        maxLength: 15,
        disabled: false,
        size: 6,
        hidden: false,
    },
    {
        id: '4',
        type: 'text',
        label: 'Colonia Población',
        value: '',
        placeholder: '',
        regex: '^[a-zA-Z0-9., ]*$',
        required: false,
        error: false,
        minLength: 0,
        maxLength: 1000,
        disabled: false,
        size: 6,
        hidden: false,
    },
    {
        id: '6',
        type: 'text',
        label: 'Delegación Municipio',
        value: '',
        placeholder: 'Campo requerido...',
        regex: '^[a-zA-Z0-9., ]*$',
        required: true,
        error: false,
        minLength: 0,
        maxLength: 1000,
        disabled: false,
        size: 6,
        hidden: false,
    },
    {
        id: '7',
        type: 'text',
        label: 'Ciudad',
        value: '',
        placeholder: 'Campo requerido...',
        regex: '^[a-zA-Z0-9., ]*$',
        required: true,
        error: false,
        minLength: 0,
        maxLength: 1000,
        disabled: false,
        size: 6,
        hidden: false,
    },
    {
        id: '8',
        type: 'text',
        label: 'CP',
        value: '',
        placeholder: '',
        regex: '^[a-zA-Z0-9., ]*$',
        required: true,
        error: false,
        minLength: 0,
        maxLength: 1000,
        disabled: false,
        size: 6,
        hidden: false,
    },
    {
        id: '9',
        type: 'options',
        label: 'Estado',
        value: '',
        placeholder: 'Campo requerido...',
        required: true,
        options: [...states],
        error: false,
        disabled: false,
        size: 6,
        hidden: false,
    }
]



////////////////////////////////

/*
PFCAE
{
    "apellidoPaterno": "QUALITYSEVEN",
    "apellidoMaterno": "ZABALA",
    "primerNombre": "TESTSEVEN",
    "segundoNombre": null,
    "nombre": null,
    "rfc": "ROZL690326J37",
    "nacionalidad": "MX",
    "calle": "NEGOCIO. AVENIDA PENSAMIENTO MANZANA 2",
    "nroCalle": "2",
    "coloniaPoblacion": "PASO LIMON",
    "delegacionMunicipio": "TUXTLA GUTIERREZ",
    "ciudad": null,
    "estado": "CHS",
    "cp": "29049"
}





*/