import React from 'react'
import store from 'react-flow-renderer/dist/store'
import { useStoreUtil } from '../../utils/useStore.util'
import { useTraceHelper } from './trace.helper.store'
import { ITraceStore } from './trace.interface.store'
import { ITrace } from './trace.model.store'
import { useTraceService } from './trace.service.store'

export const useTraceStore = (): ITraceStore => {

    const storeUtil = useStoreUtil()

    const traceHelper = useTraceHelper()

    const traceService = useTraceService()

    const [traceList, setTraceList] = React.useState<ITraceStore['traceList']>([])
    const [traceCopied, setTraceCopied] = React.useState<ITrace>(traceHelper.createTrace())
    const [traceCreated, setTraceCreated] = React.useState<ITrace>(traceHelper.createTrace())

    const createTrace: ITraceStore['createTrace'] = async (riskId, traceName) => {
        const response = await traceService.createTrace(riskId, traceName)
        if(response.isError) return storeUtil.createErrorResult(traceHelper.createTrace())
        await setTraceCreated(response.data)
        setTraceList([...traceList, response.data])
        return storeUtil.createSuccessResult(response.data)
    }

    const createLoadedTraceWithOutUpdateTraceList: ITraceStore['createLoadedTraceWithOutUpdateTraceList'] = async (trace, riskId) => {
        const response = await traceService.copyTraceForOtherRisk(trace, riskId)
        if(response.isError) return storeUtil.createErrorResult(traceHelper.createTrace())
        else return storeUtil.createSuccessResult(response.data)
    }

    const copyTrace: ITraceStore['copyTrace'] = async (traceId) => {
        const traceFinded = traceList.find(trace => trace.id === traceId)
        if(traceFinded) {
            const response = await traceService.copyTrace(traceFinded)
            if(response.isError) return storeUtil.createErrorResult(traceHelper.createTrace())
            setTraceCopied(response.data)
            setTraceList([...traceList, response.data])
            return storeUtil.createSuccessResult(response.data)
        } else {            const response = await getTrace(traceId)
            if(response.isError) return storeUtil.createErrorResult(traceHelper.createTrace())
            const traceCopyResponse = await traceService.copyTrace(response.data)
            if(traceCopyResponse.isError) return storeUtil.createErrorResult(traceHelper.createTrace())
            setTraceCopied(traceCopyResponse.data)
            setTraceList([...traceList, response.data, traceCopyResponse.data])
            return storeUtil.createSuccessResult(traceCopyResponse.data)
        }
    }

    const getTraceList: ITraceStore['getTraceList'] = async () => {
        const response = await traceService.getTraceList()
        if(response.isError) return storeUtil.createErrorResult(null)
        setTraceList(response.data) 
        return storeUtil.createSuccessResult(null)
    }

    const getTraceListByRiskId:  ITraceStore['getTraceListByRiskId'] = async (riskId) => {
        const response = await traceService.getTraceListByRiskId(riskId)
        if(response.isError) return storeUtil.createErrorResult(null)
        setTraceList(response.data)
        return storeUtil.createSuccessResult(null)
    }

    const deleteTrace: ITraceStore['deleteTrace'] = async (id) => {
        const response = await traceService.deleteTrace(id)
        if(response.isError) return storeUtil.createErrorResult(null)
        const traceListUpdated = traceList.filter(trace => trace.id !== id)
        setTraceList(traceListUpdated)
        return storeUtil.createSuccessResult(null)
    }

    const getTrace: ITraceStore['getTrace'] = async (id) => {
        const traceFinded = traceList.find(trace => trace.id === id)
        if (traceFinded) return storeUtil.createSuccessResult(traceFinded)
        const response = await traceService.getTrace(id)
        if (response.isError) return storeUtil.createErrorResult(traceHelper.createTrace())
        setTraceList([...traceList, response.data])
        return storeUtil.createSuccessResult(response.data)
    }

    const updateTrace: ITraceStore['updateTrace'] = async (traceUpdated) => {
        const response = await traceService.updateTrace(traceUpdated)
        if(response.isError) return storeUtil.createErrorResult(null)

        const traceFinded = traceList.find(trace => trace.id === traceUpdated.id) // aca se puede reutilizar el get

        if (traceFinded) {
            const traceListUpdated = traceList.map(trace => trace.id === traceUpdated.id ? traceUpdated : trace)
            setTraceList(traceListUpdated)
            return storeUtil.createSuccessResult(null)
        }

        const traceListUpdated = [...traceList, traceUpdated]
        setTraceList(traceListUpdated)
        return storeUtil.createSuccessResult(null)
    }

    const activateTrace: ITraceStore['activateTrace'] = async (traceId) => {
        const response = await getTrace(traceId)
        if(response.isError) return storeUtil.createErrorResult(null)
        const traceActivated: ITrace = {...response.data, is_active: true}
        const traceUpdatedResponse = await traceService.updateTrace(traceActivated)
        if(traceUpdatedResponse.isError) return storeUtil.createErrorResult(null)
        const traceListUpdated = [...traceList].map(trace=>trace.id !== traceActivated.id  ? {...trace, is_active: false} :  traceActivated) 
        setTraceList(traceListUpdated)
        return storeUtil.createSuccessResult(null)
    }
    
    return { 
        traceList, 
        traceCopied, 
        createLoadedTraceWithOutUpdateTraceList,
        traceCreated, 
        createTrace, 
        copyTrace, 
        getTraceList, 
        getTraceListByRiskId, 
        deleteTrace, 
        getTrace, 
        updateTrace, 
        activateTrace }
}













