import { TForm } from 'FormGenerator'

export const sourceFormSheme: TForm = [
    {
        id: '1',
        type: 'text',
        label: 'Nombre',
        value: '',
        placeholder: 'Campo Requerido...',
        regex: '',
        required: true,
        error: false,
        minLength: 1,
        maxLength: 5000,
        disabled: false,
        size: 12,
        hidden: false,
    },
    {
        id: '2',
        type: 'text',
        label: 'Descripción',
        value: '',
        placeholder: 'Campo Requerido...',
        regex: '',
        required: true,
        error: false,
        minLength: 1,
        maxLength: 5000,
        disabled: false,
        size: 12,
        hidden: false,
    },
    {
        id: '3',
        type: 'text',
        label: 'URL',
        value: '',
        placeholder: 'Campo Requerido...',
        regex: '',
        required: true,
        error: false,
        minLength: 1,
        maxLength: 5000,
        disabled: false,
        size: 12,
        hidden: false,
    }
]