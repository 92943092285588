import { useState } from "react";
import store from "react-flow-renderer/dist/store";
import { useStoreUtil } from "../../utils/useStore.util";
import { IBureauStore } from "./bureau.interface.store";
import { useBureauService } from "./bureau.service.store";

export const useBureauStore = (): IBureauStore => {

    const storeUtil = useStoreUtil()
    const bureauService = useBureauService()

    const [result, setRestult] = useState<IBureauStore['result']>(null)
    const [bureauRequestReportList, setBureauRequestReportList] = useState<IBureauStore['bureauRequestReportList']>([])

    ///////////////

    const executeDownloadBureauMexicoPF: IBureauStore['executeDownloadBureauMexicoPF'] = async param => {
        const response = await bureauService.executeDownloadBureauMexicoPF(param)
        if(response.isSuccess) {
            return storeUtil.createSuccessResult(response.data)
        }
        return storeUtil.createErrorResult(response.data)
    }

    const executeDownloadBureauMexicoPFAE: IBureauStore['executeDownloadBureauMexicoPFAE'] = async param => {
        const response = await bureauService.executeDownloadBureauMexicoPFAE(param)
        if(response.isSuccess) {
            return storeUtil.createSuccessResult(response.data)
        }
        return storeUtil.createErrorResult(response.data)
    }

    const executeDownloadBureauMexicoPM: IBureauStore['executeDownloadBureauMexicoPM'] = async param => {
        const response = await bureauService.executeDownloadBureauMexicoPM(param)
        if(response.isSuccess) {
            return storeUtil.createSuccessResult(response.data)
        }
        return storeUtil.createErrorResult(response.data)
    }

    ///////////////

    const executeBureauMexicoPF: IBureauStore['executeBureauMexicoPF'] = async param => {
        setRestult({})
        const response = await bureauService.executeBureauMexicoPF(param)
        if(response.isSuccess) {
            setRestult(response.data)
            return storeUtil.createSuccessResult(response.data)
        }

        return storeUtil.createErrorResult(response.data)
    }
    const executeBureauMexicoPFAE: IBureauStore['executeBureauMexicoPFAE'] = async param => {
        setRestult({})
        const response = await bureauService.executeBureauMexicoPFAE(param)
        if(response.isSuccess) {
            setRestult(response.data)
            return storeUtil.createSuccessResult(response.data)
        }

        return storeUtil.createErrorResult(response.data)

    }
    const executeBureauMexicoPM: IBureauStore['executeBureauMexicoPM'] = async param => {
        setRestult(null)
        const response = await bureauService.executeBureauMexicoPM(param)
        if(response.isSuccess) {
            setRestult(response.data)
            return storeUtil.createSuccessResult(response.data)
        }

        return storeUtil.createErrorResult(response.data)
    }
    //////////
    const verifyNip: IBureauStore['verifyNip'] = async param => {
        const response = await bureauService.verifyNip(param)
        if(response.isSuccess) return storeUtil.createSuccessResult(response.data)
        else return storeUtil.createErrorResult(response.data)
    }
    const generateNip: IBureauStore['generateNip'] = async param => {
        const response = await bureauService.generateNip(param)
        if(response.isSuccess) return storeUtil.createSuccessResult(response.data)
        else return storeUtil.createErrorResult(response.data)
    }
    const validateNip: IBureauStore['validateNip'] = async (param) => {
        const response = await bureauService.validateNip(param)
        if(response.isSuccess) return storeUtil.createSuccessResult(response.data)
        else return storeUtil.createErrorResult(response.data)
    }

    /////////

    const executeDownloadNipReportCSV: IBureauStore['executeDownloadNipReportCSV'] = async param => {
        const response = await bureauService.executeDownloadNipReportCSV(param)
        if(response.isSuccess) {
            return storeUtil.createSuccessResult(response.data)
        }
        return storeUtil.createErrorResult(response.data)
    }


    ////////////////

    const getBureauRequestList: IBureauStore['getBureauRequestList'] = async param => {
        setBureauRequestReportList([])
        const response = await bureauService.getBureauRequestList(param)
        if(response.isError) return storeUtil.createErrorResult(response.data)
        setBureauRequestReportList(response.data)
        return storeUtil.createSuccessResult(null)
    }

    const getBureauRequest: IBureauStore['getBureauRequest'] = async id => {
        const bureauRequestFinded = bureauRequestReportList.find(request=> request._id === id)
        if(bureauRequestFinded) return storeUtil.createSuccessResult(bureauRequestFinded)
        const response = await bureauService.getBureauRequestDetail(id)
        if(response.isError) return storeUtil.createErrorResult(response.data)
        else return storeUtil.createSuccessResult(response.data)
    }


    const downloadBureauRequestDetail: IBureauStore['downloadBureauRequestDetail'] = async (id) => {
        const response = await bureauService.downloadBureauRequestDetail(id)
        if(response.data) return storeUtil.createSuccessResult(response.data)
        else return storeUtil.createErrorResult(response.data)
    }

    ////////
    return {
        result,
        executeBureauMexicoPF,
        executeBureauMexicoPFAE,
        executeBureauMexicoPM,

        executeDownloadBureauMexicoPF,
        executeDownloadBureauMexicoPFAE,
        executeDownloadBureauMexicoPM,
        executeDownloadNipReportCSV,

        verifyNip,
        generateNip,
        validateNip,

        bureauRequestReportList,
        getBureauRequestList,
        getBureauRequest,
        downloadBureauRequestDetail
    }
}