import { IExecuted, IRisk } from "./risk.model.store";

export const useRiskHelper = () => ({
    createExecuted: (): IExecuted => ({
        exec_nodes: [],
        execution_id: 0,
        inputData: {},
        is_error: false,
        response: '',
        risk_policy_id: 0,
        trace_id: 0,
        treeData: {}
    }),
    createRisk: (): IRisk=>({
        id: 0,
        type: 1,
        name: '',
        description: '',
        schema: [],
        fields_group: [],
        active_trace_id: 0,
        is_active: false,
    })
})