import React from 'react'
import { Link } from 'react-router-dom'
import { FormWrapper, Href, Title, Text, EaseInAnimation } from '../elements/Element'
import { setup } from '../utils/setup'

export const NoAuthPage = () => {
    return (
        <EaseInAnimation>
            <div>
                <Title>No estas autenticado</Title>
                <FormWrapper style={{textAlign: 'center'}}>
                    <Text>Es necesario que ingrese con su cuenta de usuario en el siguiente enlace</Text>
                    <Href href={setup.signInAppUrl}>Acceder</Href>    
                </FormWrapper>
            </div>
        </EaseInAnimation>
    )
}