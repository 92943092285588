import { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { FormGenerator, getValueToForm, TForm } from 'FormGenerator'
import { EaseInAnimation, Title } from '../elements/Element'
import { addPreloadDataToStatusFormScheme } from '../helpers/Circuit.helper'
import { stageFormScheme, statusFormScheme } from '../schemes/circuit.form.scheme'
import { IAppStore } from '../stores/app/app.interface.store'
import { circuitFactory, statusFactory } from '../stores/circuit/circuit.helper.store'
import { ICircuitStoreObject } from '../stores/circuit/circuit.interface.store'

interface CircuitStageCreateStatusPageProps {
    circuitStore: ICircuitStoreObject
    appStore: IAppStore
}

export const CircuitStageCreateStatusPage: FC<CircuitStageCreateStatusPageProps> = (props) => {

    const history = useHistory()
    const [formData, setFormData] = useState<TForm>([])
    const  params = useParams<{ circuitId: string , stageId: string, statusId: string}>()

    useEffect(() => { onInit() }, [])

    const onInit = async () => {

        if(params.statusId) {
            props.appStore.showLoader()
            const result = await props.circuitStore.getStatus(Number(params.stageId), Number(params.statusId))
            
            props.appStore.hideLoader()

            if(result.isSuccess) return setFormData(addPreloadDataToStatusFormScheme(statusFormScheme, result.data))
            if(result.isError) return props.appStore.openApiErrorAlert()

        }else {
            setFormData([...statusFormScheme])
        }
    }


    const onSubmitFormHandler = async () => {

        const newStatusName = getValueToForm(formData, '1')
        const newStatusDescription = getValueToForm(formData, '2')

        if(params.statusId) {
            props.appStore.showLoader()
            const getStatusResult = await props.circuitStore.getStatus(Number(params.stageId), Number(params.statusId))
            if(getStatusResult.isError) props.appStore.openApiErrorAlert() 

            const updateStatusResult = await props.circuitStore.updateStatus(Number(params.stageId), {...getStatusResult.data, name: newStatusName, description: newStatusDescription})
            if(updateStatusResult.isError) props.appStore.openApiErrorAlert() 
            props.appStore.hideLoader()
            if(updateStatusResult.isSuccess) history.push(`/circuit/${params.circuitId}/stage/${params.stageId}/status`)
        } else {
            props.appStore.showLoader()
            const createStatusResult = await props.circuitStore.createStatus(
                Number(params.stageId),
                {...statusFactory(), name: newStatusName, description: newStatusDescription
            })

            if(createStatusResult.isError) {
                props.appStore.hideLoader()
                props.appStore.openApiErrorAlert()
                return
            }

            if(createStatusResult.isSuccess) {
                props.appStore.hideLoader()
                history.push(`/circuit/${params.circuitId}/stage/${params.stageId}/status`)
                return 
            }
            
        }
    }
    
    return (
        <EaseInAnimation>
            <br/><br/>
            <Title>{params.statusId ? `Modificar el status #${params.statusId}` : 'Crear nuevo status' }</Title>
            <br/><br/>
            <FormGenerator
                dataTestId="createCircuit"
                form={formData}
                setForm={setFormData}
                onSubmit={onSubmitFormHandler}
                submitText="Guardar"
                isShowSubmit={true}
                actions={[]}
                onAction={()=>{}}
                onValidForm={()=>{}}
                onInvalidForm={()=>{}}
            />
        </EaseInAnimation>
    )
}