import { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Button, Card, Cell, EaseInAnimation, FloatPlusButton, FloatSaveButton, Loader, Row, Title } from '../elements/Element'
import { stageFormScheme } from '../schemes/circuit.form.scheme'
import { IAppStore } from '../stores/app/app.interface.store'
import { circuitFactory } from '../stores/circuit/circuit.helper.store'
import { ICircuitStoreObject } from '../stores/circuit/circuit.interface.store'
import { ICircuitModel, IStageModel } from '../stores/circuit/circuit.model.store'

interface CircuitPageProps {
    circuitStore: ICircuitStoreObject
    appStore: IAppStore
}

export const CircuitStagePage: FC<CircuitPageProps> = (props) => {

    const history = useHistory()
    const { circuitId } = useParams<{ circuitId: string }>()
    const [currentCircuit, setCurrentCircuit] = useState<ICircuitModel>(circuitFactory())

    useEffect(() => { onInit() }, [])

    const onInit = async () => {
        props.appStore.showLoader()
        const response = await props.circuitStore.getCircuit(Number(circuitId))
        if (response.isSuccess) setCurrentCircuit(response.data)
        if (response.isError) props.appStore.openApiErrorAlert()
        props.appStore.hideLoader()
    }

    const onClickHandlerFromFloatPlusButton = () => {
        history.push(`/circuit/${circuitId}/stage/add`)
    }

    const onDenyStageToCircuitHandler = async (stageId: number) => {
        props.appStore.showLoader()
        const denyStageToCircuitResult = await props.circuitStore.denyStageToCircuit(stageId, Number(circuitId))
        if(denyStageToCircuitResult.isError) props.appStore.openApiErrorAlert()
        if(denyStageToCircuitResult.isSuccess) setCurrentCircuit(denyStageToCircuitResult.data)
        props.appStore.hideLoader()
    }

    


    const onChangeOrderHandler = async (currentOrder: number, newOrder: number) => {
        props.appStore.showLoader()
        
        const newStageList = currentCircuit.stages.map(stage => ({
            ...stage, 
            order: stage.order === currentOrder ? newOrder : 
            stage.order === newOrder ?  currentOrder :
            stage.order
        }))
        .sort((a,b)=> a.order-b.order)

        const result = await props.circuitStore.changeStageOrderToCircuit(currentCircuit.id, newStageList)

        if(result.isError) {
            props.appStore.openApiErrorAlert()
        }

        if(result.isSuccess) {
            setCurrentCircuit(result.data)
        }


        props.appStore.hideLoader()
    }

    return (
        <EaseInAnimation>

            <br></br><br></br>
            <Title>Lista de Stages asignados al circuito #{currentCircuit.id}</Title>
            <br></br>

            {currentCircuit.stages.map((stage, index) => (
                <div key={stage.id}>
                    <Card>
                        <Row>
                            <Cell width={5} aling={'center'}>#{stage.id}</Cell>
                            <Cell width={55} aling={'center'}>{stage.name}</Cell>
                            <Cell width={40} aling={'right'}>
                                <Row>
                                    <Cell width={33.33} aling={'center'}><Button style={{width: '100%'}} disabled={index === 0} onClick={()=>onChangeOrderHandler(stage.order, stage.order - 1)}>▲</Button></Cell>
                                    <Cell width={33.33} aling={'center'}><Button style={{width: '100%'}} disabled={index + 1 === currentCircuit.stages.length} onClick={()=>onChangeOrderHandler(stage.order, stage.order + 1)}>▼</Button></Cell>
                                    <Cell width={33.33} aling={'center'} onClick={()=>onDenyStageToCircuitHandler(stage.id)}><Button style={{width: '100%'}}>✕</Button></Cell>
                                </Row>
                            </Cell>
                        </Row>
                    </Card>
                    <br />
                </div>
            ))}

            <br></br><br></br>

            <FloatPlusButton data-testid="FloatPlusButton" onClick={onClickHandlerFromFloatPlusButton} />

        </EaseInAnimation>
    )
}