import React, { FC } from 'react'
import { Navigation, NavigationDropDownItem, NavigationDropDownLabel, NavigationDropDownList, NavigationHref, NavigationItem, NavigationItemLink, NavigationList } from '../elements/Element'
import logo from '../assets/logo-dark-mode.svg'
import { setup } from '../utils/setup'
import { useHistory } from 'react-router'
import { ISetupStore } from '../stores/setup/setup.use.store'

interface INavigationComponentProps {
  setupStore: ISetupStore
}

export const NavigationComponent: FC<INavigationComponentProps> = props => {

    const history = useHistory()


    const getPanelUrl = () => setup.panelAppUrl

    return (
        <Navigation>
          <NavigationList>

            <NavigationItem>
              <img src={logo} alt="Siembro" style={{ width: '100px', marginTop: '1px', marginLeft: '10px' }} />
            </NavigationItem>

            <NavigationItem>
              <NavigationHref href={`${getPanelUrl()}`} text="Inicio"/>
            </NavigationItem>

            <NavigationItem>
              <NavigationHref href={`${getPanelUrl()}/ctc/contact/`} text="Contactos"/>
            </NavigationItem>

            <NavigationItem>
              <NavigationHref href={`${getPanelUrl()}/sie/transfer-request/`} text="Depósitos/ Transferencias"/>
            </NavigationItem>

            <NavigationDropDownItem>
              <NavigationDropDownLabel>Configuración</NavigationDropDownLabel>
              <NavigationDropDownList>
                <NavigationItemLink url={`/circuit`} text="Circuitos"/>
                <NavigationHref href={`${getPanelUrl()}`} text="Sistema"/>
                <NavigationHref href={`${getPanelUrl()}/sie/notifications/`} text="Notificaciones"/>
                <NavigationHref href={`${getPanelUrl()}/sie/system/commercial-policies/`} text="Políticas comerciales"/>
                <NavigationHref href={`${getPanelUrl()}/sie/forms/`} text="Formularios"/>
                <NavigationHref href={`${getPanelUrl()}/conf/user/`} text="Usuarios"/>
                <NavigationHref href={`${getPanelUrl()}/conf/security/permission/`} text="Permisos"/>
                <NavigationHref href={`${getPanelUrl()}/conf/security/access-groups/`} text="Grupos de acceso"/>
                <NavigationHref href={`${getPanelUrl()}/user_category/`} text="Vendors/Merchant"/>
                <NavigationHref href={`${getPanelUrl()}/loan-request/document/type/index`} text="Filtros de documentos"/>
                <NavigationHref href={`${getPanelUrl()}/calculated_debit/`} text="Débitos calculados"/>
                <NavigationHref href={`${getPanelUrl()}/calculated_debit_custom_variable/`} text=" Débitos calculados-variables"/>
              </NavigationDropDownList>
            </NavigationDropDownItem>
          </NavigationList>

          <NavigationDropDownItem>
            <NavigationDropDownLabel>Créditos</NavigationDropDownLabel>
            <NavigationDropDownList>
                <NavigationHref href={`${getPanelUrl()}/sie/credits/loan-request/`} text="Solicitudes"/>
                <NavigationHref href={`${getPanelUrl()}/sie/credits/`} text="Créditos otorgados"/>
                <NavigationHref href={`${getPanelUrl()}/sie/cuentas-corrientes/`} text="Cuentas corrientes"/>
                <NavigationHref href={`${getPanelUrl()}/sie/credits/loan-request/simulator`} text="Simulador"/>
                <NavigationHref href={`${getPanelUrl()}`} text="Vencimientos"/>
                <NavigationHref href={`${getPanelUrl()}/sie/documents/expired`} text=" Documentos vencidos"/>
            </NavigationDropDownList>
          </NavigationDropDownItem>

          <NavigationDropDownItem>
            <NavigationDropDownLabel>Políticas de Riesgo</NavigationDropDownLabel>
            <NavigationDropDownList>
                <NavigationItemLink url={`/`} text="Lista de Políticas de Riesgo"/>
                <NavigationItemLink url={`/search/execution`} text="Buscador de Ejecuciones"/>
                <NavigationItemLink url={`/sourceList`} text="Fuente de datos añadidas"/>
                {props.setupStore.currentCountry === 'México' ? (
                  <NavigationItemLink url={`/nipBureauReport`} text="Informe NIP Bureau"/>
                ) : null}
                {props.setupStore.currentCountry === 'México' ? (
                  <NavigationItemLink url={`/bureau`} text="Consultar Bureau"/>
                ) : null}
                {props.setupStore.currentCountry === 'México' ? (
                  <NavigationItemLink url={`/bureauHistory`} text="Historial Bureau"/>
                ) : null}
            </NavigationDropDownList>
          </NavigationDropDownItem>

          <NavigationDropDownItem>
            <NavigationDropDownLabel>Usuario</NavigationDropDownLabel>
            <NavigationDropDownList>
              <NavigationHref href={`${getPanelUrl()}/conf/user/profile`} text="Perfil"/>
              <NavigationHref href={`${getPanelUrl()}/logout`} text="Salir"/>
            </NavigationDropDownList>
          </NavigationDropDownItem>
        </Navigation>
    )
}