import React from 'react'
import { TUseAppStore } from './app.interface.store'

export const useAppStore: TUseAppStore = () => {

    const [isLoading, setIsLoading] = React.useState<boolean>(false)

    const [isOpenAlert, setIsOpenAlert] = React.useState<boolean>(false)
    const [status, setStatus] = React.useState<'success' | 'danger'>('success')
    const [message, setMessage] = React.useState<string>('')

    const showLoader = () => setIsLoading(true)
    const hideLoader = () => setIsLoading(false)

    const openAlert = (status: 'success' | 'danger', message: string) => {
        setMessage(message)
        setStatus(status)
        setIsOpenAlert(true)
        setTimeout(()=> {
            setIsOpenAlert(false)
        }, 5000)
    }

    const closeAlert = () => setIsOpenAlert(false)

    const openApiErrorAlert = () => {
        openAlert('danger', "Error al intentar realizar la acción, no hemos podido conectar al servidor, reintentar en unos minutos.")
    }

    const openImportTrazeSuccessAlert = (traceId: number, traceTitle: string) => {
        openAlert('success',`Se importó correctamente la traza ${traceId} - ${traceTitle}`)

    }

    const openExportTraceSuccessAlert = (fileName: string) => {
        openAlert('success',`Se Descargo correctamente el archivo ${fileName}`)
    } 

    return { 
        isLoading,
        showLoader,
        hideLoader,
        isOpenAlert, 
        openAlert, 
        closeAlert, 
        status, 
        message, 
        openApiErrorAlert, 
        openImportTrazeSuccessAlert, 
        openExportTraceSuccessAlert
    }
}
