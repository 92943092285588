import { TApiClientResponse, useApiClient } from "../../utils/apiClient.use.util"
import { setup } from "../../utils/setup"
import { IExecuted } from "./execute.model.store"


interface IExecuteService {
    getExecutionByCuit: (cuit: number)=> TApiClientResponse<Array<IExecuted>>
    getExecutionById: (executionId: number) => TApiClientResponse<IExecuted>
}

type TUseExecuteService = () => IExecuteService

export const useExecuteService: TUseExecuteService = () => {

    const apiClient = useApiClient() 

    const getExecutionByCuit : IExecuteService['getExecutionByCuit'] = async (cuit)=> {
        const response = await apiClient.get<Array<IExecuted>>(`${setup.riskApiUrl}/getCuitExecution/${cuit}`)
        return response
    }

    const getExecutionById : IExecuteService['getExecutionById'] = async (executionId) => {
        const response = await apiClient.get<IExecuted>(`${setup.riskApiUrl}/getExecution/${executionId}`)
        return response
    }

    return { getExecutionByCuit, getExecutionById }
}