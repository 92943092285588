import { TApiClientResponse, useApiClient } from "../../utils/apiClient.use.util"
import { setup } from "../../utils/setup"
import { ISource } from "./source.model.store"


interface ISourceServie {
    getSourceList: () => TApiClientResponse<Array<ISource>>
    getSource: (sourceId: number) => TApiClientResponse<ISource>
    createSource: (source: ISource) => TApiClientResponse<ISource>
    updateSource: (source: ISource) => TApiClientResponse<null>
    deleteSource: (sourceId: number) => TApiClientResponse<null>
    syncSource: (sourceId: number) => TApiClientResponse<null>
}

const useSourceService = (): ISourceServie => {

    const apiClient = useApiClient()
    const serviceGateWay = 'addedSource'
    const asyncServiceGateWar = 'syncSource'

    const getSourceList: ISourceServie['getSourceList'] = async () => {
        const response = await apiClient.get<Array<ISource>>(`${setup.riskApiUrl}/${serviceGateWay}/all`)
        return response
    }

    const getSource: ISourceServie['getSource'] = async ( sourceId ) => {
        const response = await apiClient.get<ISource>(`${setup.riskApiUrl}/${serviceGateWay}/${sourceId}`)
        return response
    }

    const createSource: ISourceServie['createSource'] = async (source) => {
        const response = await apiClient.post<ISource>(`${setup.riskApiUrl}/${serviceGateWay}`, source)
        return response
    }

    const updateSource: ISourceServie['updateSource'] = async (source) => {
        const response = await apiClient.put<null>(`${setup.riskApiUrl}/${serviceGateWay}/${source.id}`, source)
        return response
    }

    const deleteSource: ISourceServie['deleteSource'] = async (sourceId) => {
        const response = await apiClient.del<null>(`${setup.riskApiUrl}/${serviceGateWay}/${sourceId}`)
        return response
    }

    const syncSource: ISourceServie['syncSource'] = async (sourceId) => {
        const response = await apiClient.get<null>(`${setup.riskApiUrl}/${asyncServiceGateWar}/${sourceId}`)
        return response
    }

    return { getSourceList, getSource, createSource, updateSource, deleteSource, syncSource }
}


export { useSourceService }