import { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { FormGenerator, getValueToForm, TForm } from 'FormGenerator'
import { EaseInAnimation, Title } from '../elements/Element'
import { addPreloadDataToStageFormScheme } from '../helpers/Circuit.helper'
import { stageFormScheme } from '../schemes/circuit.form.scheme'
import { IAppStore } from '../stores/app/app.interface.store'
import { circuitFactory } from '../stores/circuit/circuit.helper.store'
import { ICircuitStoreObject } from '../stores/circuit/circuit.interface.store'

interface ICircuitCreateStagePageProps {
    circuitStore: ICircuitStoreObject
    appStore: IAppStore
}

export const CircuitCreateStagePage: FC<ICircuitCreateStagePageProps> = (props) => {

    const history = useHistory()
    const [formData, setFormData] = useState<TForm>([])
    const  params = useParams<{ circuitId: string , stageId: string}>()

    useEffect(() => { onInit() }, [])

    const onInit = async () => {

        if(params.stageId) {
            props.appStore.showLoader()
            const result = await props.circuitStore.getStage(Number(params.stageId))
            
            if(result.isSuccess) {
                setFormData(addPreloadDataToStageFormScheme(stageFormScheme, result.data))
            }
            
            props.appStore.hideLoader()

            if(result.isError) props.appStore.openApiErrorAlert()

        }else {
            setFormData([...stageFormScheme])
        }
    }


    const onSubmitFormHandler = async () => {

        const newStageName = getValueToForm(formData, '1')

        if(params.stageId) {
            props.appStore.showLoader()
            const result = await props.circuitStore.updateStage(Number(params.stageId), newStageName)
            props.appStore.hideLoader()
            if(result.isError) props.appStore.openApiErrorAlert()        
            if(result.isSuccess) history.push(`/circuit/${params.circuitId}/stage/add`)
        } else {
            props.appStore.showLoader()
            const result = await props.circuitStore.createStage(newStageName)
            if(result.isError) props.appStore.openApiErrorAlert()
            props.appStore.hideLoader()
            if(result.isSuccess) history.push(`/circuit/${params.circuitId}/stage/add`)
        }
    }
    
    return (
        <EaseInAnimation>
            <br/><br/>
            <Title>{params.circuitId ? `Modificar el stage #${params.circuitId}` : 'Crear nuevo stage' }</Title>
            <br/><br/>
            <FormGenerator
                dataTestId="createCircuit"
                form={formData}
                setForm={setFormData}
                onSubmit={onSubmitFormHandler}
                submitText="Guardar"
                isShowSubmit={true}
                actions={[]}
                onAction={()=>{}}
                onValidForm={()=>{}}
                onInvalidForm={()=>{}}
            />
        </EaseInAnimation>
    )
}