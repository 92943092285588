import { Background, getBezierPath, getEdgeCenter, getMarkerEnd, Handle } from "react-flow-renderer";
import { IBox } from "../../stores/trace/trace.model.store";

import binaryIcon from "../../assets/binary-file.png"
import calculatorIcon from "../../assets/calculatorNodeIcon.svg"
import advancedCalculatorIcon from "../../assets/advancedCalculatorNodeIcon.jpg"

import rootNodeIcon from "../../assets/rootNodeIcon.png"
import finishNodeIcon from "../../assets/finishNodeIcon.png"
import bureauNodeIcon from "../../assets/bureauNodeIcon.png"
import callRiskIcon from "../../assets//callRisk.svg"

import styled from 'styled-components'

const HandleAny: any = Handle

const customNodeStyles: React.CSSProperties = {
    background: 'white',
    color: 'black',
    fontSize: '15px',
    padding: 10,
    display: 'flex',
    flexDirection: "column",
    boxShadow: '1px 1px 3px grey',
    width: '50px',
    height: '60px'
}


const FinishNodeComponent = (props: IBox) => {

    const onRemoveHandler = () => props.data.onRemove(props.id)

    return (
        <div style={customNodeStyles}>

            <div style={{ display: 'flex', flexDirection: "row" }}>
                <div style={{ width: '50%' }}>{props.id}</div>
                <div style={{ width: '50%', display: 'flex', flexDirection: "row-reverse" }}>
                    <button style={{ background: '#111', color: 'white', fontWeight: 'bold', width: '15px', height: '15px', padding: '0px', borderRadius: '20px', border: 'none', cursor: 'pointer' }} onClick={onRemoveHandler}>×</button>
                </div>
            </div>


            <img style={{ width: '50px', height: '50px' }} draggable="false" src={finishNodeIcon} />
            <HandleAny
                type="target"
                style={{ background: 'blue' }}
            />
        </div>
    );
};



const RootNodeComponent = (props: IBox) => {
    return (
        <div style={customNodeStyles}>
            <div style={{ width: '100%' }}>{props.id}</div>
            <img style={{ width: '50px', height: '50px' }} draggable="false" src={rootNodeIcon} />
            <HandleAny
                type="source"
                id="a"
                style={{ top: '95%', left: '50%', background: 'blue' }}
            />
        </div>
    );
};


const BinaryNodeComponent = (props: IBox) => {

    const onRemoveHandler = () => props.data.onRemove(props.id)

    return (
        <div style={customNodeStyles}>
            <div style={{ display: 'flex', flexDirection: "row" }}>
                <div style={{ width: '50%' }}>{props.id}</div>
                <div style={{ width: '50%', display: 'flex', flexDirection: "row-reverse" }}>
                    <button style={{ background: '#111', color: 'white', fontWeight: 'bold', width: '15px', height: '15px', padding: '0px', borderRadius: '20px', border: 'none', cursor: 'pointer' }} onClick={onRemoveHandler}>×</button>
                </div>
            </div>
            <HandleAny
                type="target"
                style={{ background: 'blue' }}
            />
            <img style={{ width: '50px', height: '50px' }} draggable="false" src={binaryIcon} />
            <HandleAny
                type="source"
                id="a"
                style={{ top: '95%', left: '40%', background: 'lightgreen' }}
            />
            <HandleAny
                type="source"
                id="b"
                style={{ top: '95%', left: '60%', background: 'red' }}
            />
        </div>
    );
};


const CalculatorNodeComponent = (props: IBox) => {

    const onRemoveHandler = () => props.data.onRemove(props.id)

    return (
        <div style={customNodeStyles}>

            <div style={{ display: 'flex', flexDirection: "row" }}>
                <div style={{ width: '50%' }}>{props.id}</div>
                <div style={{ width: '50%', display: 'flex', flexDirection: "row-reverse" }}>
                    <button style={{ background: '#111', color: 'white', fontWeight: 'bold', width: '15px', height: '15px', padding: '0px', borderRadius: '20px', border: 'none', cursor: 'pointer' }} onClick={onRemoveHandler}>×</button>
                </div>
            </div>


            <HandleAny
                type="target"
                style={{ background: 'blue' }}
            />

            <img style={{ width: '50px', height: '50px' }} draggable="false" src={calculatorIcon} />

            <HandleAny
                type="source"
                id="a"
                style={{ top: '95%', left: '50%', background: 'blue' }}
            />
        </div>
    );
};






const CallRiskNodeComponent = (props: IBox) => {

    const onRemoveHandler = () => props.data.onRemove(props.id)

    return (
        <div style={customNodeStyles}>

            <div style={{ display: 'flex', flexDirection: "row" }}>
                <div style={{ width: '50%' }}>{props.id}</div>
                <div style={{ width: '50%', display: 'flex', flexDirection: "row-reverse" }}>
                    <button style={{ background: '#111', color: 'white', fontWeight: 'bold', width: '15px', height: '15px', padding: '0px', borderRadius: '20px', border: 'none', cursor: 'pointer' }} onClick={onRemoveHandler}>×</button>
                </div>
            </div>


            <HandleAny
                type="target"
                style={{ background: 'blue' }}
            />

            <img style={{ width: '50px', height: '50px' }} draggable="false" src={callRiskIcon} />

            <HandleAny
                type="source"
                id="a"
                style={{ top: '95%', left: '50%', background: 'blue' }}
            />
        </div>
    );
};



const AdvancedCalculatorNodeComponent = (props: IBox) => {

    const onRemoveHandler = () => props.data.onRemove(props.id)

    return (
        <div style={customNodeStyles}>

            <div style={{ display: 'flex', flexDirection: "row" }}>
                <div style={{ width: '50%' }}>{props.id}</div>
                <div style={{ width: '50%', display: 'flex', flexDirection: "row-reverse" }}>
                    <button style={{ background: '#111', color: 'white', fontWeight: 'bold', width: '15px', height: '15px', padding: '0px', borderRadius: '20px', border: 'none', cursor: 'pointer' }} onClick={onRemoveHandler}>×</button>
                </div>
            </div>


            <HandleAny
                type="target"
                style={{ background: 'blue' }}
            />

            <img style={{ width: '50px', height: '50px' }} draggable="false" src={advancedCalculatorIcon} />

            <HandleAny
                type="source"
                id="a"
                style={{ top: '95%', left: '50%', background: 'blue' }}
            />
        </div>
    );
};

const CallPolicyNode = (props: IBox) => {

    const onRemoveHandler = () => props.data.onRemove(props.id)


    return (
        <div style={customNodeStyles}>

            <div style={{ display: 'flex', flexDirection: "row" }}>
                <div style={{ width: '50%' }}>{props.id}</div>
                <div style={{ width: '50%', display: 'flex', flexDirection: "row-reverse" }}>
                    <button style={{ background: '#111', color: 'white', fontWeight: 'bold', width: '15px', height: '15px', padding: '0px', borderRadius: '20px', border: 'none', cursor: 'pointer' }} onClick={onRemoveHandler}>×</button>
                </div>
            </div>


            <HandleAny
                type="target"
                style={{ background: 'blue' }}
            />

            <HandleAny
                type="source"
                id="a"
                style={{ top: '95%', left: '50%', background: 'blue' }}
            />
        </div>
    );
};

const CallBureauNode = (props: IBox) => {
    const onRemoveHandler = () => props.data.onRemove(props.id)

    return (
        <div style={customNodeStyles}>

            <div style={{ display: 'flex', flexDirection: "row" }}>
                <div style={{ width: '50%' }}>{props.id}</div>
                <div style={{ width: '50%', display: 'flex', flexDirection: "row-reverse" }}>
                    <button style={{ background: '#111', color: 'white', fontWeight: 'bold', width: '15px', height: '15px', padding: '0px', borderRadius: '20px', border: 'none', cursor: 'pointer' }} onClick={onRemoveHandler}>×</button>
                </div>
            </div>


            <HandleAny
                type="target"
                style={{ background: 'blue' }}
            />
            <img style={{ width: '50px', height: '50px' }} draggable="false" src={bureauNodeIcon} />
            <HandleAny
                type="source"
                id="a"
                style={{ top: '95%', left: '50%', background: 'blue' }}
            />
        </div>
    );
};

export const nodeTypes = {
    root: RootNodeComponent,
    binary: BinaryNodeComponent,
    finish: FinishNodeComponent,
    calculator: CalculatorNodeComponent,
    advancedCalculator: AdvancedCalculatorNodeComponent,
    policy: CallRiskNodeComponent,
    bureau: CallBureauNode,
};

/////////////////////////////////////////////


const foreignObjectSize = 50;

const onEdgeClick = (evt: any, id: any) => {
    // console.log("evt", evt)
    // evt.stopPropagation();
    // alert(`remove ${id}`);
};

interface ICustomEdgeProps {
    id: any,
    sourceX: any,
    sourceY: any,
    targetX: any,
    targetY: any,
    sourcePosition: any,
    targetPosition: any,
    data: any,
    arrowHeadType: any,
    markerEndId: any
}

export default function CustomEdge(props: ICustomEdgeProps) {

    const edgePath = getBezierPath({
        sourceX: props.sourceX,
        sourceY: props.sourceY,
        sourcePosition: props.sourcePosition,
        targetX: props.targetX,
        targetY: props.targetY,
        targetPosition: props.targetPosition,
    });

    const markerEnd = getMarkerEnd(props.arrowHeadType, props.markerEndId);
    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
        sourceX: props.sourceX,
        sourceY: props.sourceY,
        targetX: props.targetX,
        targetY: props.targetY,
    });

    return (
        <>
            <path
                id={props.id}
                style={{}}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />
            <foreignObject
                width={foreignObjectSize}
                height={foreignObjectSize}
                x={edgeCenterX - 10}
                y={edgeCenterY - 10}
                className="edgebutton-foreignobject"
                requiredExtensions="http://www.w3.org/1999/xhtml"
            >
                <button
                    style={{ background: 'black', fontWeight: 'bold', color: 'white', border: 'none', borderRadius: '60px', margin: '1px', padding: '0.8px 4.5px', cursor: 'pointer' }}
                    className="edgebutton"
                    onClick={(event) => onEdgeClick(event, props.id)}
                >
                    ×
                </button>
            </foreignObject>
        </>
    );
}

export const edgeTypes = {
    line: CustomEdge,
}


export const TraceTitleContainer = styled.div`
    position: absolute;
    right: 10px;
    top: 170px;
    z-index: 800;
    background: white;
    box-shadow: 3px 3px 3px #ccc;
    box-sizing: border-box;
    width: 200px;
`
export const TraceTitleLabel = styled.label`
    width: 100%;
    box-sizing: border-box;

`

export const TraceTitleInput = styled.input`
    font-size: 17px;
    padding: 10px;
    border: none;
    box-sizing: border-box;
    width: 100%;
`