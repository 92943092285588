import { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Button, Card, Cell, EaseInAnimation, FloatPlusButton, Row, Title } from '../elements/Element'
import { IAppStore } from '../stores/app/app.interface.store'
import { circuitFactory, stageFactory } from '../stores/circuit/circuit.helper.store'
import { ICircuitStoreObject } from '../stores/circuit/circuit.interface.store'
import { ICircuitModel, IStageModel, IStatusModel } from '../stores/circuit/circuit.model.store'

interface ICircuitStageStatesPageProps {
    circuitStore: ICircuitStoreObject
    appStore: IAppStore
}

export const CircuitStageStatesPage: FC<ICircuitStageStatesPageProps> = props => {

    const history = useHistory()

    const { circuitId, stageId } = useParams<{ circuitId: string, stageId: string }>()
    const [currentStage, setCurrentStage] = useState<IStageModel>(stageFactory())

    useEffect(() => { onInit() }, [])

    const onInit = async () => {
        props.appStore.showLoader()
        const response = await props.circuitStore.getStage(Number(stageId))
        if (response.isError) props.appStore.openApiErrorAlert()
        if (response.isSuccess) setCurrentStage(response.data)
        props.appStore.hideLoader()
    }


    const onClickHandlerFromFloatPlusButton = () => {
        history.push(`/circuit/${circuitId}/stage/${stageId}/status/create`)
    }

    const onDeleteStateToStageHandler = async (statusId: number) => {
        props.appStore.showLoader()
        const result = await props.circuitStore.deleteStatus(Number(stageId), statusId)
        if (result.isError) props.appStore.openApiErrorAlert()
        if (result.isSuccess) {
            const getStageResult = await props.circuitStore.getStage(Number(stageId))
            if (getStageResult.isError) props.appStore.openApiErrorAlert()
            if (getStageResult.isSuccess) setCurrentStage(getStageResult.data)
        }
        props.appStore.hideLoader()
    }

    const onChangeOrderHandler = async (currentOrder: number, newOrder: number) => {
        props.appStore.showLoader()
        const newStatusList = currentStage.statuses.map(status => ({
            ...status,
            order: status.order === currentOrder ? newOrder :
                status.order === newOrder ? currentOrder :
                    status.order
        }))
        .sort((a, b) => a.order - b.order)


        const result = await props.circuitStore.changeStatusOrderToStage(Number(circuitId), Number(stageId), newStatusList)

        if (result.isError) props.appStore.openApiErrorAlert()
        if (result.isSuccess) {
            setCurrentStage(result.data)
            props.appStore.hideLoader()
        }
    }

    return (
        <EaseInAnimation>

            <br></br><br></br>
            <Title>Lista de Status asignados al stage #{stageId}</Title>
            <br></br>
            {currentStage.statuses.map((status, index) => (
                <div key={status.id}>
                    <Card>
                        <Row>
                            <Cell width={5} aling={'center'}>#{status.id}</Cell>
                            <Cell width={55} aling={'center'}>{status.name}</Cell>
                            <Cell width={40} aling={'right'}>
                                <Row>
                                    <Cell width={33.33} aling={'center'}><Button style={{ width: '100%' }} disabled={index === 0} onClick={() => onChangeOrderHandler(status.order, status.order - 1)}>▲</Button></Cell>
                                    <Cell width={33.33} aling={'center'}><Button style={{ width: '100%' }} disabled={index + 1 === currentStage.statuses.length} onClick={() => onChangeOrderHandler(status.order, status.order + 1)}>▼</Button></Cell>
                                    <Cell width={33.33} aling={'center'} onClick={() => onDeleteStateToStageHandler(status.id)}><Button style={{ width: '100%' }}>✕</Button></Cell>
                                </Row>
                            </Cell>
                        </Row>
                    </Card>
                    <br />
                </div>
            ))}

            <br></br><br></br>

            <FloatPlusButton data-testid="FloatPlusButton" onClick={onClickHandlerFromFloatPlusButton} />

        </EaseInAnimation>
    )
}