import React from 'react'
import { useStoreUtil } from "../../utils/useStore.util"
import { executedFactory } from "./execute.helper.store"
import { IExecuteStore, TUseExecuteStore } from "./execute.interface.store"
import { IExecuted } from './execute.model.store'
import { useExecuteService } from "./execute.service.store"

export const useExecuteStore: TUseExecuteStore = () => {
    const storeUtil = useStoreUtil()
    const executeService = useExecuteService()

    const [executedList, setExecutedList] = React.useState<IExecuteStore['executedList']>([])
    const [executed, setExecuted] = React.useState<IExecuteStore['executed']>(executedFactory())

    const getExecutionByCuit: IExecuteStore['getExecutionByCuit'] = async (cuit) => {
        setExecutedList([])
        const response = await executeService.getExecutionByCuit(cuit)
        if (response.isError) return storeUtil.createErrorResult([executedFactory()])
        setExecutedList(response.data)
        return storeUtil.createSuccessResult(response.data)
    }

    const getExecutionById: IExecuteStore['getExecutionById'] = async (executionId) => {
        setExecuted(executedFactory())
        const response = await executeService.getExecutionById(executionId)
        if(response.isError) return storeUtil.createErrorResult(executedFactory())
        setExecuted(response.data)
        return storeUtil.createSuccessResult(response.data)
    }
    
    return { getExecutionByCuit, getExecutionById, executedList, executed }
}