import React from 'react'
import { FormGenerator, getValueToForm, TForm } from 'FormGenerator'
import { Button, EaseInAnimation, Loader } from '../elements/Element'
import { IExecuteStore } from '../stores/execution/execute.interface.store'
import ReactJson from 'react-json-view'
import { IAppStore } from '../stores/app/app.interface.store'
import { useHistory } from 'react-router'

interface ISearchExecutionPageProps {
    executeStore: IExecuteStore
    appStore: IAppStore
}

export const SearchExecutionPage = (props: ISearchExecutionPageProps) => {

    const [isLoadingData, setIsLoading] = React.useState<boolean>(false)
    const history = useHistory()

    const [form, setForm] = React.useState<TForm>([
        {
            id: '1',
            type: 'cuilcuit',
            label: 'CUIT',
            value: '',
            placeholder: '',
            required: true,
            error: false,
            disabled: false,
            size: 12,
            hidden: false,
        },
        {
            id: '2',
            type: 'number',
            label: 'execution ID',
            value: '',
            maxValue: 99999999999,
            minValue: 0,
            placeholder: '',
            required: true,
            error: false,
            disabled: false,
            size: 12,
            hidden: false,
        },
    ])

    const cuitValue: string = getValueToForm(form, '1')
    const executionIdvalue: string = getValueToForm(form, '2')

    const onSubmitHandler = async () => {

        setIsLoading(true)

        const cuitValue: string = getValueToForm(form, '1')
        const executionIdvalue: string = getValueToForm(form, '2')

        if (cuitValue && executionIdvalue) {
            const [cuitResponse, executionIdResponse] = await Promise.all([
                props.executeStore.getExecutionByCuit(Number(cuitValue)),
                props.executeStore.getExecutionById(Number(executionIdvalue))
            ])
            if (cuitResponse.isError) props.appStore.openApiErrorAlert()
            if (executionIdResponse.isError) props.appStore.openApiErrorAlert()
            setIsLoading(false)
            return
        }

        if (cuitValue && !executionIdvalue) {
            const cuitResponse = await props.executeStore.getExecutionByCuit(Number(cuitValue))
            if (cuitResponse.isError) props.appStore.openApiErrorAlert()
            setIsLoading(false)
            return
        }

        if (!cuitValue && executionIdvalue) {
            const executionIdResponse = await props.executeStore.getExecutionById(Number(executionIdvalue))
            if (executionIdResponse.isError) props.appStore.openApiErrorAlert()
            setIsLoading(false)
            return
        }

        setIsLoading(false)
    }

    const onExecuteHandler = () => {
        if (props.executeStore.executed.execution_id) {
            history.push(`/risk/${props.executeStore.executed.risk_policy_id}/execute/${props.executeStore.executed.execution_id}`)
        }
    }

    const setFormHandler = (form: TForm) => {
        
        const newCuitValue: string = getValueToForm(form, '1')
        const newExecutionIdvalue: string = getValueToForm(form, '2')

        if(newCuitValue === '' && newExecutionIdvalue === '') {
            setForm(form.map(input=>({...input, required: true, error: false})))
            return
        } else {
            setForm(form.map(input=>({...input, required: false})))
            return 
        }
    }

    return (
        <EaseInAnimation>
            <Loader isOpen={isLoadingData} />

            <div style={{ padding: '3% 30%' }}>
                <FormGenerator
                    dataTestId="forSearchExecution"
                    form={form}
                    setForm={setFormHandler}
                    onSubmit={onSubmitHandler}
                    submitText="Buscar"
                    isShowSubmit={true}
                    actions={[]}
                    onAction={() => { }}
                    onValidForm={() => { }}
                    onInvalidForm={() => { }}
                />
            </div>

            <div style={{ padding: '3% 30%' }}>

                {props.executeStore.executedList.length ? (
                    <div>
                        <h4>Resultado del Cuit</h4>
                        {/* <p>{cuitResult}</p> */}
                        <ReactJson src={props.executeStore.executedList} collapsed={true} displayDataTypes={false} />
                    </div>
                ) : null}


                {props.executeStore.executed.execution_id ? (
                    <div>
                        <h4>Resultado del ExecuteId</h4>
                        <ReactJson src={props.executeStore.executed} collapsed={true} displayDataTypes={false} />
                    </div>
                ) : null}

                <br></br>

                {props.executeStore.executed.execution_id ? (
                    <Button onClick={onExecuteHandler}>Ejecutar</Button>
                ) : null}
            </div>
        </EaseInAnimation>
    )
}