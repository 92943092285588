import React from 'react'
import { useParams } from 'react-router'
import { FormGenerator, getValueToForm, TForm } from 'FormGenerator'
import { ITableGeneratorComponent, TableGeneratorComponent } from 'table-generator'
import { Button, EaseInAnimation, FloatPlusButton, FormWrapper, Loader, Modal, Title } from '../elements/Element'
import { IAppStore } from '../stores/app/app.interface.store'
import { IRiskStore } from '../stores/risk/risk.interface.store'

interface IRiskParamsPage {
    riskStore: IRiskStore
    appStore: IAppStore
}

const formSchema : TForm = [
    {
        id: '1',
        type: 'text',
        value: '',
        minLength: 1,
        maxLength: 100,
        required: true,
        label: 'Nombre',
        placeholder: 'Requerido',
        error: false,
        disabled: false,
        size: 12,
        regex: '',
        hidden: false,
    },
    {
        id: '2',
        type: 'options',
        label: "Tipo",
        value: '',
        placeholder: 'Requerido',
        required: true,
        options: [
            { value: 'number', label: 'Número' },
            { value: 'string', label: 'Texto' },
        ],
        error: false,
        disabled: false,
        size: 12,
        hidden: false,
    },
    {
        id: '4',
        type: 'text',
        value: '',
        minLength: 1,
        maxLength: 200,
        required: true,
        label: 'Label',
        placeholder: 'Requerido',
        error: false,
        disabled: false,
        size: 12,
        regex: '',
        hidden: false,
    },
    {
        id: '3',
        type: 'text',
        value: '',
        minLength: 1,
        maxLength: 200,
        required: true,
        label: 'Descripción',
        placeholder: 'Requerido',
        error: false,
        disabled: false,
        size: 12,
        regex: '',
        hidden: false,
    }
]

export const RiskParamsPage = ( props: IRiskParamsPage ) => {

    const { id } = useParams<{id:string}>()

    const [ isLoading, setIsLoading ] = React.useState<boolean>(false)
    const [ isOpenFormEdit, setIsOpenFormEdit ] = React.useState<boolean>(false)
    const [ isOpenFormCreate, setIsOpenFormCreate ] = React.useState<boolean>(false)
    const [ formDataForEdit, setFormDataForEdit ] = React.useState<TForm>([...formSchema])
    const [ formDataForCreate, setFormDataForCreate ] = React.useState<TForm>([...formSchema])
    const [ idParam, setIdParam ] = React.useState<number>(0)

    React.useEffect(()=>{
        onInit()
    }, [])

    const onInit = async () =>{
        setIsLoading(true)
        const result = await props.riskStore.getRisk(Number(id))
        if(result.isError) props.appStore.openApiErrorAlert()
        setIsLoading(false)
    }

    const onSubmitEditHandler = async () => {
        setIsLoading(true)
        const result = await props.riskStore.updateParam(Number(id), {
            id: idParam,
            label: getValueToForm(formDataForEdit, '4'),
            name: getValueToForm(formDataForEdit, '1'),
            type: getValueToForm(formDataForEdit, '2') === 'string' ? 'string' : 'number',
            description: getValueToForm(formDataForEdit, '3')
        })
        
        if(result.isError) props.appStore.openApiErrorAlert()

        setIsOpenFormEdit(false)
        setIsLoading(false)
    }
    
    const onSubmitCreateHandler = async ()=> {
        setIsLoading(true)
        const result = await props.riskStore.createParam(Number(id), {
            id: 0,
            label: getValueToForm(formDataForCreate, '4'),
            name: getValueToForm(formDataForCreate, '1'),
            type: getValueToForm(formDataForCreate, '2') === 'string' ? 'string' : 'number',
            description: getValueToForm(formDataForCreate, '3'),
        })

        if(result.isError) props.appStore.openApiErrorAlert()

        setIsOpenFormCreate(false)
        setIsLoading(false)
    }

    const onActionTableHandler = async (type: string, key: string) => {
        if(type === 'edit') {
            setIdParam(Number(key))
            const paramFinded = currentRiskSchema.find(param => param.id === Number(key))
            if(!paramFinded) return
            setFormDataForEdit(formDataForEdit.map(input=>{
                if(input.id === '1') return {...input, error: false, value: paramFinded.name}
                if(input.id === '2') return {...input, error: false, value: paramFinded.type}
                if(input.id === '3') return {...input, error: false, value: paramFinded.description}
                if(input.id === '4') return {...input, error: false, value: paramFinded.label}
                return input
            }))
            setIsOpenFormEdit(true)
        }
        if(type === 'delete') {
            setIsLoading(true)
            const result = await props.riskStore.deleteParam(Number(id), Number(key))
            if(result.isError) props.appStore.openApiErrorAlert()
            setIsLoading(false)
            return 
        }
    }

    const onClickAddParamHandler = () => {
        setFormDataForCreate(formDataForCreate.map(input=>({...input, value: '', error: false})))
        setIsOpenFormCreate(true)
    }

    const currentRisk = props.riskStore.riskList.find(risk=>risk.id === Number(id))  
    const currentRiskSchema = currentRisk ? currentRisk.schema : []
    const bodyRows : ITableGeneratorComponent['bodyList'] = currentRiskSchema.map(param => ([
        {
            type: 'text',
            text: String(param.id)
        },
        {
            type: 'text',
            text: param.name
        },
        {
            type: 'text',
            text: param.type
        },
        {
            type: 'text',
            text: param.label
        },
        {
            type: 'text',
            text: param.description
        },
        {
            type: 'actions',
            key: String(param.id),
            actions: [
                {
                    type: 'edit',
                    text: 'Modificar Parametro',
                    disabled: false
                },
                {
                    type: 'delete',
                    text: 'Eliminar Parametro',
                    disabled: false
                }
            ]
        },
    ]))

    return (
        <div>
            <Loader isOpen={isLoading}/>
            <Modal title="" level="low" size="M" isOpen={isOpenFormEdit} onClose={()=>setIsOpenFormEdit(false)}>
                <FormWrapper>
                    <Title>Modificar Parametro</Title>
                    <br></br><br></br>
                    <FormGenerator
                        dataTestId="forEditParam"
                        form={formDataForEdit}
                        setForm={setFormDataForEdit}
                        onSubmit={onSubmitEditHandler}
                        submitText="Guardar"
                        isShowSubmit={true}
                        actions={[]}
                        onAction={()=>{}}
                        onValidForm={()=>{}}
                        onInvalidForm={()=>{}}
                    />
                </FormWrapper>
            </Modal>
            <Modal title="" level="low" size="M" isOpen={isOpenFormCreate} onClose={()=>setIsOpenFormCreate(false)}>
                <FormWrapper>
                    <Title>Crear Parametro</Title>
                    <br></br><br></br>
                    <FormGenerator
                        dataTestId="forCreateParam"
                        form={formDataForCreate}
                        setForm={setFormDataForCreate}
                        onSubmit={onSubmitCreateHandler}
                        submitText="Crear"
                        isShowSubmit={true}
                        actions={[]}
                        onAction={()=>{}}
                        onValidForm={()=>{}}
                        onInvalidForm={()=>{}}
                    />
                </FormWrapper>
            </Modal>
            <EaseInAnimation>
                <br></br><br></br>
                <Title>Editar Parametros</Title>
                <TableGeneratorComponent
                    headerList={['Id', 'Nombre', 'Tipo', 'Label', 'Descripción', 'Acciones']}
                    bodyList={bodyRows}
                    onAction={onActionTableHandler}
                    tools={[]}
                    paginationList={[]}
                />
                <FloatPlusButton onClick={onClickAddParamHandler}/>
            </EaseInAnimation>
        </div>
    )
}