import { TForm } from "FormGenerator";

export const traceFormScheme: TForm = [
    {
        id: '1',
        type: 'text',
        label: 'Nombre:',
        value: '',
        placeholder: 'Campo Requerido...',
        required: true,
        error: false,
        minLength: 0,
        maxLength: 100,
        disabled: false,
        size: 12,
        regex: '',
        hidden: false,
    }
]