import React from 'react'
import styled from 'styled-components'

interface IRowProps {
    horizontalAlign: 'left' | 'center' | 'right'
    verticalAlign: 'up' | 'center' | 'down'
}

export const Row = styled.div<IRowProps>`
    align-items: ${props=> props.verticalAlign === 'up' ? 'flex-start' : props.verticalAlign === 'down' ? 'flex-end' : 'center'};
    justify-content: ${props => props.horizontalAlign === "left" ? "start" : props.horizontalAlign === "right" ? "end" : "center"};
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
`

interface IColProps {
    size: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    horizontalAlign: 'left' | 'center' | 'right'
    verticalAlign: 'up' | 'center' | 'down'
}

export const Col = styled.span<IColProps>`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px;
    margin: 0px;
    justify-content: ${props => props.verticalAlign === "up" ? "start" : props.verticalAlign === "down" ? "end" : "center"};
    align-items: ${props=> props.horizontalAlign === 'left' ? 'flex-start' : props.horizontalAlign === 'right' ? 'flex-end' : 'center'};

    @media (min-width: 1024px) {
        width: ${props=> 100 * (props.size/12)}%;
    }
`