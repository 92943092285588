import React from 'react'
import { useStoreUtil } from '../../utils/useStore.util'
import { IAuthStore } from './auth.interface.store'
import { useAuthService } from './auth.service.store'

export const useAuthStore = () : IAuthStore => {
    const authUtil = useStoreUtil()
    const [isAuthenticated, setIsAuthenticated] = React.useState<IAuthStore['isAuthenticated']>(false)
    const [isAuthenticating, setIsAuthenticating] = React.useState<IAuthStore['isAuthenticating']>(false)
    const [isFinishedAuthenticate, setIsFinishedAuthenticate] = React.useState<IAuthStore['isFinishedAuthenticate']>(false)
    const authService = useAuthService()

    const authenticate: IAuthStore['authenticate'] = async () => {
        setIsAuthenticating(true)
        const response = await authService.authenticate()
        if(response.isError) {
            return authUtil.createErrorResult(false)
        }
        setIsAuthenticating(false)
        setIsFinishedAuthenticate(true)
        setIsAuthenticated(response.data)
        return authUtil.createSuccessResult(response.data)
    }

    return {
        isAuthenticating,
        isAuthenticated,
        isFinishedAuthenticate,
        authenticate
    }
} 


