import { FC, useState } from "react"
import { TableGeneratorComponent, TItem } from 'table-generator'
import { IBureauStore } from "../stores/bureau/bureau.interface.store"
import { IAppStore } from "../stores/app/app.interface.store"
import Calendar from "react-calendar"
import { Row, Col } from '../elements/Grid.element'
import { EaseInAnimation, Modal, ModalContent, PrimaryButton } from "../elements/Element"
import { FormGenerator, getValueToForm, TForm } from "FormGenerator"
import { searchBureauRequestsSheme } from "../schemes/searchBureauRequest.scheme"
import { dateFormaterUtil } from "../utils/date.util"
import { useHistory } from "react-router"
import { useFile } from "../utils/useFile.util"
import { IBureauPFRequestReport, IBureauPMRequestReport } from "../stores/bureau/bureau.model.store"
import ReactJson from "react-json-view"
import { timer } from "../utils/timer"

interface IBureauHistoryPageProps {
    bureauStore: IBureauStore
    appStore: IAppStore
}

const BureauHistoryPage: FC<IBureauHistoryPageProps> = props => {

    const file = useFile()

    const history = useHistory()

    const [minDateValue, setMinDateValue] = useState<Date>(new Date());
    const [maxDateValue, setMaxDateValue] = useState<Date>(new Date());

    const [formData, setFormData] = useState<TForm>([...searchBureauRequestsSheme])

    const [isOpenBureauRequestDetail, setIsOpenBureauRequestDetail] = useState<boolean>(false)

    const [currentReport, setCurrentReport] = useState<IBureauPFRequestReport | IBureauPMRequestReport | null>(null)


    const tableBody: TItem[][] = props.bureauStore.bureauRequestReportList.map(report => [
        {
            type: 'text',
            text: report._id
        },
        {
            type: 'text',
            text: `${dateFormaterUtil(report.CREATED_AT)}`
        },
        {
            type: 'text',
            text: report.kind_person
        },
        {
            type: 'text',
            text:
                report.kind_person === 'PF' ? `${report.Nombre.PrimerNombre} ${report.Nombre.ApellidoPaterno}` :
                    report.kind_person === 'PM' ? `${report.datosGenerales.nombre}` :
                        report.kind_person === 'PFAE' ? `${report.datosGenerales.nombre} ${report.datosGenerales.apellidoPaterno}`
                            : ''
        },
        {
            type: 'text',
            text:
                report.kind_person === 'PF' ? `${report.Nombre.RFC}` :
                    report.kind_person === 'PM' ? `${report.datosGenerales.rfcCliente}` :
                        report.kind_person === 'PFAE' ? `${report.datosGenerales.rfcCliente}`
                            : ''
        },
        {
            type: 'actions',
            key: report._id,
            actions: [{
                type: 'detail',
                text: 'Ver',
                disabled: false,
            }]
        }
    ])


    const onChangeMinValue = (value: Date) => {
        setMinDateValue(value)
    }

    const onChangeMaxValue = (value: Date) => {
        setMaxDateValue(value)
    }

    const onActionHandler = async (type: string, key: string) => {
        setCurrentReport(null)

        const bureauRequestFinded = props.bureauStore.bureauRequestReportList.find(request => request._id === key)
        if (bureauRequestFinded === undefined) {
            setIsOpenBureauRequestDetail(false)
            return
        }

        setCurrentReport(bureauRequestFinded)
        setIsOpenBureauRequestDetail(true)

    }

    const onSubmit = async () => {

        const newMinDate = new Date(minDateValue)
        newMinDate.setHours(0)
        newMinDate.setMinutes(0)
        newMinDate.setSeconds(0)
        newMinDate.setMilliseconds(0)

        const newMaxDate = new Date(maxDateValue)
        newMaxDate.setHours(23)
        newMaxDate.setMinutes(59)
        newMaxDate.setSeconds(59)
        newMaxDate.setMilliseconds(999)

        props.appStore.showLoader()
        const result = await props.bureauStore.getBureauRequestList({
            startDate: newMinDate.toISOString(),
            endDate: newMaxDate.toISOString(),
            fullName: getValueToForm(formData, 'fullName'),
            rfc: getValueToForm(formData, 'rfc')
        })
        props.appStore.hideLoader()

        if (result.isError) props.appStore.openApiErrorAlert()
    }



    const onDownloadJsonFileHandler = async () => {
        file.saveData(currentReport, `${currentReport?._id}.json`)
    }


    const onDownloadPDFFileHandler = async () => {
        if (currentReport === null) return

        props.appStore.showLoader()
        const result = await props.bureauStore.downloadBureauRequestDetail(currentReport._id)
        props.appStore.hideLoader()

        if (result.isError) return props.appStore.openApiErrorAlert()

        file.downloadFile(new Blob([result.data]), `${currentReport._id}.pdf`)
    }

    return (
        <EaseInAnimation>
            <Modal title={`Solicitud Bureau #${currentReport?._id}`} level="low" size="M" isOpen={isOpenBureauRequestDetail} onClose={() => setIsOpenBureauRequestDetail(false)}>
                <ModalContent>
                    {currentReport ? (
                        <>
                            <Row horizontalAlign='center' verticalAlign='center'>
                                <br />
                            </Row>
                            <Row horizontalAlign='center' verticalAlign='center'>
                                <ReactJson src={currentReport} collapsed={true} displayDataTypes={false} />
                            </Row>
                            <Row horizontalAlign='center' verticalAlign='center'>
                                <br /><br />
                            </Row>
                        </>
                    ) : null}

                    <Row horizontalAlign='center' verticalAlign='center'>
                        <PrimaryButton disabled={false} style={{ margin: '10px' }} onClick={() => onDownloadJsonFileHandler()}>Descargar JSON</PrimaryButton>
                        <PrimaryButton disabled={false} style={{ margin: '10px' }} onClick={() => onDownloadPDFFileHandler()}>Descargar PDF</PrimaryButton>
                    </Row>
                    <Row horizontalAlign='center' verticalAlign='center'>
                       <br/>
                    </Row>
                </ModalContent>
            </Modal>

            <Row horizontalAlign='center' verticalAlign='up'>
                <Col size={6} horizontalAlign='center' verticalAlign='center'>
                    <br />
                    <label>Fecha de inicio</label>
                    <br />
                    <Calendar onChange={onChangeMinValue} value={minDateValue} maxDate={maxDateValue} />
                </Col>
                <Col size={6} horizontalAlign='center' verticalAlign='center'>
                    <br />
                    <label>Fecha de finalizacion</label>
                    <br />
                    <Calendar onChange={onChangeMaxValue} value={maxDateValue} minDate={minDateValue} maxDate={new Date()} />
                </Col>
            </Row>

            <Row horizontalAlign='center' verticalAlign='center'>
                <br />
            </Row>

            <Row horizontalAlign='center' verticalAlign='up'>
                <Col size={2} horizontalAlign='center' verticalAlign='center' />
                <Col size={8} horizontalAlign='center' verticalAlign='center'>

                    <FormGenerator
                        dataTestId="forEditRisk"
                        form={formData}
                        setForm={setFormData}
                        onSubmit={() => { }}
                        submitText=""
                        isShowSubmit={false}
                        actions={[]}
                        onAction={() => { }}
                        onValidForm={() => { }}
                        onInvalidForm={() => { }}
                    />
                </Col>
                <Col size={2} horizontalAlign='center' verticalAlign='center' />
            </Row>

            <Row horizontalAlign='center' verticalAlign='center'>
                <Col size={12} horizontalAlign='center' verticalAlign='center'>
                    <PrimaryButton disabled={false} onClick={() => onSubmit()}>Buscar</PrimaryButton>
                </Col>
            </Row>

            {props.bureauStore.bureauRequestReportList.length ? (
                <TableGeneratorComponent
                    headerList={['Id', "Fecha", "Tipo", "Nombre", "RFC", '']}
                    bodyList={tableBody}
                    paginationList={[20, 40, 80]}
                    tools={[]}
                    onAction={(key, type) => onActionHandler(key, type)}
                />
            ) : null}

            <Row horizontalAlign='center' verticalAlign='center'>
                <br /><br />
            </Row>

        </EaseInAnimation>
    )
}

export { BureauHistoryPage }