import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { timer } from '../utils/timer'

export const CenterText = styled.div`
    text-align: center;
    padding: 5px;
`

export const All = styled.div`
    display: flex;
    flex-direction: column;
    font-family: Arial;
    min-height: 100vh;
 
    box-sizing: border-box;
    
    color: #334;
    background:#eee;
    @media (prefers-color-scheme: dark) {
        background:black;
        color:white;
    }
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
`

export const EnterAnimation =  styled.div<{isOpen: boolean}>`
    display: flex;
    flex-direction: column;
    opacity: ${props=>props.isOpen ? 1 : 0};
    transition: .3s all;
`

export const Button = styled.button`
    border: none;
    background: #ddd;
    padding:10px;
    box-shadow: 1px 1px 2px grey;
    font-size: 13px;
    text-transform: uppercase;
    cursor: pointer;
    min-width: 30px;
    border-radius: 3px;
`

export const DanggerButton = styled.button`
    border: none;
    background: #d33;
    color: white;
    padding:10px;
    box-shadow: 1px 1px 2px grey;
    font-size: 13px;
    text-transform: uppercase;
    cursor: pointer;
    min-width: 30px;
    border-radius: 3px;
`

export const PrimaryButton = styled.button<{disabled:boolean}>`
    border: none;
    background: rgb(56, 128, 255);
    color: white;
    padding:10px;
    box-shadow: 1px 1px 2px grey;
    font-size: 13px;
    text-transform: uppercase;
    cursor: pointer;
    min-width: 30px;
    border-radius: 3px;
    opacity: ${props=> props.disabled ? 0.5 : 1};
    cursor: ${props=> props.disabled ? 'default' : 'cursor'};
`

///////////////////

export const NavigationDropDownItem = styled.div`
    display: flex;
    flex-direction: column;
    text-decoration: none;
    margin: 0px;
    &:hover{
        

    }
    &:hover > ul {
        display: flex;
    }
    &>a {
        padding: 10px;
    }
`

export const NavigationItemLink = (props: {url: string, text: string})=> {
    return <Link to={props.url} style={{ padding: '15px', textDecoration: 'none', color: 'white' }}>{props.text}</Link>
}

export const NavigationDropDownLabel = styled.label`
    padding: 15px;
    color: white;
`

export const NavigationDropDownList = styled.ul`
    display: none;
    flex-direction: column;
    position: absolute;
    background:#eee;
    box-shadow: 0px 3px 3px #555;
    background: #497d7d;
    top: 48px;
    padding: 0px;
    margin: 0px;
    z-index: 999;
`

////////////////////

export const Navigation = styled.nav`
    width: 100%;
    display: flex;
    flex-direction: row;
    background: #497d7d;
    box-shadow: 0px 0px 5px grey;
    padding: 0px;
`

export const NavigationList = styled.ul`
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    list-style: none;
    padding: 0px;
    margin: 0px;
`

export const NavigationItem = styled.li`
    display: flex;
    flex-direction: row;
    text-decoration: none;
    margin-right: 10px;
    margin: 0px;
` 

export const NavigationItemRedirect = styled.label`
`

export const NavigationHref = (props: {href: string, text: string, onClick?: ()=> void})=> {
    return <a href={props.href} style={{ padding: '15px', textDecoration: 'none', color: 'white' }}>{props.text}</a>
}



///////////////////

export const Slide = styled.div`
    
`

export const SlideList = styled.ul`

`

export const SlideItem = styled.li`

`

export const SlideLink = styled.li`

`

/////////////////////

const BackDropLoaderShadow = styled.div<{isOpen: boolean}>`
    display: flex;
    opacity: ${props => props.isOpen ? '1' : '0'};
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0,0,0,0.3);
    height: 100vh;
    width: 100%;
    margin: 0px;
    top: 0;
    left: 0;
    z-index: 998;
    transition: opacity .2s ease-in;
`

export const LoaderSpinner = styled.div`
    
    border: 16px solid #ccc;
    border-radius: 50%;
    border-top: 16px solid grey;
    width: 60px;
    height: 60px;

    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
    

    /* Safari */
    @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    }

`



export const Loader = (props: {isOpen: boolean})=> {

    const [isRendered, setIsRendered] = React.useState<boolean>(false)
    const [isOpenAnimation, setIsOpenAnimation] = React.useState<boolean>(false)
 
    React.useEffect(()=>{
        if(props.isOpen !== isRendered) changeAnimation(props.isOpen)
    }, [props.isOpen])

    const changeAnimation = async (isOpen: boolean) => {
        if(props.isOpen){
            setIsRendered(true)
            await timer(50)
            setIsOpenAnimation(true)
        }else{
            setIsOpenAnimation(false)
            await timer(300)
            setIsRendered(false)
        }
    }

    return (
        <div>
            {isRendered ? (
                <BackDropLoaderShadow isOpen={isOpenAnimation}>
                    <LoaderSpinner/>
                </BackDropLoaderShadow>
            ) : null}
        </div>       
    )
}

///////////////////////////////////



//////////////////////////////////


const BackDropModalShadow = styled.div<{isOpen: boolean, level: 'high' | 'medium' | 'low'}>`
    display: flex;
    opacity: ${props => props.isOpen ? '1' : '0'};
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0,0,0,0.3);
    height: 100vh;
    width: 100%;
    margin: 0px;
    top: 0;
    left: 0;
    z-index: ${props=> props.level === 'high' ? '900' : props.level === 'medium' ? '800' : '700'};
    transition: opacity .2s ease-in;
`

export const ModalWrapper = styled.div<{size: 'S' | 'M' | 'L'}>`

    width: ${props=>props.size === 'M' ? '70%' : props.size === 'S' ? '35%' : '90%' };
    background: #eee;
    box-shadow: 1px 1px 5px grey;
    max-height: 90%;
`

export const ModalHeader = styled.div`
    display: flex;
    flex-direction: row-reverse;
    padding:0px;
    margin:0px;
`

export const ModalContent = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 80vh;
    width: 100%;
    margin: 0%;
`

export const ModalCloseButton = styled.button`
    background: none;
    border: none;
    padding: 10px;
    font-size: 25px;
    color:#bbb;
    cursor: pointer;
    font-weight: lighter;
    &:before {
        content: "✖";
    }
    
`

export const Modal = (props: {
    size: 'S' | 'M' | 'L', 
    title: string, 
    isOpen: boolean, 
    level: 'high' | 'medium' | 'low',
    children: React.ReactNode, 
    onClose: () => void
})=> {

    const [isRendered, setIsRendered] = React.useState<boolean>(false)
    const [isOpenAnimation, setIsOpenAnimation] = React.useState<boolean>(false)
 
    React.useEffect(()=>{
        if(props.isOpen !== isRendered) changeAnimation(props.isOpen)
    }, [props.isOpen])

    const changeAnimation = async (isOpen: boolean) => {
        if(props.isOpen){
            setIsRendered(true)
            await timer(100)
            setIsOpenAnimation(true)
        }else{
            setIsOpenAnimation(false)
            await timer(600)
            setIsRendered(false)
        }

    }

    const onClickHandler = () => {
        props.onClose()
    }

    return (
        <div>
            {isRendered ? (
                <BackDropModalShadow isOpen={isOpenAnimation} level={props.level}>
                    <ModalWrapper size={props.size}>
                        <ModalHeader>
                            <ModalCloseButton onClick={onClickHandler}></ModalCloseButton> 
                            <h1 style={{color: '#333', marginLeft: '20px', textAlign: 'center',width: '100%', fontSize: '23px'}}>{props.title}</h1>
                        </ModalHeader>
                        <ModalContent>
                            {props.children}
                        </ModalContent>
                    </ModalWrapper>                
                </BackDropModalShadow>
            ) : null}
        </div>       
    )
}

///////////////////////

export const FormWrapper = styled.div`
    padding: 0% 5% 5% 5%;
`

export const Title = styled.h1`
    font-size: 25px;
    text-align: center;
    color: #445;
    padding: 0px;
    margin: 0px;
`

export const Description=  styled.h1`
    font-size: 20px;
    text-align: center;
    color: #445;
    padding: 0px;
    margin: 0px;
`

export const Href = styled.a`
  text-decoration: none;
`

export const Text = styled.p`

`
//////////////////////

const EaseIn = styled.div<{isRendered: boolean}>`
    opacity: ${props => props.isRendered ? '1' : '0'};
    transition: opacity .2s ease-in;
`
export const EaseInAnimation = (props: { children: React.ReactNode }) => {
    const [isRendered, setIsRendered] = React.useState(false)

    React.useEffect(()=>{
        setIsRendered(true)
    }, [])

    return (
        <EaseIn isRendered={isRendered}>
            {props.children}
        </EaseIn>
    )
}


///////////////////////


export const FloatSaveButton = styled.button`

    display: flex;
    justify-content: center;
    align-items: center;

    background: #497d7d;
    position: fixed;
    bottom: 100px;
    right: 20px;
    border: none;
    width: 55px;
    height: 55px;
    
    font-weight: bold;
    font-size: 30px;
    color: white;
    cursor: pointer;
    font-weight: lighter;
    border-radius: 100px;
    box-shadow: 1px 1px 5px #555;
    z-index: 800;

    transform: rotate(180deg);

    &:hover{
        background: #5a8d7d;   
    }
    &:before {
        content: "🖫";
    }
`

export const FloatPlusButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #497d7d;
    position: fixed;
    bottom: 20px;
    right: 20px;
    border: none;
    font-size: 30px;
    color: white;
    cursor: pointer;
    font-weight: lighter;
    border-radius: 100px;
    box-shadow: 1px 1px 5px #555;
    z-index: 800;
    
    width: 50px;
    height: 50px;

    
    &:hover{
        background: #5a8d7d;   
    }
    &:before {
        content: "+";
    }
`


export const FloatBackButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #497d7d;
    position: fixed;
    bottom: 180px;
    right: 20px;
    border: none;
    font-size: 30px;
    color: white;
    cursor: pointer;
    font-weight: lighter;
    border-radius: 100px;
    box-shadow: 1px 1px 5px #555;
    z-index: 800;
    
    width: 50px;
    height: 50px;

    
    &:hover{
        background: #5a8d7d;   
    }
    &:before {
        content: "↺";
    }
`


export const FloatTitle = styled.h1`
    position: fixed;
    font-size: 20px;
    top: 50px;
`


export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
`

export const Cell = styled.span<{width: number, aling: 'right' | 'center' | 'left'}>`
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    width: ${props=>props.width}%;
    justify-content: ${props=> props.aling === 'left' ? 'start' : props.aling === 'center' ? 'center' : 'end'};
    align-items: ${props=> props.aling === 'left' ? 'start' : props.aling === 'center' ? 'center' : 'end'};
`

///////////////////

export const Card = styled.div`
    width: 100%;
    box-shadow: 3px 3px 5px grey;
`