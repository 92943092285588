import { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { FormGenerator, getValueToForm, TForm } from 'FormGenerator'
import { EaseInAnimation, Title } from '../elements/Element'
import { addPreloadDataToCircuitFormScheme } from '../helpers/Circuit.helper'
import { circuitFormScheme } from '../schemes/circuit.form.scheme'
import { IAppStore } from '../stores/app/app.interface.store'
import { ICircuitStoreObject } from '../stores/circuit/circuit.interface.store'

interface ICircuitCreatePageProps {
    circuitStore: ICircuitStoreObject
    appStore: IAppStore
}

export const CircuitCreatePage: FC<ICircuitCreatePageProps> = (props) => {

    const history = useHistory()
    const [formData, setFormData] = useState<TForm>([])
    const params = useParams<{circuitId: string}>()

    useEffect(() => { onInit() }, [])

    const onInit = async () => {
        if(params.circuitId) {
            props.appStore.showLoader()
            const result = await props.circuitStore.getCircuit(Number(params.circuitId))
            
            if(result.isSuccess) {
                setFormData(addPreloadDataToCircuitFormScheme(circuitFormScheme, result.data))
            }
            
            props.appStore.hideLoader()

            if(result.isError) props.appStore.openApiErrorAlert()

        }else {
            setFormData([...circuitFormScheme])
        }
    }


    const onSubmitFormHandler = async () => {
        const newCircuitName = getValueToForm(formData, '1')
        if(params.circuitId) {
            props.appStore.showLoader()
            const result = await props.circuitStore.updateCircuit(Number(params.circuitId), newCircuitName)
            props.appStore.hideLoader()
            if(result.isError) props.appStore.openApiErrorAlert()
        }else {
            props.appStore.showLoader()
            const result = await props.circuitStore.createCircuit(newCircuitName)
            if(result.isError) props.appStore.openApiErrorAlert()
            props.appStore.hideLoader()
            if(result.isSuccess) history.push(`/circuit/${result.data.id}/stage`)
        }
    }



    return (
        <EaseInAnimation>
            <br/><br/>
            <Title>{params.circuitId ? `Modificar el curcuito #${params.circuitId}` : 'Crear nuevo Circuito' }</Title>
            <br/><br/>
            <FormGenerator
                dataTestId="createCircuit"
                form={formData}
                setForm={setFormData}
                onSubmit={onSubmitFormHandler}
                submitText="Guardar"
                isShowSubmit={true}
                actions={[]}
                onAction={()=>{}}
                onValidForm={()=>{}}
                onInvalidForm={()=>{}}
            />
        </EaseInAnimation>
    )
}