import { TApiClientResponse, useApiClient } from "../../utils/apiClient.use.util"
import { setup } from "../../utils/setup"
import { ITrace, TDiagram, TNode } from "./trace.model.store"

interface ITraceService {
    createTrace: (riskId: number, traceName: string) => TApiClientResponse<ITrace>
    copyTraceForOtherRisk: (trace: ITrace, riskId: number) => TApiClientResponse<ITrace>
    copyTrace: (trace: ITrace) => TApiClientResponse<ITrace>
    getTraceList: () => TApiClientResponse<Array<ITrace>>
    getTraceListByRiskId: (riskId: number) => TApiClientResponse<Array<ITrace>>
    deleteTrace: (id: number) => TApiClientResponse<void>
    getTrace: (id: number) => TApiClientResponse<ITrace>
    updateTrace: (traceUpdated: ITrace) => TApiClientResponse<void>
}

interface ICreateTraceRequest {
    diagram: TDiagram
    title: string
    is_active: boolean
    risk_policy_id: number
    trace: Array<TNode>
}

export const useTraceService = (): ITraceService => {

    const apiClient = useApiClient()

    const copyTrace: ITraceService['copyTrace'] = async (trace) => {
        const newTrace: ICreateTraceRequest = {
            is_active: false,
            title: trace.title,
            risk_policy_id: trace.risk_policy_id,
            diagram: trace.diagram,
            trace: trace.trace
        }
        const response = await apiClient.post<ITrace>(`${setup.riskApiUrl}/trace`, newTrace)
        return response
    }

    const copyTraceForOtherRisk: ITraceService['copyTraceForOtherRisk'] =
        async (trace, riskId) => {
            const newTrace: ICreateTraceRequest = {
                is_active: trace.is_active,
                title: trace.title,
                risk_policy_id: riskId,
                diagram: trace.diagram,
                trace: trace.trace
            }
            const response = await apiClient.post<ITrace>(`${setup.riskApiUrl}/trace`, newTrace)
            return response
        }

    const createTrace: ITraceService['createTrace'] = async (riskId, traceName) => {

        const newTrace: ICreateTraceRequest = {
            is_active: false,
            risk_policy_id: riskId,
            title: traceName,
            diagram: [{
                id: '1',
                type: 'root',
                data: {
                    label: '',
                    onRemove: (id) => { }
                },
                position: {
                    x: 0,
                    y: 0
                }
            }],
            trace: [{
                id: 1,
                nodeType: 0,
                properties: {},
                slots: []
            }]
        }

        const response = await apiClient.post<ITrace>(`${setup.riskApiUrl}/trace`, newTrace)

        return response
    }


    const getTraceList: ITraceService['getTraceList'] = async () => {
        const response = await apiClient.get<Array<ITrace>>(`${setup.riskApiUrl}/trace/all`)
        return response
    }

    const getTraceListByRiskId: ITraceService['getTraceListByRiskId'] = async (riskId) => {
        const response = await apiClient.get<Array<ITrace>>(`${setup.riskApiUrl}/getRiskPolicyTraces/${riskId}`)
        return response
    }

    const deleteTrace: ITraceService['deleteTrace'] = async (id) => {
        const response = await apiClient.del<void>(`${setup.riskApiUrl}/trace/${id}`)
        return response
    }

    const getTrace: ITraceService['getTrace'] = async (id) => {
        const response = await apiClient.get<ITrace>(`${setup.riskApiUrl}/trace/${id}`)
        return response
    }

    const updateTrace: ITraceService['updateTrace'] = async (traceUpdated) => {
        const response = await apiClient.put<void>(`${setup.riskApiUrl}/trace/${traceUpdated.id}`, traceUpdated)
        return response
    }

    return { createTrace, copyTraceForOtherRisk, copyTrace, getTraceList, deleteTrace, getTrace, updateTrace, getTraceListByRiskId }
}