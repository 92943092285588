import { IFormSection, createFieldSheme, createListField, IField } from "FormCreator"
import { getValueToForm } from "FormGenerator"
import { IFieldGroup, IJsonField, INumberField, IOptionField, IStringField } from "../stores/risk/risk.model.store"



export const translateFieldGroupListToSectionList = (fieldGroupList: Array<IFieldGroup>): Array<IFormSection> => {

    const createFieldFormSheme = (field: INumberField | IStringField | IOptionField | IJsonField): IField => {
        const preloadScheme = createFieldSheme().map(input => {
            if (input.id === '1') return {
                ...input,
                value: 
                    field.type === 'string' ? 'text' :
                    field.type === 'number' ? 'number' :
                    field.type === 'option' ? 'options' :
                    field.type === 'json' ? 'json' : 
                    'text'
            }
            if (input.id === '2') return { ...input, value: field.label }
            if (input.id === '3') return { ...input, value: field.name }
            if (input.id === '4') return { ...input, value: field.description }
            if (input.id === '6') return { ...input, value: field.required ? 'true' : 'false' }
            if (input.id === '7') return { ...input, value: field.onboarding ? 'true' : 'false' }

            return input
        })
    
        if (field.type === 'option') preloadScheme.push({
            ...createListField(),
            list: field.list.map(listItem=>listItem.value)
        })
    
        const newField: IField = {
            id: field.id,
            form: preloadScheme
        }
    
        return newField
    }


    return fieldGroupList.map(fieldGroup => ({
        id: fieldGroup.id,
        name: fieldGroup.name,
        description: fieldGroup.description,
        nextStep: fieldGroup.next_step,
        fields: fieldGroup.fields.map(field => createFieldFormSheme(field))
    }))
}

export const translateSectionListToFieldGroupList = (sectionList: Array<IFormSection>): Array<IFieldGroup> => {
    
    const getNextSectionId=(sectionList: Array<IFormSection>, currentIndex: number): number => {
        const nextSection = sectionList[currentIndex+1]
        if(nextSection) return nextSection.id
        else return 0
    }

    const getGroupFieldFromSectionField = (field: IField): INumberField | IStringField | IOptionField | IJsonField => {
 
        const type = getValueToForm(field.form, '1')    
        const label = getValueToForm(field.form, '2')
        const name = getValueToForm(field.form, '3')
        const description = getValueToForm(field.form, '4')
        const required = getValueToForm(field.form, '6') === 'true' ? true : false
        const visible = getValueToForm(field.form, '7') === 'true' ? true : false
        
        if(type === 'text') return {
            id: field.id,
            type: "string", 
            description: description,
            name: name,
            label: label,
            required: required,
            onboarding: visible
        }
        if(type === 'number') return {
            id: field.id,
            type: "number",
            description: description,
            name: name,
            label: label,
            required: required,
            onboarding: visible
        }
        if(type === 'json') return {
            id: field.id,
            type: "json",
            description: description,
            name: name,
            label: label,
            required: required,
            onboarding: false
        }
        return {
            id: field.id,
            type: "option",
            description: description,
            name: name,
            label: label,
            required: required,
            onboarding: visible,
            list:  getValueToForm(field.form, '5').split(',').map(item=>({
                label: item,
                value: item,
                next_step: 0
            }))
        }
    }

    return sectionList.map((section, index)=>({
        id: section.id,
        description: section.description,
        name: section.name,
        type: "normal",
        hidden: false,
        next_step: getNextSectionId(sectionList,index),
        fields: section.fields.map(field=>getGroupFieldFromSectionField(field))
    }))
}