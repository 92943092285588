import React from 'react'
import { useStoreUtil } from '../../utils/useStore.util'
import { ITraceStore } from '../trace/trace.interface.store'
import { IInputPolicyNode } from '../trace/trace.model.store'
import { useRiskHelper } from './risk.helper.store'
import { IRiskStore } from './risk.interface.store'
import { IExecuted, IRisk } from './risk.model.store'
import { useRiskService } from './risk.service.store'

interface IUseRiskStore {
    traceStore: ITraceStore
} 

export const useRiskStore = (props: IUseRiskStore): IRiskStore => {

    const storeUtil = useStoreUtil()
    const riskHelper = useRiskHelper()
    const riskService = useRiskService()

    const [riskList, setRiskList] = React.useState<IRiskStore['riskList']>([])
    const [executed, setExecuted] = React.useState<IExecuted>(riskHelper.createExecuted())

    const getRiskList:IRiskStore['getRiskList'] = async ()  => {
        const response = await riskService.getRiskList()
        if(response.isError) return storeUtil.createErrorResult([])
        setRiskList(response.data)
        return storeUtil.createSuccessResult(response.data)
    }

    const getRisk:IRiskStore['getRisk'] = async (id) => {
        const riskFinded = riskList.find(risk=> risk.id === id)
        if(riskFinded) return storeUtil.createSuccessResult(riskFinded)
        const response = await riskService.getRisk(id)
        if(response.isError) return  storeUtil.createErrorResult({
            id: 0,
            name: '',
            type: 1,
            description: '',
            schema: [],
            fields_group: [],
            active_trace_id: 0,
            is_active: false
            
        })
        setRiskList([...riskList, response.data])
        return storeUtil.createSuccessResult(response.data)
    }

    const createRisk:IRiskStore['createRisk'] = async (risk) => {
        const response = await riskService.createRisk(risk)
        if(response.isError) return storeUtil.createErrorResult(null)
        setRiskList([...riskList, response.data])
        return storeUtil.createSuccessResult(null)
    }

    const updateRisk:IRiskStore['updateRisk'] = async (riskUpdated) => {
        const response = await riskService.updateRisk(riskUpdated)
        if(response.isError) return storeUtil.createErrorResult(null)
        setRiskList(riskList.map(risk=> risk.id === riskUpdated.id ? riskUpdated : risk))
        return storeUtil.createSuccessResult(null)
    } 

    const deleteRisk:IRiskStore['deleteRisk'] = async (id) => {
        const response = await riskService.deleteRisk(id)
        if(response.isError) return storeUtil.createErrorResult(null)
        setRiskList(riskList.filter(risk=>risk.id !== id))
        return storeUtil.createSuccessResult(null)
    }

    const deleteParam: IRiskStore['deleteParam'] = async (idRisk, idParam) => {
        const riskFinded = riskList.find(risk=>risk.id === idRisk) 
        if(riskFinded) {
            const newRiskUpdated = {
                ...riskFinded,
                schema: riskFinded.schema.filter(param=> param.id !== idParam)
            }
            const riskListUpdated = riskList.map(risk=> risk.id === idRisk ? newRiskUpdated : risk)
            setRiskList(riskListUpdated)
            const response = await updateRisk(newRiskUpdated)
            if(response.isError) return storeUtil.createErrorResult(null)
            return storeUtil.createSuccessResult(null)
        } else {
            const getRiskResponse = await riskService.getRisk(idRisk)
            if(getRiskResponse.isError) return storeUtil.createErrorResult(null)
            const newRiskUpdated: IRisk = {
                ...getRiskResponse.data,
                schema: getRiskResponse.data.schema.filter(param=> param.id !== idParam)
            }
            setRiskList([...riskList, newRiskUpdated])
            const response = await updateRisk(newRiskUpdated)
            if(response.isError) return storeUtil.createErrorResult(null)
            return storeUtil.createSuccessResult(null)
        }
    }

    const createParam: IRiskStore['createParam'] = async (idRisk, param) => {
        const riskFinded = riskList.find(risk=>risk.id == idRisk)
        if(riskFinded){
            const newRisk: IRisk = {
                ...riskFinded, 
                schema: [...riskFinded.schema, {...param, id: new Date().getTime()}]
            }
            const riskListUpdated: Array<IRisk> = riskList.map(risk=>risk.id === newRisk.id ? newRisk : risk)
            setRiskList(riskListUpdated)
            const response = await updateRisk(newRisk)
            if(response.isError) return storeUtil.createErrorResult(riskHelper.createRisk())
            return storeUtil.createSuccessResult(newRisk)
        }else{
            const getRiskResult = await getRisk(idRisk)
            if(getRiskResult.isError) return storeUtil.createErrorResult(riskHelper.createRisk())
            const newRiskUpdated = {...getRiskResult.data, schema: [...getRiskResult.data.schema, param]}
            setRiskList([...riskList, newRiskUpdated])
            const updateRiskResult = await updateRisk(newRiskUpdated)
            if(updateRiskResult.isError) return storeUtil.createErrorResult(riskHelper.createRisk())
            return storeUtil.createSuccessResult(newRiskUpdated)
        }
    }

    const updateParam: IRiskStore['updateParam'] = async (idRisk, newParam) => {
        const riskFinded = riskList.find(risk=>risk.id == idRisk)
        if (riskFinded) {
            const newRiskUpdated:IRisk = {
                ...riskFinded, 
                schema: riskFinded.schema.map(param=>param.id === newParam.id ? newParam : param )
            }
            const response = await updateRisk(newRiskUpdated)
            if(response.isError) return storeUtil.createSuccessResult(riskHelper.createRisk())
            setRiskList(riskList.map(risk=>risk.id === newRiskUpdated.id ? newRiskUpdated : risk))
            return storeUtil.createSuccessResult(newRiskUpdated)
        } else {
            const getRiskResult = await getRisk(idRisk)
            if(getRiskResult.isError) return storeUtil.createErrorResult(riskHelper.createRisk())
            const newRiskUpdated: IRisk = {
                ...getRiskResult.data,
                schema: getRiskResult.data.schema.map(param=> param.id === newParam.id ? newParam : param)
            }
            setRiskList([...riskList, newRiskUpdated])
            const updateRiskResult = await updateRisk(newRiskUpdated)
            if(updateRiskResult.isError) return storeUtil.createErrorResult(riskHelper.createRisk())
            return storeUtil.createSuccessResult(newRiskUpdated)
        }
    }
    
    const executeRisk: IRiskStore['executeRisk'] =  async (riskId, inputs) => {
        const response = await riskService.executeRisk(riskId, inputs)
        if(response.isError) return storeUtil.createErrorResult(riskHelper.createExecuted())
        setExecuted(response.data)
        return storeUtil.createSuccessResult((response.data))
    }

    const copyRisk: IRiskStore['copyRisk'] = async (idRisk) => {
        const riskResult = await getRisk(idRisk)
        if(riskResult.isError) return storeUtil.createErrorResult(riskHelper.createRisk())
        const riskCopiedResult = await riskService.copyRisk(riskResult.data)
        if(riskCopiedResult.isError) return storeUtil.createErrorResult(riskHelper.createRisk())
        if(riskResult.data.active_trace_id === 0) {
            setRiskList([...riskList, riskResult.data]) 
            return storeUtil.createSuccessResult(riskCopiedResult.data)
        }
        const traceResult = await props.traceStore.getTrace(riskResult.data.active_trace_id)
        if(traceResult.isError) return storeUtil.createErrorResult(riskHelper.createRisk())
        const createTraceResult = await props.traceStore.createLoadedTraceWithOutUpdateTraceList(traceResult.data, riskCopiedResult.data.id)
        if(createTraceResult.isError) return storeUtil.createErrorResult(riskHelper.createRisk())
        const newRisk: IRisk = {...riskCopiedResult.data, active_trace_id: createTraceResult.data.id}
        const updateRiskResult = await updateRisk(newRisk)
        if(updateRiskResult.isError) return storeUtil.createErrorResult(riskHelper.createRisk())
        setRiskList([...riskList, newRisk])    
        return storeUtil.createSuccessResult(newRisk)
    }

    return {
        riskList,
        getRiskList,
        getRisk,
        createRisk,
        updateRisk,
        deleteRisk,
        deleteParam,
        createParam,
        updateParam,
        executeRisk,
        executed,
        copyRisk,
    }
}