import { useState } from 'react'
import { useStoreUtil } from '../../utils/useStore.util'
import { circuitFactory, stageFactory, statusFactory } from './circuit.helper.store'
import { ICircuitStoreObject, TUseCircuitStore } from './circuit.interface.store'
import { ICircuitModel, IStageModel, IStatusModel } from './circuit.model.store'
import { useCircuitService } from './circuit.service.store'

export const useCircuitStore: TUseCircuitStore = () => {
    
    const circuitService = useCircuitService()
    const storeUtil = useStoreUtil()

    const [circuitList, setCircuitList] = useState<ICircuitStoreObject['circuitList']>([])
    const [stageList, setStageList] = useState<ICircuitStoreObject['stageList']>([])

    const getCircuitList: ICircuitStoreObject['getCircuitList'] = async () => {
        
        const response = await circuitService.getCircuitList()
        
        if(response.isSuccess) {
            setCircuitList(response.data)
            return storeUtil.createSuccessResult([])
        }else {
            return storeUtil.createErrorResult([])
        }
    }

    const getCircuit: ICircuitStoreObject['getCircuit'] = async (circuitId) => {
        const circuitFinded = circuitList.find(circuit => circuit.id === circuitId)
        if(circuitFinded) return storeUtil.createSuccessResult(circuitFinded)
        const response = await circuitService.getCircuit(circuitId)
        if(response.isSuccess) {
            setCircuitList([response.data])
            return storeUtil.createSuccessResult(response.data)
        }else {
            return storeUtil.createErrorResult(circuitFactory())
        }
    }

    const getStageList: ICircuitStoreObject['getStageList'] = async () => {
        const response = await circuitService.getStageList()
        if(response.isSuccess) {
            setStageList(response.data)
            return storeUtil.createSuccessResult(response.data)
        }else{
            return storeUtil.createErrorResult([])
        } 
    }

    const createCircuit: ICircuitStoreObject['createCircuit'] = async (name) => {
        const response = await circuitService.createCircuit(name)
        if(response.isSuccess) return  storeUtil.createSuccessResult(response.data)
        else return storeUtil.createErrorResult(circuitFactory())
    }

    const updateCircuit: ICircuitStoreObject['updateCircuit']  = async (circuitId, name) => {
        const response = await circuitService.updateCircuit(circuitId, name)
        if(response.isSuccess) return storeUtil.createSuccessResult(null)
        else return storeUtil.createSuccessResult(null)
    }

    const deleteCircuit: ICircuitStoreObject['deleteCircuit'] = async(circuitId) => {
        const response = await circuitService.deleteCircuit(circuitId)
        if(response.isSuccess) {
            const newCircuitList : ICircuitStoreObject['circuitList'] = circuitList.filter(circuit=> circuit.id !== circuitId)
            setCircuitList(newCircuitList)
            return storeUtil.createSuccessResult(null)
        }else{
            return storeUtil.createErrorResult(null)
        }
    }

    const createStage: ICircuitStoreObject['createStage'] = async (name) => {
        const response = await circuitService.createStage(name)
        if(response.isSuccess) return storeUtil.createSuccessResult(response.data)
        else {
            setStageList([...stageList, response.data])
            return storeUtil.createErrorResult(response.data)
        }
    }

    const deleteStage: ICircuitStoreObject['deleteStage'] = async (stageId) => {
        const response = await circuitService.deleteStage(stageId)
        if(response.isSuccess) {
            setStageList(stageList.filter(stage=>stage.id !== stageId))
            return storeUtil.createSuccessResult(null)
        }else return storeUtil.createErrorResult(null)
    }

    const updateStage: ICircuitStoreObject['updateStage'] = async (stageId: number, name: string) => {
        const response = await circuitService.updateStage(stageId, name)
        if(response.isSuccess) return storeUtil.createSuccessResult(null)
        else return storeUtil.createErrorResult(null)
    }

    const getStage: ICircuitStoreObject['getStage'] = async(stageId) => {
        const stageFinded = stageList.find(stage=> stage.id === stageId)
        if(stageFinded) return storeUtil.createSuccessResult(stageFinded)
        const response = await circuitService.getStage(stageId)
        if(response.isSuccess) return  storeUtil.createSuccessResult(response.data)
        else return storeUtil.createErrorResult(response.data)
    }


    const assignStageToCircuit: ICircuitStoreObject['assignStageToCircuit'] = async(stageId, circuitId) => {
        const getCircuitResult = await getCircuit(circuitId)
        if(getCircuitResult.isError) return storeUtil.createErrorResult(null)
        const getStageResult = await getStage(stageId)
        if(getStageResult.isError) return storeUtil.createErrorResult(null)
        const newStage: IStageModel = {...getStageResult.data, order: getCircuitResult.data.stages.length + 1}
        const assignStageToCircuitResponse = await circuitService.assignStageToCircuit(newStage, circuitId)
        if(assignStageToCircuitResponse.isError) return storeUtil.createErrorResult(null)
        const newCircuit: ICircuitModel = {
            ...getCircuitResult.data,
            stages: [...getCircuitResult.data.stages, newStage]
        }
        setCircuitList([...circuitList].map(circuit=>circuit.id === newCircuit.id ? newCircuit : circuit))
        return storeUtil.createSuccessResult(null)        
    }

    const denyStageToCircuit: ICircuitStoreObject['denyStageToCircuit'] = async (stageId, circuitId ) => {
        
        const getCircuitResult = await getCircuit(circuitId)

        if(getCircuitResult.isError) return storeUtil.createErrorResult(circuitFactory())
        
        const newCircuit: ICircuitModel = {
            ...getCircuitResult.data, 
            stages: 
                getCircuitResult.data.stages.filter(stage=>stage.id !==stageId).map((stage, index)=> ({...stage, order: index+1}))
        }

        const denyStageToCircuitResponse = await circuitService.denyStageToCircuit(stageId, circuitId)
        
        if(denyStageToCircuitResponse.isError) return storeUtil.createErrorResult(circuitFactory())
        setCircuitList([...circuitList].map(circuit=>circuit.id === newCircuit.id ? newCircuit : circuit))
        return storeUtil.createSuccessResult(newCircuit)
    }


    const changeStageOrderToCircuit: ICircuitStoreObject['changeStageOrderToCircuit'] = async (circuitId, newStageList) => {

        const getCircuitResult = await getCircuit(circuitId)
        if(getCircuitResult.isError) return storeUtil.createErrorResult(circuitFactory()) 

        const changeStageOrderToCircuitResult = await circuitService.changeStageOrderToCircuit(circuitId, newStageList)
        if(changeStageOrderToCircuitResult.isError) return storeUtil.createErrorResult(circuitFactory())

        const newCircuitForUpdate: ICircuitModel = {...getCircuitResult.data, stages: newStageList}
        setCircuitList(circuitList.map(circuit=>circuit.id === circuitId ? {...circuit, stages: newStageList}: {...circuit}))

        return storeUtil.createSuccessResult(newCircuitForUpdate)
    }
    
    const changeStatusOrderToStage: ICircuitStoreObject['changeStatusOrderToStage'] = async (circuitId, stageId, statusList) => {

        const getStageResult = await getStage(stageId)
        if(getStageResult.isError) return storeUtil.createErrorResult(stageFactory()) 

        const changeStatusOrderToStageResult = await circuitService.changeStatusOrderToStage(stageId, statusList)
        if(changeStatusOrderToStageResult.isError) return storeUtil.createErrorResult(stageFactory())

        const newStage: IStageModel = {...getStageResult.data, statuses: statusList}
    
        setStageList(stageList.map(stage=>stage.id === stageId ? newStage: {...stage}))

        return storeUtil.createSuccessResult(newStage)
    }


    const createStatus: ICircuitStoreObject['createStatus'] = async(stageId, status) => {

        const getStageResult = await getStage(stageId)
        if(getStageResult.isError) return storeUtil.createErrorResult(statusFactory())
        const orderList: Array<number> = getStageResult.data.statuses.map(status=>status.order)
        const maxOrder: number = orderList.reduce((current, acumulator)=> current > acumulator ? current : acumulator, 0)
        const createStatusResponse = await circuitService.createStatus(stageId, {...status, order: maxOrder + 1})
        if(createStatusResponse.isError) return storeUtil.createErrorResult(createStatusResponse.data)
        setStageList(stageList.map(stage=>({
            ...stage,
            statuses: [...stage.statuses, createStatusResponse.data]
        })))
        return storeUtil.createSuccessResult(createStatusResponse.data)
    }

    const updateStatus: ICircuitStoreObject['updateStatus'] = async (stageId, newStatus) => {

        const response = await circuitService.updateStatus(stageId, newStatus)
        if(response.isError) return storeUtil.createErrorResult(null)

        setStageList(stageList.map(stage=>({
            ...stage,
            statuses: stage.statuses.map(status=>status.id === newStatus.id ? {...newStatus}: status)
        })))

        return storeUtil.createSuccessResult(null)
    }

    const deleteStatus: ICircuitStoreObject['deleteStatus'] = async (stageId: number, statusId: number) => {
        const response = await circuitService.deleteStatusToStage(stageId, statusId)
        if(response.isError) return storeUtil.createErrorResult(null)

        setStageList(stageList.map(stage=>({
            ...stage,
            statuses: stage.statuses.filter(status=>status.id !== statusId)
        })))

        return storeUtil.createSuccessResult(null)
    }

    const getStatus: ICircuitStoreObject['getStatus'] = async (stageId, statusId) => {
        const response = await circuitService.getStage(stageId)
        if(response.isError) return storeUtil.createErrorResult(statusFactory())
    
        const statusFinded = response.data.statuses.find(status=>status.id === statusId)
        if(!statusFinded) return storeUtil.createErrorResult(statusFactory())

        return storeUtil.createSuccessResult(statusFinded)
    }

    return {
        circuitList,
        stageList,

        getCircuitList,
        getCircuit,
        createCircuit,
        deleteCircuit,
        updateCircuit,

        getStageList,
        createStage,
        deleteStage,
        updateStage,
        getStage,

        assignStageToCircuit,
        denyStageToCircuit,
        changeStageOrderToCircuit,

        changeStatusOrderToStage,
        createStatus,
        updateStatus,
        deleteStatus,
        getStatus
    }
}