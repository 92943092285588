import React from 'react'
import { useHistory, useParams } from 'react-router'
import { FormGenerator, getValueToForm, TForm } from 'FormGenerator'
import { ITableGeneratorComponent, TableGeneratorComponent, TItem } from 'table-generator'
import { Button, CenterText, FloatPlusButton, Loader, Modal, ModalContent, Title, Text, EaseInAnimation, FormWrapper } from '../elements/Element'
import { IAppStore } from '../stores/app/app.interface.store'
import { IRiskStore } from '../stores/risk/risk.interface.store'
import { ITraceStore } from '../stores/trace/trace.interface.store'
import { traceFormScheme } from '../schemes/trace.form.scheme'
import { useFile } from '../utils/useFile.util'
import { ITrace } from '../stores/trace/trace.model.store'

interface ITraceListPageProps {
    traceStore: ITraceStore
    riskStore: IRiskStore
    appStore: IAppStore
}

export const TraceListPage = (props: ITraceListPageProps) => {

    const { riskId } = useParams<{ riskId: string }>()
    const history = useHistory()
    const file = useFile()

    const [isLoadingData, setIsLoadingData] = React.useState<boolean>(false)
    const [isOpenDeleteTraceAlert, setIsOpenDeleteTraceAlert] = React.useState<boolean>(false)
    const [isOpenEditTraceAlert, setIsOpenEditTraceAlert] = React.useState<boolean>(false)
    const [isOpenCopyTraceAlert, setIsOpenCopyTraceAlert] = React.useState<boolean>(false)
    const [isOpenCreateTraceFormModal, setIsOpenCreateTraceFormModal] = React.useState<boolean>(false)

    const [traceIdForDelete, setTraceIdForDelete] = React.useState<number>(0)
    const [traceIdForEdit, setTraceIdForEdit] = React.useState<number>(0)
    const [traceIdForCopy, setTraceIdForCopy] = React.useState<number>(0)
    const [riskTitle, setRiskTitle] = React.useState<string>('')

    const [ traceForm, setTraceForm ] = React.useState<TForm>([...traceFormScheme])


    React.useEffect(() => { onInit() }, [])

    const onInit = async () => {
        setIsLoadingData(true)

        const [getRiskResult, getTraceListResult] = await Promise.all([
            props.riskStore.getRisk(Number(riskId)),
            props.traceStore.getTraceListByRiskId(Number(riskId))
        ])

        if (getRiskResult.isError || getTraceListResult.isError) props.appStore.openApiErrorAlert()

        setRiskTitle(getRiskResult.data.name)

        setIsLoadingData(false)
    }


    const onActionTableHandler = async (type: string, key: string) => {
        if (type === 'edit') {
            history.push(`/risk/${riskId}/trace/${Number(key)}`)
            return
        }
        if (type === 'active') {
            setIsLoadingData(true)
            await props.traceStore.activateTrace(Number(key))
            setIsLoadingData(false)
            return
        }
        if (type === 'delete') {
            setIsOpenDeleteTraceAlert(true)
            setTraceIdForDelete(Number(key))
            return
        }
        if (type === 'copy') {
            setIsOpenCopyTraceAlert(true)
            setTraceIdForCopy(Number(key))
            return
        }
        if(type === 'export') {
            const traceResult = await props.traceStore.getTrace(Number(key))
            const riskResult = await props.riskStore.getRisk(Number(riskId))
            if(traceResult.isError || riskResult.isError) return props.appStore.openApiErrorAlert()
            const newFileName: string = `${riskResult.data.name}/${traceResult.data.title}/${new Date().toJSON().slice(0, 10).split('-').reverse().join('-')}.json`
            file.saveData(traceResult.data, newFileName)
            props.appStore.openExportTraceSuccessAlert(newFileName)
            return
        }
        if(type === 'import') {
            const fileData = await file.readFile<ITrace>()
            const traceResult = await props.traceStore.getTrace(Number(key))
            if(traceResult.isError) return props.appStore.openApiErrorAlert()
            props.traceStore.updateTrace({
                ...traceResult.data, 
                diagram: fileData.diagram,
                trace: fileData.trace,
                title: fileData.title
            })
            props.appStore.openImportTrazeSuccessAlert(traceResult.data.id, traceResult.data.title)
        }
    }

    const onDeleteConfirmHandler = async () => {
        setIsLoadingData(true)
        await props.traceStore.deleteTrace(traceIdForDelete)
        setIsLoadingData(false)
        setIsOpenDeleteTraceAlert(false)
        setTraceIdForDelete(0)
    }

    const onEditConfirmHandler = () => history.push(`/risk/${riskId}/trace/${traceIdForEdit}`)

    const onCreateHandler = async () => setIsOpenCreateTraceFormModal(true)
    
    const onSubmitCreateTraceFormHandler = async () => {
        setIsLoadingData(true)
        const traceNameValue = getValueToForm(traceForm, '1')
        const newTraceResult = await props.traceStore.createTrace(Number(riskId), traceNameValue)
        if(newTraceResult.isError) props.appStore.openApiErrorAlert()
        if(newTraceResult.isSuccess) setTraceIdForEdit(newTraceResult.data.id)
        setTraceForm(traceForm.map(input=>({...input, value: ''})))
        setIsLoadingData(false)
        setIsOpenCreateTraceFormModal(false)
        setIsOpenEditTraceAlert(true)
    }

    const onCopyConfirmHandler = async () => {
        setIsLoadingData(true)
        const newTraceResult = await props.traceStore.copyTrace(Number(traceIdForCopy))

        if (newTraceResult.isError) props.appStore.openApiErrorAlert()
        if (newTraceResult.isSuccess) {
            setTraceIdForEdit(newTraceResult.data.id)
            setTraceIdForCopy(0)
            setIsOpenEditTraceAlert(true)
        }

        setIsLoadingData(false)
        setIsOpenCopyTraceAlert(false)

    }

    // todo: esto tiene que ir en un helper
    const bodyRowListComputed: ITableGeneratorComponent['bodyList'] = props.traceStore.traceList.map(trace => {

        const newRow: Array<TItem> = [
            {
                type: 'text',
                text: String(trace.id)
            },
            {
                type: 'text',
                text: trace.title
            },
            {
                type: 'tags',
                tags: [{
                    text: trace.is_active ? 'ACTIVADO' : 'DESACTIVADO',
                    color: trace.is_active ? 'rgb(0,190,50)' : 'grey'
                }]
            },
            {
                type: 'actions',
                key: String(trace.id),
                actions: [
                    {
                        type: 'export',
                        text: 'Exportar',
                        disabled: false
                    },
                    {
                        type: 'import',
                        text: 'Importar',
                        disabled: false
                    },
                    {
                        type: 'copy',
                        text: 'Duplicar',
                        disabled: false
                    },
                    {
                        type: 'edit',
                        text: 'Editar',
                        disabled: false
                    },
                    {
                        type: 'delete',
                        text: 'Eliminar',
                        disabled: false
                    },
                    {
                        type: 'active',
                        text: 'Activar',
                        disabled: trace.is_active  ? true : false
                    }
                ]
            },
        ]

        // if (!trace.is_active && newRow[3] && newRow[3].type === 'actions') newRow[3].actions.push({
        //     type: 'active',
        //     text: 'Activar'
        // })

        return newRow
    })

    return (
        <div>
            <Modal
                size="S"
                level="low"
                title=""
                isOpen={isOpenCreateTraceFormModal}
                onClose={() => setIsOpenCreateTraceFormModal(false)}
            >
                <ModalContent>
                    <CenterText>
                        <Title>Crear Traza</Title>
                    </CenterText>
                    <br />
                    <FormWrapper>
                        <FormGenerator
                            dataTestId='createRiskForm'
                            form={traceForm}
                            setForm={setTraceForm}
                            onSubmit={onSubmitCreateTraceFormHandler}
                            submitText="Guardar"
                            isShowSubmit={true}
                            actions={[]}
                            onAction={()=>{}}
                            onValidForm={()=>{}}
                            onInvalidForm={()=>{}}
                        />
                    </FormWrapper>

                </ModalContent>
            </Modal>
            <Modal
                size="S"
                title=""
                level="low"
                isOpen={isOpenCopyTraceAlert}
                onClose={() => setIsOpenCopyTraceAlert(false)}
            >
                <ModalContent>
                    <CenterText>
                        <Title>Confirmar</Title>
                        <Text>¿Quieres duplicar la traza #{traceIdForCopy}?</Text>
                        <br />
                        <Button onClick={onCopyConfirmHandler}>Aceptar</Button>
                    </CenterText>
                    <br />
                </ModalContent>
            </Modal>
            <Modal
                level="low"
                size="S"
                title=""
                isOpen={isOpenDeleteTraceAlert}
                onClose={() => setIsOpenDeleteTraceAlert(false)}
            >
                <ModalContent>
                    <CenterText>
                        <Title>Confirmar</Title>
                        <Text>¿Quieres eliminar la traza #{traceIdForDelete}?</Text>
                        <br />
                        <Button onClick={onDeleteConfirmHandler}>Aceptar</Button>
                    </CenterText>
                    <br />
                </ModalContent>
            </Modal>
            <Modal
                level="low"
                size="S"
                title=""
                isOpen={isOpenEditTraceAlert}
                onClose={() => setIsOpenEditTraceAlert(false)}
            >
                <ModalContent>
                    <CenterText>
                        <Title>Traza Creada</Title>
                        <Text>¿Quieres editar la traza #{traceIdForEdit}?</Text>
                        <br />
                        <Button onClick={onEditConfirmHandler}>Aceptar</Button>
                    </CenterText>
                    <br />
                </ModalContent>
            </Modal>
            <Loader isOpen={isLoadingData} />
            <EaseInAnimation>
                <br></br><br></br>
                <Title>Trazas de la politica de riesgo "{riskTitle}" Nº{riskId}</Title>
                <br></br>
                <TableGeneratorComponent
                    headerList={['ID', 'Nombre', 'Estado', 'Acciones']}
                    bodyList={bodyRowListComputed}
                    onAction={onActionTableHandler}
                    tools={[]}
                    paginationList={[]}
                />
                <FloatPlusButton onClick={onCreateHandler} />
            </EaseInAnimation>

        </div>

    )
}