import { ICircuitModel, IStageModel, IStatusModel } from "./circuit.model.store";

export const circuitFactory = (): ICircuitModel => ({
    "id": 0,
    "name": '',
    "created_at": new Date(),
    "updated_at": new Date(),
    "is_active": false,
    "is_deleted": false,
    "stages_quantity": 0,
    "stages": []   
})

export const stageFactory =  (): IStageModel => ({
    "id": 0,
    "name": '',
    "created_at": '',
    "updated_at": '',
    "is_active": false,
    "is_deleted": false,
    "order": 0,
    "statuses": [],
})

export const statusFactory= (): IStatusModel => ({
    "id": 0,
    "name": '',
    "order": 0,
    "description": '',
    "created_at": new Date(),
    "updated_at": new Date(),
    "is_active": false,
    "is_deleted": false
})