import React from 'react'

export interface IActionResult <IData> {
    data: IData
    isError: boolean
    isSuccess: boolean
}

export type TActionAsyncResult<IData> = Promise<IActionResult<IData>>

export const useStoreUtil = () => ({
    createSuccessResult: <IData>(data: IData) : IActionResult<IData> =>({
        data: data,
        isError: false,
        isSuccess: true
    }),
    createErrorResult: <IData>(data: IData) : IActionResult<IData> =>({
        data: data,
        isError: true,
        isSuccess: false
    })

    // todo: creador de stores, que reciba los states por un lado y los actions por el otro, y que devuelva esto por separado
})