import axios from "axios"
import { apiResponseFactory, TApiClientResponse, useApiClient } from "../../utils/apiClient.use.util"
import { setup } from "../../utils/setup"
import { TExecuteBureauMexicoPFAErequest, IExecuteBureauMexicoPFAEresponse, IExecuteBureauMexicoPFrequest, IExecuteBureauMexicoPFresponse, IExecuteBureauMexicoPMrequest, IExecuteBureauMexicoPMresponse, IGenerateNipRequest, IGenerateNipResponse, IValidateNipRequest, IValidateNipResponse, IVerifyNipRequest, IVerifyNipRequestResponse, IExecuteDownloadNipReportCSVrequest, IBureauPFRequestReport, IBureauPMRequestReport } from "./bureau.model.store"


interface IBureauService {

    executeBureauMexicoPF: (param: IExecuteBureauMexicoPFrequest) => TApiClientResponse<IExecuteBureauMexicoPFresponse>
    executeBureauMexicoPFAE: (param: TExecuteBureauMexicoPFAErequest) => TApiClientResponse<IExecuteBureauMexicoPFAEresponse>
    executeBureauMexicoPM: (param: IExecuteBureauMexicoPMrequest) => TApiClientResponse<IExecuteBureauMexicoPMresponse>

    verifyNip: (param: IVerifyNipRequest) => TApiClientResponse<'nip is verified' | 'nip is not verified' | 'rfc is not valid'>
    generateNip: (param: IGenerateNipRequest) => TApiClientResponse<'nip is generated' | 'rfc is not valid'>
    validateNip: (param: IValidateNipRequest) => TApiClientResponse<'nip is valid' | 'nip is not valid'>

    executeDownloadBureauMexicoPF: (param: IExecuteBureauMexicoPFrequest) => TApiClientResponse<any>
    executeDownloadBureauMexicoPFAE: (param: TExecuteBureauMexicoPFAErequest) => TApiClientResponse<any>
    executeDownloadBureauMexicoPM: (param: IExecuteBureauMexicoPMrequest) => TApiClientResponse<any>

    executeDownloadNipReportCSV: (param: IExecuteDownloadNipReportCSVrequest) =>  TApiClientResponse<any>

    getBureauRequestList: (param:{
        fullName: string
        rfc: string
        startDate: string
        endDate: string
    }) => TApiClientResponse<Array<IBureauPFRequestReport | IBureauPMRequestReport>>

    getBureauRequestDetail: (id: string) => TApiClientResponse<IBureauPFRequestReport | IBureauPMRequestReport>
    downloadBureauRequestDetail: (id: string) => TApiClientResponse<any>
}

export const useBureauService = (): IBureauService => {

    const apiClient = useApiClient()

    const executeBureauMexicoPF: IBureauService['executeBureauMexicoPF'] = async (param) => {
        const response = await apiClient.post<IExecuteBureauMexicoPFresponse>(`${setup.bureauApiUrl}/bureauMxPF`, param)
        return response
    }

    const executeBureauMexicoPFAE: IBureauService['executeBureauMexicoPFAE'] = async (param) => {
        const response = await apiClient.post<IExecuteBureauMexicoPFAEresponse>(`${setup.bureauApiUrl}/bureauMxPFAE`, param)
        return response
    }

    const executeBureauMexicoPM: IBureauService['executeBureauMexicoPM'] = async (param) => {
        const response = await apiClient.post<IExecuteBureauMexicoPMresponse>(`${setup.bureauApiUrl}/bureauMxPM`, param)
        return response
    }

    ////////////////////

    const executeDownloadBureauMexicoPF: IBureauService['executeDownloadBureauMexicoPF'] = async (param) => {
        const response = await apiClient.postArraybuffer<any>(`${setup.bureauApiUrl}/getPFPDF`, param)
        return response
    }
    const executeDownloadBureauMexicoPFAE: IBureauService['executeDownloadBureauMexicoPFAE'] = async (param) => {
        const response = await apiClient.postArraybuffer<any>(`${setup.bureauApiUrl}/getPFAEPDF`, param)
        return response
    }

    const executeDownloadBureauMexicoPM: IBureauService['executeDownloadBureauMexicoPM']  = async (param) => {
        const response = await apiClient.postArraybuffer<any>(`${setup.bureauApiUrl}/getPMPDF`, param)
        return response
    }

    const executeDownloadNipReportCSV: IBureauService['executeDownloadNipReportCSV'] = async (param) => {
        const response = await apiClient.postArraybuffer<any>(`${setup.bureauApiUrl}/getAuditReport`, param)
        return response
    }

    //////////////

    const verifyNip: IBureauService['verifyNip'] = async (param) => {
        const response = await apiClient.post<IVerifyNipRequestResponse>(`${setup.bureauApiUrl}/verifyNip`, param)
        if (response.status === 422) return apiResponseFactory(200, 'rfc is not valid')
        if (response.data.verified) return apiResponseFactory(200, 'nip is verified')
        if (!response.data.verified) return apiResponseFactory(200, 'nip is not verified')
        return apiResponseFactory(500, 'rfc is not valid')
    }

    const generateNip: IBureauService['generateNip'] = async (param) => {
        const response = await apiClient.post<IGenerateNipResponse>(`${setup.bureauApiUrl}/generateNip`, param)
        if (response.status === 422) return apiResponseFactory(200, 'rfc is not valid')
        if (response.isSuccess) return apiResponseFactory(200, 'nip is generated')
        return apiResponseFactory(500, 'rfc is not valid')
    }

    const validateNip: IBureauService['validateNip'] = async (param) => {
        const response = await apiClient.post<IValidateNipResponse>(`${setup.bureauApiUrl}/validateNip`, param)
        if (response.status === 409) return apiResponseFactory(200, 'nip is not valid')
        if (response.isSuccess) return apiResponseFactory(200, 'nip is valid')
        return apiResponseFactory(500, 'nip is not valid')
    }


    //////////////

    const getBureauRequestList: IBureauService['getBureauRequestList'] = async (param) => {
        const response = await apiClient.get<Array<IBureauPFRequestReport | IBureauPMRequestReport>>(`${setup.bureauApiUrl}/getReports?fullname=${param.fullName}&rfc=${param.rfc}&start_date=${param.startDate}&end_date=${param.endDate}`)
        return response        
    }

    const getBureauRequestDetail: IBureauService['getBureauRequestDetail'] = async (id) => {
        const response = await apiClient.get<IBureauPFRequestReport | IBureauPMRequestReport>(`${setup.bureauApiUrl}/report/${id}`)
        return response
    }

    const downloadBureauRequestDetail: IBureauService['downloadBureauRequestDetail'] = async (id) => {
        const response = await apiClient.getArraybuffer<IBureauPFRequestReport | IBureauPMRequestReport>(`${setup.bureauApiUrl}/download-pdf-report/${id}`)
        return response
    }

    return {
        executeBureauMexicoPF,
        executeBureauMexicoPFAE,
        executeBureauMexicoPM,

        executeDownloadBureauMexicoPF,
        executeDownloadBureauMexicoPFAE,
        executeDownloadBureauMexicoPM,
        executeDownloadNipReportCSV,

        verifyNip,
        generateNip,
        validateNip,


        getBureauRequestList,
        getBureauRequestDetail,
        downloadBureauRequestDetail
    }
}