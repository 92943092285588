import { TForm } from 'FormGenerator'

export const createRiskFormScheme: TForm = [
    {
        id: '2',
        type: 'text',
        label: 'Nombre',
        value: '',
        placeholder: 'Campo Requerido...',
        required: true,
        error: false,
        minLength: 0,
        maxLength: 100,
        disabled: false,
        size: 12,
        regex: '',
        hidden: false,
    },
    {
        id: '3',
        type: 'text',
        label: 'Descripción',
        value: '',
        placeholder: 'Campo Requerido...',
        required: true,
        error: false,
        minLength: 0,
        maxLength: 100,
        disabled: false,
        size: 12,
        regex: '',
        hidden: false,
    },
    {
        id: '4',
        type: 'options',
        label: 'Tipo',
        value: '',
        placeholder: 'Campo Requerido...',
        required: true,
        options: [{ value: '1', label: 'Primaria' }, { value: '2', label: 'Secundaria' }],
        error: false,
        disabled: false,
        size: 12,
        hidden: false,
    }
]