import { TForm, getValueToForm } from "FormGenerator"
import { IExecuteBureauMexicoPFrequest, IExecuteBureauMexicoPMrequest, TExecuteBureauMexicoPFAErequest, TState } from "../stores/bureau/bureau.model.store"

export const getRFCfromCurp = (curp: string): string => 
    curp.toUpperCase().split('').reverse().filter((character, index)=>index>4).reverse().join('')


export const executeBureauMexicoPFrequestFacory = (form: TForm): IExecuteBureauMexicoPFrequest => ({
    apellidoPaterno: getValueToForm(form, '1').toUpperCase(),
    apellidoMaterno: getValueToForm(form, '2').toUpperCase(),
    primerNombre: getValueToForm(form, '3').toUpperCase(),
    segundoNombre: getValueToForm(form, '4').toUpperCase(),
    rfc: getValueToForm(form, '5').toUpperCase(),
    nacionalidad: 'MX',
    calle: getValueToForm(form, '6').toUpperCase(),
    nroCalle: getValueToForm(form, '7') ? getValueToForm(form, '7').toUpperCase() : 'SN',
    coloniaPoblacion: getValueToForm(form, '8').toUpperCase(),
    delegacionMunicipio: getValueToForm(form, '9').toUpperCase(),
    ciudad: getValueToForm(form, '10').toUpperCase(),
    cp: getValueToForm(form, '11').toUpperCase(),
    estado: getValueToForm(form, '12').toUpperCase() as TState
})  

export const executeBureauMexicoPFAErequestFactory = (form: TForm): TExecuteBureauMexicoPFAErequest => executeBureauMexicoPFrequestFacory(form)

export const executeBureauMexicoPMrequestFactory = (form: TForm): IExecuteBureauMexicoPMrequest =>  ({
    nombre: getValueToForm(form, '1').toUpperCase(),
    rfc: getValueToForm(form, '5').toUpperCase(),
    nacionalidad: 'MX',
    calle: getValueToForm(form, '2').toUpperCase(),
    nroCalle: getValueToForm(form, '3') ? getValueToForm(form, '3').toUpperCase() : 'SN',
    coloniaPoblacion: getValueToForm(form, '4').toUpperCase(),
    delegacionMunicipio: getValueToForm(form, '6').toUpperCase(),
    ciudad: getValueToForm(form, '7').toUpperCase(),
    cp: getValueToForm(form, '8').toUpperCase(),
    estado: getValueToForm(form, '9').toUpperCase() as TState
})