import { IExecuted } from "./execute.model.store";

export const executedFactory = (): IExecuted => ({
    trace_id: 0,
    risk_policy_id: 0,
    inputData: {},
    treeData: {},
    extraData: {},
    exec_nodes: [],
    status: 0,
    response: '',
    is_error: false,
    execution_id: 0,
    executed_at: new Date()
})