import React from 'react'
import { useParams } from 'react-router';

import { DiagramComponent } from '../components/Diagram/Diagram.component'
import { FormGenerator, getValueToForm, TForm } from 'FormGenerator'
import { EaseInAnimation, FloatSaveButton, FloatPlusButton, Loader, Modal, ModalContent, Title, Text, Button, CenterText, FloatBackButton, FloatTitle, Row, Cell } from '../elements/Element';
import { addCurrencyCountryLimitsToFinishNodeSchema, addNodeConnectionUsingElement, addRiskPolicyListToCallPolicyRiskNodeSheme, createBox, createIdEnabled, createNodeTrace, createUpdateTraceNode, duplicateTraceNode, getNodeTypeForDiagram, getPreloadDataToNodeFormData, mapFieldGroupToIForm, onChangeAdvanceCalculatorNodeHandler, onChangeFinishNodeHandler, onRemoveNodeToDiagram, onRemoveNodeToTrace, preloadFormDataValuesForPolicyNodeForm, pushNewAnyListToAnyRecord, removeNodeConectionUsingElements } from '../helpers/EditTrace.helper';
import { TOptionNodeType, binaryNodeFormScheme, calculatorNodeFormScheme, callBureauNodeFormScheme, finishNodeFormScheme, nodeTypeFormScheme, rootNodeFormScheme, advancedCalculatorNodeFormScheme, callPolicyNodeFormScheme } from '../schemes/node.form.scheme';
import { IAppStore } from '../stores/app/app.interface.store';
import { IRiskStore } from '../stores/risk/risk.interface.store';
import { ITraceStore } from '../stores/trace/trace.interface.store';
import { IBox, ILineConector, ITrace, TBoxType, TDiagram, TNode, TNodeType } from '../stores/trace/trace.model.store';

interface IEditTracePage {
    riskStore: IRiskStore
    traceStore: ITraceStore
    appStore: IAppStore
}

export const EditTracePage: React.FC<IEditTracePage> = (props) => {

    const param = useParams<{ traceId: string, riskId: string }>()
    const [isLoadingData, setIsLoadingData] = React.useState<boolean>(false)

    const [isOpenCreateNodeModal, setIsOpenCreateNodeModal] = React.useState<boolean>(false)
    const [isOpenUpdateNodeModal, setIsOpenUpdateNodeModal] = React.useState<boolean>(false)
    const [isOpenSaveConfirmModal, setIsOpenSaveConfirmModal] = React.useState<boolean>(false)
    const [isOpenConectionDeleteConfirmModal, setIsOpenConectionDeleteConfirmModal] = React.useState<boolean>(false)
    const [isOpenNodeDeleteConfirmModal, setIsOpenNodeDeleteConfirmModal] = React.useState<boolean>(false)

    const [diagram, setDiagram] = React.useState<TDiagram>([])
    const [diagramRecord, setDiagramRecord] = React.useState<Array<TDiagram>>([])
    const [trace, setTrace] = React.useState<ITrace['trace']>([])
    const [traceTitle, setTraceTitle] = React.useState<string>('')
    const [traceRecord, setTraceRecord] = React.useState<Array<ITrace['trace']>>([])

    const [isShowNodeForm, setIsShowNodeForm] = React.useState<boolean>(false)
    const [formDataForCreateNode, setFormDataForCreateNode] = React.useState<TForm>([])
    const [formDataTypeNodeForCreate, setFormDataTypeNodeForCreate] = React.useState<TForm>([])

    const [formDataForUpdateNode, setFormDataForUpdateNode] = React.useState<TForm>([])
    const [nodeSelectedForUpdate, setNodeSelectedForUpdate] = React.useState<TNode>({
        id: 0,
        nodeType: 0,
        properties: {},
        slots: []
    })

    const [elementsForRemove, setElementsForRemove] = React.useState<TDiagram>([])
    const [nodeElementIdForRemove, setNodeElementIdForRemove] = React.useState<string>('')

    const [screenCoordinates, setScreenCoordinates] = React.useState<{ x: number, y: number }>({ x: 0, y: 0 })

    const [riskName, setRiskName] = React.useState<string>('')

    React.useEffect(() => {
        onInit()
    }, [])

    const setFormDataForCreateNodeHandler = async (formData: TForm) => {

        if(getValueToForm(formDataTypeNodeForCreate, '1') === 'advancedCalculator') {
            setFormDataForCreateNode(onChangeAdvanceCalculatorNodeHandler(formData))
            return
        }

        if(getValueToForm(formDataTypeNodeForCreate, '1') === 'finish') {
            setFormDataForCreateNode(onChangeFinishNodeHandler(formData))
            return
        }

        if(getValueToForm(formDataTypeNodeForCreate, '1') === 'policy' && getValueToForm(formData, '3')) {
            const oldRiskId = getValueToForm(formDataForCreateNode,'3')
            const newRiskId = getValueToForm(formData, '3')
            if(oldRiskId !== newRiskId) {
                const riskPolicyId: number = Number(getValueToForm(formData, '3'))
                props.appStore.showLoader()
                const result = await props.riskStore.getRisk(riskPolicyId)
                props.appStore.hideLoader()
                const newAditionalFields = mapFieldGroupToIForm(result.data.fields_group)
                const newFormDataWithOutOldDinamicInputs = formData.filter(input=> Number(input.id) < 10)
                setFormDataForCreateNode([...newFormDataWithOutOldDinamicInputs, ...newAditionalFields])
                return
            }
            setFormDataForCreateNode(formData)
            return
        } 
        
        setFormDataForCreateNode(formData)
    }

    const setFormDataForUpdateNodeHandler = (formData: TForm) => {
        if (nodeSelectedForUpdate.nodeType === 6 ) setFormDataForUpdateNode(onChangeAdvanceCalculatorNodeHandler(formData))
        else if (nodeSelectedForUpdate.nodeType === 4) setFormDataForUpdateNode(onChangeFinishNodeHandler(formData))
        else setFormDataForUpdateNode(formData)
    }

    const setChangesHandler = (newDiagram: TDiagram, mewTrace: ITrace['trace']) => {
        setDiagramHandler(newDiagram)
        setTraceHandler(mewTrace)
    }

    const setDiagramHandler = (newDiagram: TDiagram) => {
        setDiagram(newDiagram)
        setDiagramRecord(pushNewAnyListToAnyRecord<TDiagram>(newDiagram, diagramRecord))
    }

    const setTraceHandler = (mewTrace: ITrace['trace']) => {
        setTrace(mewTrace)
        setTraceRecord(pushNewAnyListToAnyRecord<ITrace['trace']>(mewTrace, traceRecord))
    }

    const onBackRecordHandler = () => {

        const traceWithOutLastRecord = traceRecord.length > 1 ? traceRecord.filter((trace, index) => index < traceRecord.length - 1) : traceRecord
        const diagramWithOutLastRecord = diagramRecord.length > 1 ? diagramRecord.filter((diagram, index) => index < diagramRecord.length - 1) : diagramRecord

        setTraceRecord(traceWithOutLastRecord)
        setDiagramRecord(diagramWithOutLastRecord)

        setTrace(traceWithOutLastRecord[traceWithOutLastRecord.length - 1])
        setDiagram(diagramWithOutLastRecord[diagramWithOutLastRecord.length - 1])
    }


    const onInit = async () => {
        setIsLoadingData(true)
        const [traceResult, riskResult] = await Promise.all([
            props.traceStore.getTrace(Number(param.traceId)),
            props.riskStore.getRisk(Number(param.riskId))
        ])
        if (traceResult.isError || riskResult.isError) return props.appStore.openApiErrorAlert()
        setRiskName(riskResult.data.name)
        setTraceTitle(traceResult.data.title)
        setChangesHandler(addOnDeleteHandlersToDiagram(traceResult.data.diagram), traceResult.data.trace)
        setIsLoadingData(false)
    }

    /// este va a ser usado en un helper
    const addOnDeleteHandlersToDiagram = (diagram: TDiagram) => {
        const newDiagram = diagram.map(element => {
            if (
                element.type === 'root' ||
                element.type === 'binary' ||
                element.type === 'bureau' ||
                element.type === 'finish' ||
                element.type === 'calculator' ||
                element.type === 'advancedCalculator' ||
                element.type === 'policy'
            ) {

                return { ...element, data: { ...element.data, onRemove: onRemoveNodeHandler } }
            }

            return element
        })

        return newDiagram

    }


    const onRemoveNodeHandler = (id: string) => {
        setNodeElementIdForRemove(id)
        setIsOpenNodeDeleteConfirmModal(true)
    }

    const onDeleteNodeConfirmHandler = () => {

        const newTrace = onRemoveNodeToTrace(trace, Number(nodeElementIdForRemove))
        const newDiagram = onRemoveNodeToDiagram(diagram, nodeElementIdForRemove)

        setChangesHandler(newDiagram, newTrace)
        setIsOpenNodeDeleteConfirmModal(false)
    }

    const onClickHandlerFromFloatPlusButton = () => {
        setFormDataForCreateNode([])

        const bureauNodeFindedFromTrace = trace.find(node => node.nodeType === 5)
        const bureauNodeFindedFromDiagram = diagram.find(element => element.type === 'bureau')
        const newNodeTypeFormScheme = nodeTypeFormScheme.map(ele => ({ ...ele }))


        if (!bureauNodeFindedFromTrace && !bureauNodeFindedFromDiagram) {
            setFormDataTypeNodeForCreate(newNodeTypeFormScheme)
        } else if (newNodeTypeFormScheme[1].type === 'options') {
            newNodeTypeFormScheme[1].options = newNodeTypeFormScheme[1].options.filter(option => option.value !== 'bureau')
            setFormDataTypeNodeForCreate(newNodeTypeFormScheme)
        }

        setIsShowNodeForm(false)
        setIsOpenCreateNodeModal(true)
    }

    const onSubmitFromSelectNodeHandler = async (form: TForm) => {
        setIsShowNodeForm(true)
        const nodeTypeSelected = getValueToForm(form, '1') as TOptionNodeType
        nodeTypeSelected === 'binary' && setFormDataForCreateNode(binaryNodeFormScheme)
        nodeTypeSelected === 'finish' && setFormDataForCreateNode(addCurrencyCountryLimitsToFinishNodeSchema(finishNodeFormScheme))
        nodeTypeSelected === 'calculator' && setFormDataForCreateNode(calculatorNodeFormScheme)
        nodeTypeSelected === 'advancedCalculator' && setFormDataForCreateNode(advancedCalculatorNodeFormScheme)
        nodeTypeSelected === 'bureau' && setFormDataForCreateNode(callBureauNodeFormScheme)

        if(nodeTypeSelected === 'policy' ) {
            props.appStore.showLoader()
            const getRiskListResult = await props.riskStore.getRiskList()
            if(getRiskListResult.isSuccess) {
                const getRiskListResult = await props.riskStore.getRiskList()
                setFormDataForCreateNode(addRiskPolicyListToCallPolicyRiskNodeSheme(getRiskListResult.data, [...callPolicyNodeFormScheme]))
            }else {
                props.appStore.openApiErrorAlert()
            }
            props.appStore.hideLoader()
        } 
    }


    const onSubmitFromCreateNodeHandler = (form: TForm) => {

        const nodeTypeSelected = getValueToForm(formDataTypeNodeForCreate, '1') as TOptionNodeType
        const idEnabled = createIdEnabled(diagram)

        const newBoxDiagram: IBox = createBox(nodeTypeSelected, idEnabled, screenCoordinates)
        
        newBoxDiagram.data.onRemove = onRemoveNodeHandler
        
        const newNodeTrace = createNodeTrace(nodeTypeSelected, form, idEnabled)

        setChangesHandler([...diagram, newBoxDiagram], [...trace, newNodeTrace])
        setIsOpenCreateNodeModal(false)
    }

    const onSubmitFromUpdateNodeHandler = (form: TForm) => {
        const newNode = createUpdateTraceNode(form, nodeSelectedForUpdate)
        setChangesHandler(diagram, trace.map(node => node.id === newNode.id ? newNode : node))
        setIsOpenUpdateNodeModal(false)
    }

    const onNodeDoubleClickHandler = async (nodeId: string) => {
        const nodeFinded = trace.find(node => node.id === Number(nodeId))
        if (!nodeFinded) return


        if(nodeFinded.nodeType === 3 ) {
            props.appStore.showLoader()
            const getRiskListResult = await props.riskStore.getRiskList()
            
        
            if(getRiskListResult.isSuccess) {

                const preloadNodeFormData = addRiskPolicyListToCallPolicyRiskNodeSheme(getRiskListResult.data,getPreloadDataToNodeFormData(nodeFinded))
                const riskIdSelected: number = Number(getValueToForm(preloadNodeFormData, '3'))
                const getdinamicFormsResult = await props.riskStore.getRisk(riskIdSelected)
                if(getdinamicFormsResult.isError) {
                    props.appStore.openApiErrorAlert()
                    props.appStore.hideLoader()
                    return
                }

                const dinamicFieldsCreatedForInputGenerator = mapFieldGroupToIForm(getdinamicFormsResult.data.fields_group)
                const preloadDinamicFieldsCreatedForInputGenerator = preloadFormDataValuesForPolicyNodeForm(dinamicFieldsCreatedForInputGenerator, nodeFinded)
                
                setFormDataForUpdateNode([...preloadNodeFormData, ...preloadDinamicFieldsCreatedForInputGenerator])
                setNodeSelectedForUpdate(nodeFinded)

                setIsOpenUpdateNodeModal(true)
                props.appStore.hideLoader()


            }else {
                props.appStore.openApiErrorAlert()
                props.appStore.hideLoader()
            }
            
          
            return
        } else {
            const preloadNodeFormData = getPreloadDataToNodeFormData(nodeFinded)
            setFormDataForUpdateNode(preloadNodeFormData)
            setNodeSelectedForUpdate(nodeFinded)
            setIsOpenUpdateNodeModal(true)
        } 
    }

    const onRemoveLineHandler = (elements: TDiagram) => {
        setIsOpenConectionDeleteConfirmModal(true)
        setElementsForRemove(elements)
    }

    const onConnectElementsHandler = (line: ILineConector) => {
        setChangesHandler([...diagram, line], addNodeConnectionUsingElement(line, trace))
    }

    const onSaveConfirmHandler = async () => {

        setIsLoadingData(true)

        const traceResult = await props.traceStore.getTrace(Number(param.traceId))

        if (traceResult.isError) {
            props.appStore.openApiErrorAlert()
        }

        if (traceResult.isSuccess) {
            const newTrace: ITrace = { ...traceResult.data, diagram: diagram, trace: trace, title: traceTitle }
            await props.traceStore.updateTrace(newTrace)
            setIsOpenSaveConfirmModal(false)
        }

        setIsLoadingData(false)
    }

    const onDeleteConectionConfirmHandler = async (): Promise<void> => {
        const newTrace = removeNodeConectionUsingElements(elementsForRemove, trace)
        const rootElementFinded = elementsForRemove.find((element: any) => element.type === "root")
        if (rootElementFinded) return
        const elementsFiltered = diagram.filter(element => !elementsForRemove.find((elementToRemove: any) => elementToRemove.id === element.id))
        setChangesHandler(elementsFiltered, newTrace)
        setIsOpenConectionDeleteConfirmModal(false)
    }

    const onClickSaveFloatButtonHandler = () => setIsOpenSaveConfirmModal(true)

    const onMoveNodeHandler = (newDiagram: TDiagram) => setChangesHandler(newDiagram, trace)

    const onMoveScreenHandler = (params: { x: number, y: number }) => {
        setScreenCoordinates(params)
    }


    const onUpdateNodeActionHandler = (key: string) => {
        if (key === 'duplicate') onDuplicateHandler()
    }

    const onDuplicateHandler = () => {
        const newNodeDuplicated = { ...nodeSelectedForUpdate }
        const nodeType: TOptionNodeType = getNodeTypeForDiagram(newNodeDuplicated)
        const idEnabled = createIdEnabled(diagram)
        const newBoxDiagram: IBox = createBox(nodeType, idEnabled, screenCoordinates)
        newBoxDiagram.data.onRemove = onRemoveNodeHandler
        const newNodeTrace = duplicateTraceNode(newNodeDuplicated, idEnabled)
        setChangesHandler([...diagram, newBoxDiagram], [...trace, newNodeTrace])
        setIsOpenUpdateNodeModal(false)
    }

    const onChangeTitleHandler = (title: string) => {
        setTraceTitle(title)
    }

    const onCloseCreateNodeFormHandler = () => {
        setIsOpenCreateNodeModal(false)
    }

    return (
        <div>
            <Loader isOpen={isLoadingData} />

            <Modal
                size="S"
                title=""
                level="low"
                isOpen={isOpenNodeDeleteConfirmModal}
                onClose={() => setIsOpenNodeDeleteConfirmModal(false)}
            >
                <ModalContent>
                    <CenterText>
                        <Title>Confirmar</Title>
                        <Text>¿Quieres Eliminar el nodo?</Text>
                        <br />
                        <Button data-testid="confirmDeleteElementButton" onClick={onDeleteNodeConfirmHandler}>Aceptar</Button>
                    </CenterText>
                    <br />
                </ModalContent>
            </Modal>


            <Modal
                size="S"
                title=""
                level="low"
                isOpen={isOpenConectionDeleteConfirmModal}
                onClose={() => setIsOpenConectionDeleteConfirmModal(false)}
            >
                <ModalContent>
                    <CenterText>
                        <Title>Confirmar</Title>
                        <Text>¿Quieres Eliminar la linea?</Text>
                        <br />
                        <Button data-testid="confirmDeleteElementButton" onClick={onDeleteConectionConfirmHandler}>Aceptar</Button>
                    </CenterText>
                    <br />
                </ModalContent>
            </Modal>

            <Modal
                size="S"
                title=""
                level="low"
                isOpen={isOpenSaveConfirmModal}
                onClose={() => setIsOpenSaveConfirmModal(false)}
            >
                <ModalContent>
                    <CenterText>
                        <Title>Confirmar</Title>
                        <Text>¿Quieres guardar los cambios en esta traza?</Text>
                        <br />
                        <Button data-testid="confirmSaveChangesButton" onClick={onSaveConfirmHandler}>Aceptar</Button>
                    </CenterText>
                    <br />
                </ModalContent>
            </Modal>
            <Modal
                size="M"
                level="low"
                isOpen={isOpenCreateNodeModal}
                onClose={() => onCloseCreateNodeFormHandler()}
                title=""
            >
                <ModalContent>
                    <FormGenerator
                        dataTestId="forSelectTypeNode"
                        form={formDataTypeNodeForCreate}
                        setForm={setFormDataTypeNodeForCreate}
                        onSubmit={onSubmitFromSelectNodeHandler}
                        submitText="Seleccionar"
                        isShowSubmit={false}
                        actions={[]}
                        onAction={() => { }}
                        onValidForm={() => { }}
                        onInvalidForm={() => { }}
                    />
                    <br />
                    {isShowNodeForm ? (
                        <FormGenerator
                            dataTestId="forCreateNode"
                            form={formDataForCreateNode}
                            setForm={setFormDataForCreateNodeHandler}
                            onSubmit={onSubmitFromCreateNodeHandler}
                            submitText="Crear Nodo"
                            actions={[]}
                            onAction={() => { }}
                            isShowSubmit={true}
                            onValidForm={() => { }}
                            onInvalidForm={() => { }}
                        />
                    ) : null}
                    <br></br>
                </ModalContent>
            </Modal>
            <Modal 
                title="" 
                size="M" 
                level="low" 
                isOpen={isOpenUpdateNodeModal} 
                onClose={() => setIsOpenUpdateNodeModal(false)}
            >
                <ModalContent>
                    <FormGenerator
                        dataTestId="forUpdateNode"
                        form={formDataForUpdateNode}
                        setForm={setFormDataForUpdateNodeHandler}
                        onSubmit={onSubmitFromUpdateNodeHandler}
                        submitText="Guardar Cambios"
                        isShowSubmit={nodeSelectedForUpdate.nodeType !== 0}
                        actions={nodeSelectedForUpdate.nodeType !== 5 && nodeSelectedForUpdate.nodeType !== 0 ? [{ label: 'Duplicar', key: 'duplicate' }] : []}
                        onAction={onUpdateNodeActionHandler}
                        onValidForm={() => { }}
                        onInvalidForm={() => { }}
                    />
                    <br />

                </ModalContent>
            </Modal>
            <EaseInAnimation>
                <FloatSaveButton data-testid="FloatSaveButton" onClick={onClickSaveFloatButtonHandler} />
                <FloatPlusButton data-testid="FloatPlusButton" onClick={onClickHandlerFromFloatPlusButton} />
                <FloatTitle>{riskName}(#{param.riskId})</FloatTitle>
                <FloatBackButton data-testid="FloatBackButton" onClick={onBackRecordHandler} />

                <div style={{ height: '90vh', background: '' }}>
                    <DiagramComponent
                        title={traceTitle}
                        onChangeTitle={onChangeTitleHandler}
                        onMoveScreen={onMoveScreenHandler}
                        diagram={diagram}
                        onMoveNode={onMoveNodeHandler}
                        onNodeDoubleClick={onNodeDoubleClickHandler}
                        onRemoveLine={onRemoveLineHandler}
                        onConnectElements={onConnectElementsHandler}
                    />
                </div>
            </EaseInAnimation>
        </div>
    )
}