import React from 'react'
import { IRiskStore } from '../stores/risk/risk.interface.store'

import { ITableGeneratorComponent, IToolOption, TableGeneratorComponent, TItem } from 'table-generator'
import { EaseInAnimation, FloatPlusButton, FormWrapper, Loader, Modal, Title } from '../elements/Element'
import { useHistory } from 'react-router'
import { createRiskFormScheme } from '../schemes/risk.form.sheme'
import { TForm, FormGenerator, getValueToForm  } from 'FormGenerator'
import { IRisk } from '../stores/risk/risk.model.store'
import { IAppStore } from '../stores/app/app.interface.store'

interface IRiskListPage {
    riskStore: IRiskStore
    appStore: IAppStore
}

export const RiskListPage = (props: IRiskListPage) => {

    const history = useHistory()
    React.useEffect(() => { onInit() }, [])

    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [isOpenCreateRiskModal, setIsOpenCreateRiskModal] = React.useState<boolean>(false)
    const [createRiskForm, setCreateRiskForm] = React.useState<TForm>([...createRiskFormScheme])

    const onInit = async () => {
        setIsLoading(true)
        const result = await props.riskStore.getRiskList()
        if (result.isError) props.appStore.openApiErrorAlert()
        setIsLoading(false)
    }

    const onActionTableHandler = async (type: string, key: string) => {
        type === 'edit' && history.push(`/risk/${key}`)
        type === 'params' && history.push(`/risk/${key}/params`)
        type === 'traze' && history.push(`/risk/${key}/trace`)
        type === 'execute' && history.push(`/risk/${key}/execute`)
        // type === 'forms' && history.push(`/risk/${key}/forms`)
        // type === 'copy' && copyRiskHandler(Number(key))
        // type === 'activate' && activateRiskHandler(Number(key), 'activate')
        // type === 'deactivate' && activateRiskHandler(Number(key), 'deactivate')
    }

    // const activateRiskHandler = async (riskId: number, action: 'activate' | 'deactivate') => {
    //     setIsLoading(true)
    //     const riskObrainedResult = await props.riskStore.getRisk(riskId)
    //     if (riskObrainedResult.isError) {
    //         props.appStore.openApiErrorAlert()
    //         setIsLoading(false)
    //         return
    //     }

    //     const riskUpdatedResult = await props.riskStore.updateRisk({
    //         ...riskObrainedResult.data,
    //         is_active: action === 'activate' ? true : false
    //     })

    //     if (riskUpdatedResult.isError) {
    //         props.appStore.openApiErrorAlert()
    //         setIsLoading(false)
    //         return
    //     }

    //     setIsLoading(false)
    //     return
    // }


    // const copyRiskHandler = async (riskId: number) => {
    //     setIsLoading(true)
    //     const result = await props.riskStore.copyRisk(riskId)
    //     if (result.isError) props.appStore.openApiErrorAlert()
    //     if (result.isSuccess) {

    //     }
    //     setIsLoading(false)
    // }

    const bodyRowsFromRiskList: ITableGeneratorComponent['bodyList'] = props.riskStore.riskList.map(risk => {

        const newRow: Array<TItem> = [
            {
                type: 'text',
                text: String(risk.id)
            },
            {
                type: 'text',
                text: risk.type === 1 ? "Primaria" : "Secundaria"
            },
            {
                type: 'text',
                text: risk.name
            },
            {
                type: 'text',
                text: risk.description
            },
            {
                type: 'actions',
                key: String(risk.id),
                actions: [
                    {
                        type: 'edit',
                        text: 'Editar Datos',
                        disabled: false,
                    },
                    // {
                    //     type: 'forms',
                    //     text: 'Editar Formularios'
                    // },
                    {
                        type: 'traze',
                        text: 'Editar Traza',
                        disabled: false,
                    },
                    // {
                    //     type: 'copy',
                    //     text: 'Duplicar'
                    // }
                    {
                        type: 'execute',
                        text: 'Ejecutar Traza',
                        disabled: risk.active_trace_id ? false : true
                    }
                ]
            }   
        ]

        return newRow
    })

    const onClickToCreateNewRiskButtonHandler = () => setIsOpenCreateRiskModal(true)

    const onSubmitCreateRiskFormHandler = async () => {

        const newRisk: IRisk = {
            id: 0,
            name: getValueToForm(createRiskForm, '2'),
            description: getValueToForm(createRiskForm, '3'),
            type: getValueToForm(createRiskForm, '4') === '1' ? 1 : 2,
            schema: [],
            fields_group: [],
            active_trace_id: 0,
            is_active: false
        }

        setIsLoading(true)
        const result = await props.riskStore.createRisk(newRisk)
        if (result.isError) props.appStore.openApiErrorAlert()
        if (result.isSuccess) setCreateRiskForm([...createRiskFormScheme])

        setIsOpenCreateRiskModal(false)
        setIsLoading(false)

    }

    return (
        <div>
            <Loader isOpen={isLoading} />
            <Modal title="" level="low" size="M" isOpen={isOpenCreateRiskModal} onClose={() => setIsOpenCreateRiskModal(false)}>
                <FormWrapper>
                    <Title>Crear política de riesgo</Title>
                    <br/>
                    <FormGenerator
                        dataTestId='createRiskForm'
                        form={createRiskForm}
                        setForm={setCreateRiskForm}
                        onSubmit={onSubmitCreateRiskFormHandler}
                        submitText="Guardar"
                        isShowSubmit={true}
                        actions={[]}
                        onAction={() => { }}
                        onValidForm={() => { }}
                        onInvalidForm={() => { }}
                    />
                </FormWrapper>
            </Modal>
            <EaseInAnimation>
                <br></br><br></br>
                <Title>Lista de Políticas Riesgos</Title>
                <TableGeneratorComponent
                    headerList={['Id', 'Tipo', 'Nombre', 'Descripción', 'Acciones']}
                    bodyList={bodyRowsFromRiskList}
                    onAction={onActionTableHandler}
                    paginationList={[]}
                    tools={[
                        {
                            id: 1,
                            label: 'Tipo',
                            value: 'all',
                            options: [
                                
                                {
                                    label: 'Todos',
                                    value: 'all'
                                },
                                {
                                    label: 'Primarias',
                                    value: 'primarys'
                                },
                                {
                                    label: 'Secundarias',
                                    value: 'secundarys'
                                }
                            ],
                            validator: (valueSelected: string, row: Array<TItem>) => {
                                const item=row[1]
                                if(item.type !== 'text') return true
                                if(valueSelected === 'all') return true
                                if(valueSelected === 'primarys' && item.text === 'Primaria') return true
                                if(valueSelected === 'secundarys' && item.text === 'Secundaria') return true
                                return false
                            }
                        }
                    ]}
                />
                <FloatPlusButton onClick={onClickToCreateNewRiskButtonHandler} />
                <br></br><br></br><br></br>
            </EaseInAnimation>
        </div>
    )
}