import { TForm } from "FormGenerator";
import { IExecuted, IJsonField, INumberField, IOptionField, IRisk, IStringField } from "../stores/risk/risk.model.store";
import { mapFieldGroupToIForm } from "./EditTrace.helper";

export const getPreloadExecuteParamsFormFromRisk = (risk: IRisk, executed: IExecuted): TForm => {
    const newFormData = mapFieldGroupToIForm(risk.fields_group)
    const inputData: any = { ...executed.inputData }

    const executedInputDataList: Array<{ id: number, name: string; value: any; }> = Object.keys(inputData).map(key => {

        const allFieldList: Array<INumberField | IStringField | IOptionField | IJsonField> = risk.fields_group.reduce((previousValue, currentValue)=>{
            currentValue.fields.forEach(field=> previousValue.push(field))
            return previousValue

        }, [] as  Array<INumberField | IStringField | IOptionField | IJsonField>)

        const schemaItemFinded = allFieldList.find(item => item.name === key)
        return {
            id: schemaItemFinded ? schemaItemFinded.id : 0,
            name: key,
            value: inputData[key]
        }
    })

    const newFormDataPreloaded: TForm = newFormData.map(input => {
        if ( input.type !== 'title' && input.type !== 'message') {
            const inputDataFinded = executedInputDataList.find(inputData => inputData.name === input.label)
            return {
                ...input,
                value: inputDataFinded ? String(inputDataFinded.value) : ''
            }
        } else {
            return input
        }
    })

    return newFormDataPreloaded
}

export const getParamsFromExecuteParamsForm = (scheme: TForm): Array<{
    type: "string" | "number" | "json"
    key: string
    value: string
}> => scheme.reduce((acumulator, current) => {
    if (current.type === 'text') {
        acumulator.push({
            key: current.label,
            value: current.value,
            type: "string"
        })
        return acumulator
    }

    if (current.type === 'number') {
        acumulator.push({
            key: current.label,
            value: current.value,
            type: "number"
        })

        return acumulator
    }

    if(current.type === 'code') {
        acumulator.push({
            key: current.label,
            value: current.value,
            type: "json"
        })

        return acumulator
    }

    if(current.type === 'options') {
        acumulator.push({
            key: current.label,
            value: current.value,
            type: "string"
        })
    }

    return acumulator

}, [] as Array<{
    key: string
    value: string
    type: "string" | "number" | 'json'
}>)
