import { FormGenerator, getValueToForm, TForm } from 'FormGenerator'
import { FC, useState } from 'react'
import { CenterText, EaseInAnimation, Modal, ModalContent, Title, Text } from '../elements/Element'
import { executeBureauMexicoPFAErequestFactory, executeBureauMexicoPFrequestFacory, executeBureauMexicoPMrequestFactory, getRFCfromCurp } from '../helpers/Bureau.helper'
import { bureauPersonTypeCheme, bureauPFAEsheme, bureauPFsheme, bureauPMSheme, emailSheme, nipSheme } from '../schemes/bureau.form.scheme'
import { IAppStore } from '../stores/app/app.interface.store'
import { IBureauStore } from '../stores/bureau/bureau.interface.store'
import ReactJson from 'react-json-view'
import { IExecuteBureauMexicoPFrequest, IExecuteBureauMexicoPMrequest, TExecuteBureauMexicoPFAErequest } from '../stores/bureau/bureau.model.store'
import { useFile } from '../utils/useFile.util'
import { timer } from '../utils/timer'

interface BureauPageProps {
    bureauStore: IBureauStore
    appStore: IAppStore
}

export const BureauPage: FC<BureauPageProps> = props => {

    const file = useFile()

    const [personTypeFormData, setFersonTypeFormData] = useState<TForm>([...bureauPersonTypeCheme])
    const [currentFormData, setCurrentFormData] = useState<TForm>([])

    const [isOpenRFCisNotValidAlert, setIsOpenRFCisNotValidAlert] = useState<boolean>(false)
    const [isNIPisNotValiedAlert, setIsNIPisNotValiedAlert] = useState<boolean>(false)

    const [isOpenNipForm, setIsOpenNipForm] = useState<boolean>(false)
    const [nipFormData, setNipFormData] = useState<TForm>([...nipSheme])

    const [isOpenEmailForm, setIsOpenEmailForm] = useState<boolean>(false)
    const [emailFormData, setEmailFormData] = useState<TForm>([...emailSheme])

    const personTypeValue = getValueToForm(personTypeFormData, '1')

    const onChangeCurrentFormData = (form: TForm) => {
        
        if (personTypeValue === 'PF' || personTypeValue === 'PFAE') {

            const delegacionMunicipioValue = getValueToForm(form, '9')
            const cityValue = getValueToForm(form, '10')



            const newFormData = form.map(input=> {
                if(input.id === '9') { //delegacionMunicipioValue
                    return {
                        ...input, 
                        required: cityValue ? false : true,
                        placeholder: !cityValue && !delegacionMunicipioValue ? 'Campo requerido...' : '',
                        error: cityValue === '' && delegacionMunicipioValue === '' ? false : !cityValue && !delegacionMunicipioValue ? true : false
                    }
                }
                if(input.id === '10') { // cityValue
                    return {
                        ...input, 
                        required: delegacionMunicipioValue ? false : true,
                        placeholder: !cityValue && !delegacionMunicipioValue ? 'Campo requerido...' : '',
                        error: cityValue === '' && delegacionMunicipioValue === '' ? false : !cityValue && !delegacionMunicipioValue ? true : false
                    }
                } 

                return input
            })

            setCurrentFormData(newFormData)
            return 

        }

        if (personTypeValue === 'PM') {

            const delegacionMunicipioValue = getValueToForm(form, '6')
            const cityValue = getValueToForm(form, '7')

            const newFormData = form.map(input=> {
                if(input.id === '6') { //delegacionMunicipioValue
                    return {
                        ...input, 
                        required: cityValue ? false : true,
                        placeholder: !cityValue && !delegacionMunicipioValue ? 'Campo requerido...' : '',
                        error: cityValue === '' && delegacionMunicipioValue === '' ? false : cityValue && delegacionMunicipioValue ? false: !cityValue && !delegacionMunicipioValue ? true : false
                    }
                }
                if(input.id === '7') { // cityValue
                    return {
                        ...input, 
                        required: delegacionMunicipioValue ? false : true,
                        placeholder: !delegacionMunicipioValue ? 'Campo requerido...' : '',
                        error: cityValue === '' && delegacionMunicipioValue === '' ? false : !cityValue && !delegacionMunicipioValue ? true : false
                    }
                } 

                return input
            })

            setCurrentFormData(newFormData)
            return
        }

        setCurrentFormData(form)
    }

    const onPersonTypeFormValid = () => {
        if (personTypeValue === 'PF') return setCurrentFormData([...bureauPFsheme])
        if (personTypeValue === 'PFAE') return setCurrentFormData([...bureauPFAEsheme])
        if (personTypeValue === 'PM') return setCurrentFormData([...bureauPMSheme])
    }

    const onSubmitCurrentFormDataHandler = async () => {
        props.appStore.showLoader()
        const verifyNipResult = await props.bureauStore.verifyNip({ rfc: getValueToForm(currentFormData, '5') })
        props.appStore.hideLoader()
        if (verifyNipResult.isError) props.appStore.openApiErrorAlert()

        if (verifyNipResult.isError) return props.appStore.openApiErrorAlert()

        if (verifyNipResult.data === 'nip is not verified') {
            setIsOpenEmailForm(true)
            return
        }
        if (verifyNipResult.data === 'rfc is not valid') {
            setIsOpenRFCisNotValidAlert(true)
            return
        }

        if (verifyNipResult.data === 'nip is verified') {
            executeBureauFacade()
            return
        }
    }

    const onSubmitEmailFormHandler = async () => {

        props.appStore.showLoader()
        const result = await props.bureauStore.generateNip({
            rfc: getValueToForm(currentFormData, '5'),
            email: getValueToForm(emailFormData, '1')
        })
        props.appStore.hideLoader()

        if (result.isError) {
            props.appStore.openApiErrorAlert()
            return
        }

        if (result.isSuccess && result.data === 'nip is generated') {
            setIsOpenEmailForm(false)
            setIsOpenNipForm(true)
            return
        }

        if (result.isSuccess && result.data === 'rfc is not valid') {
            setIsOpenEmailForm(false)
            setIsOpenRFCisNotValidAlert(true)
            return
        }
    }

    const onSubmitNipFormHandler = async () => {
        props.appStore.showLoader()
        const result = await props.bureauStore.validateNip({
            nip_number: getValueToForm(nipFormData, '1'),
            rfc: getValueToForm(currentFormData, '5')
        })
        props.appStore.hideLoader()

        if(result.isError) {
            props.appStore.openApiErrorAlert()
            return
        }

        if(result.data === 'nip is not valid') {
            setIsNIPisNotValiedAlert(true)
            return
        }

        if(result.data === 'nip is valid') {
            setIsOpenNipForm(false)
            executeBureauFacade()
            return
        }
    }

    const executeBureauFacade = async () => {
        await timer(500)
        
        if (personTypeValue === 'PF') {
            const PFrequest : IExecuteBureauMexicoPFrequest = executeBureauMexicoPFrequestFacory(currentFormData)
            props.appStore.showLoader()
            const executeBureauMexicoPFresult = await props.bureauStore.executeDownloadBureauMexicoPF(PFrequest)
            props.appStore.hideLoader()
            if(executeBureauMexicoPFresult.isError) props.appStore.openApiErrorAlert()
            if(executeBureauMexicoPFresult.isSuccess) {
                const newFileName = `${PFrequest.primerNombre}-${PFrequest.apellidoPaterno}-${PFrequest.rfc}`
                file.downloadFile(new Blob([executeBureauMexicoPFresult.data]), `${newFileName}.pdf`)
            }
            return
        }

        if (personTypeValue === 'PFAE') {
            const PFAErequest: TExecuteBureauMexicoPFAErequest = executeBureauMexicoPFAErequestFactory(currentFormData)
            props.appStore.showLoader()
            const executeBureauMexicoPFAEresult = await props.bureauStore.executeDownloadBureauMexicoPFAE(PFAErequest)
            props.appStore.hideLoader()
            if(executeBureauMexicoPFAEresult.isError) props.appStore.openApiErrorAlert()
            if(executeBureauMexicoPFAEresult.isSuccess) {
                const newFileName = `${PFAErequest.primerNombre}-${PFAErequest.apellidoPaterno}-${PFAErequest.rfc}`
                file.downloadFile(new Blob([executeBureauMexicoPFAEresult.data]), `${newFileName}.pdf`)
            }
            return
        }

        if (personTypeValue === 'PM') {
            const PMrequest:IExecuteBureauMexicoPMrequest = executeBureauMexicoPMrequestFactory(currentFormData)
            props.appStore.showLoader()
            const executeBureauMexicoPMresult = await props.bureauStore.executeDownloadBureauMexicoPM(PMrequest)
            props.appStore.hideLoader()
            if(executeBureauMexicoPMresult.isError) props.appStore.openApiErrorAlert()
            if(executeBureauMexicoPMresult.isSuccess) {
                const newFileName = `${PMrequest.nombre}-${PMrequest.rfc}`
                file.downloadFile(new Blob([executeBureauMexicoPMresult.data]), `${newFileName}.pdf`)
            }
            return
        }
    }

    return (
        <EaseInAnimation>

            <Modal
                size="S"
                level='high'
                title=""
                isOpen={isOpenRFCisNotValidAlert}
                onClose={() => setIsOpenRFCisNotValidAlert(false)}
            >
                <ModalContent>
                    <CenterText>
                        <Title>Error</Title>
                        <Text>El RFC es inválido.</Text>
                        <br />
                    </CenterText>
                    <br />
                </ModalContent>
            </Modal>

            <Modal
                size="S"
                level='high'
                title=""
                isOpen={isNIPisNotValiedAlert}
                onClose={() => setIsNIPisNotValiedAlert(false)}
            >
                <ModalContent>
                    <CenterText>
                        <Title>Error</Title>
                        <Text>El NIP es inválido.</Text>
                        <br />
                    </CenterText>
                    <br />
                </ModalContent>
            </Modal>
            <Modal
                size="S"
                level='low'
                title=""
                isOpen={isOpenNipForm}
                onClose={() => setIsOpenNipForm(false)}
            >
                <ModalContent>
                    <FormGenerator
                        dataTestId="nipForm"
                        form={nipFormData}
                        setForm={setNipFormData}
                        onSubmit={() => onSubmitNipFormHandler()}
                        submitText="Enviar NIP"
                        isShowSubmit={true}
                        actions={[]}
                        onAction={() => { }}
                        onValidForm={() => { }}
                        onInvalidForm={() => { }}
                    />
                    <br />
                </ModalContent>
            </Modal>


            <Modal
                size="S"
                level='low'
                title=""
                isOpen={isOpenEmailForm}
                onClose={() => setIsOpenEmailForm(false)}
            >
                <ModalContent>
                    <FormGenerator
                        dataTestId="nipForm"
                        form={emailFormData}
                        setForm={setEmailFormData}
                        onSubmit={() => onSubmitEmailFormHandler()}
                        submitText="Enviar el NIP"
                        isShowSubmit={true}
                        actions={[]}
                        onAction={() => { }}
                        onValidForm={() => { }}
                        onInvalidForm={() => { }}
                    />
                    <br />
                </ModalContent>
            </Modal>

            <br /><br />
            <Title>Bureau</Title>
            <br /><br />

            <FormGenerator
                dataTestId="personTypeFormData"
                form={personTypeFormData}
                setForm={setFersonTypeFormData}
                onSubmit={() => { }}
                submitText=""
                isShowSubmit={false}
                actions={[]}
                onAction={() => { }}
                onValidForm={() => onPersonTypeFormValid()}
                onInvalidForm={() => { }}
            />

            {personTypeValue ? (
                <FormGenerator
                    dataTestId=""
                    form={currentFormData}
                    setForm={onChangeCurrentFormData}
                    onSubmit={() => onSubmitCurrentFormDataHandler()}
                    submitText="Descargar"
                    isShowSubmit={true}
                    actions={[]}
                    onAction={() => { }}
                    onValidForm={() => { }}
                    onInvalidForm={() => { }}
                />
            ) : null}

            <br />
            <br />
            
            {props.bureauStore.result ? (
                <ReactJson src={props.bureauStore.result} collapsed={true} displayDataTypes={false} />
            ) : null}

            <br />
            <br />
            <br />
            <br />
            

        </EaseInAnimation>
    )
}