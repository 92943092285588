import React from "react";
import { useApiClient } from "../../utils/apiClient.use.util";
import { setup } from "../../utils/setup";
import { getToken, setToken } from "../../utils/token.util";
import { TActionAsyncResult, useStoreUtil } from "../../utils/useStore.util";

interface IGetSetupDataResponse {
    "panelAppUrl": string
    "signInAppUrl": string
    "authApiUrl": string
    "riskApiUrl": string
    "cookieAuthKey": string
    "country": 'México' | 'Argentina'
    "controlPanelApiUrl": string
    "bureauApiUrl": string
}

const useSetupService = () => {
    const apiClient = useApiClient();
    const getSetupData = async () => {
        const respone = await apiClient.get<IGetSetupDataResponse>(`runtime-config.json?v=${new Date().getTime()}`)
        return respone
    }

    return { getSetupData }
}

export interface ISetupStore {
    isSetupDataLoaded: boolean
    currentCountry: IGetSetupDataResponse['country']
    getSetupData: ()=> TActionAsyncResult<null>
}

type TUseSetupStore = () => ISetupStore

export const useSetupStore: TUseSetupStore = () => {

    const setupUtil = useStoreUtil()
    const setupService = useSetupService()
    const [isSetupDataLoaded, setIsSetupDataLoaded] = React.useState<boolean>(false)
    const [currentCountry, setCurrentCountry] = React.useState<ISetupStore['currentCountry']>('Argentina')

    const getSetupData = async () => {

        const response = await setupService.getSetupData()
        if(response.isError) return setupUtil.createErrorResult(null)

        setup.panelAppUrl = response.data.panelAppUrl
        setup.signInAppUrl = response.data.signInAppUrl
        setup.authApiUrl = response.data.authApiUrl
        setup.riskApiUrl = response.data.riskApiUrl
        setup.cookieAuthKey = response.data.cookieAuthKey
        setup.country = response.data.country
        setup.token = getToken(response.data.cookieAuthKey)
        setup.controlPanelApiUrl = response.data.controlPanelApiUrl
        setup.bureauApiUrl =  response.data.bureauApiUrl

        setIsSetupDataLoaded(true)
        setCurrentCountry(response.data.country)
        return setupUtil.createSuccessResult(null)
    }

    return {isSetupDataLoaded, currentCountry, getSetupData}
}