import { TApiClientResponse, useApiClient } from "../../utils/apiClient.use.util"
import { setup } from '../../utils/setup'
import { IRiskStore } from "./risk.interface.store"
import { IExecuted, IRisk } from "./risk.model.store"



export interface IOptionField {
    type: "option"
    id: number
    description: string
    name: string
    label: string
    required: boolean
    list: Array<{
      label: string
      value: string
      next_step: number
    }>
  }
  
  export interface IStringField {
    type: "string"
    id: number
    description: string
    name: string
    label: string
    required: boolean
  }
  
  export interface IJsonField {
    type: "json"
    id: number
    description: string
    name: string
    label: string
    required: boolean
  }
  
  export interface INumberField {
    type: "number"
    id: number
    description: string
    name: string
    label: string
    required: boolean
  }
  
  export interface IFieldGroup {
    id: number
    description: string
    name: string
    type: "normal"
    hidden: false
    next_step: number
    fields: Array<INumberField | IStringField | IOptionField | IJsonField>
  }
  
  // export interface IGetDinamicFormsResponse {
  //   data: Array<IFieldGroup>
  //   status: "success"
  // }
  

interface IRiskService {
    getRiskList: () => TApiClientResponse<IRisk[]>
    getRisk: (id: number) => TApiClientResponse<IRisk>
    createRisk: (risk: IRisk) => TApiClientResponse<IRisk>
    copyRisk: (risk: IRisk) => TApiClientResponse<IRisk>
    updateRisk: (risk: IRisk) => TApiClientResponse<IRisk>
    deleteRisk: (id: number) => TApiClientResponse<void>
    // getDinamicForms: (riskId: number) => TApiClientResponse<IGetDinamicFormsResponse>
    executeRisk: (riskId: number, inputs: Array<{
        "key": string
        "value": string
        "type": 'string' | 'number' | 'json'
    }>) => TApiClientResponse<IExecuted>
}

export const useRiskService = (): IRiskService => {

    const apiClient = useApiClient()
    const serviceGateWay = 'riskPolicy'

    const getRiskList: IRiskService['getRiskList'] = async () => {
        const response = await apiClient.get<IRiskStore['riskList']>(`${setup.riskApiUrl}/${serviceGateWay}/all`)
        
        return response
    }
    const getRisk: IRiskService['getRisk'] = async (id) => {
        const response = await apiClient.get<IRisk>(`${setup.riskApiUrl}/${serviceGateWay}/${id}`)
        return response
    }

    const createRisk: IRiskService['createRisk'] = async (risk) => {
        const response = await apiClient.post<IRisk>(`${setup.riskApiUrl}/${serviceGateWay}`, { name: risk.name, description: risk.description, type: risk.type })
        return response
    }

    const copyRisk: IRiskService['copyRisk'] = async (risk) => {
      const response = await apiClient.post<IRisk>(`${setup.riskApiUrl}/${serviceGateWay}`, {
            active_trace_id: 0,
            name: risk.name,
            schema: risk.schema,
            description: risk.description,
            type: risk.type,
            fields_group: risk.fields_group
        })
        return response
    }

    const updateRisk: IRiskService['updateRisk'] = async (risk) => {
        const response = await apiClient.put<IRisk>(`${setup.riskApiUrl}/${serviceGateWay}/${risk.id}`, risk)
        return response
    }

    const deleteRisk: IRiskService['deleteRisk'] = async (id) => {
        const response = await apiClient.del<void>(`${setup.riskApiUrl}/${serviceGateWay}/${id}`)
        return response
    }

    const executeRisk: IRiskService['executeRisk'] = async (riskId, inputs) => {
        const response = await apiClient.post<IExecuted>(`${setup.riskApiUrl}/executeDecisionTree`, { id: riskId, inputs: inputs })
        return response
    }


    // const getDinamicForms = async (policieId: number): TApiClientResponse<IGetDinamicFormsResponse> => {
    //     const response = await apiClient.get<IGetDinamicFormsResponse>(`${setup.riskApiUrl}/${serviceGateWay}/${policieId}`)
    //     return response
    // }

    return {getRiskList, getRisk, createRisk, updateRisk, deleteRisk, executeRisk, copyRisk}
}