import { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { ITableGeneratorComponent, TableGeneratorComponent, TItem } from 'table-generator'
import { Button, Card, Cell, CenterText, EaseInAnimation, FloatPlusButton, FloatSaveButton, Loader, Modal, ModalContent, Row, Title, Text } from '../elements/Element'
import { IAppStore } from '../stores/app/app.interface.store'
import { circuitFactory } from '../stores/circuit/circuit.helper.store'
import { ICircuitStoreObject } from '../stores/circuit/circuit.interface.store'
import { ICircuitModel } from '../stores/circuit/circuit.model.store'

interface ICircuitAddStagePageProps {
    circuitStore: ICircuitStoreObject
    appStore: IAppStore
}

export const CircuitAddStagePage: FC<ICircuitAddStagePageProps> = (props) => {

    const history = useHistory()
    const { circuitId } = useParams<{ circuitId: string }>()
    const [stageIdForDelete, setStageIdForDelete] = useState<number>(0)
    const [isOpenDeleteAlert, setIsOpenDeleteAlert] = useState<boolean>(false)


    useEffect(() => { onInit() }, [])

    const onInit = async () => {
        props.appStore.showLoader()
        const response = await props.circuitStore.getStageList()
        if (response.isError) props.appStore.openApiErrorAlert()
        props.appStore.hideLoader()
    }

    // const onClickSaveFloatButtonHandler = () => {

    // }

    const onClickHandlerFromFloatPlusButton = () => {
        history.push(`/circuit/${circuitId}/stage/create`)
    }


    const bodyList: ITableGeneratorComponent['bodyList'] = props.circuitStore.stageList.map(stage => {

        const newRow: Array<TItem> = [
            {
                type: 'text',
                text: String(stage.id)
            },
            {
                type: 'text',
                text: stage.name
            },
            {
                type: 'actions',
                key: String(stage.id),
                actions: [
                    {
                        type: 'assign',
                        text: 'Asignar',
                        disabled: false
                    },
                    {
                        type: 'modify',
                        text: 'Modificar',
                        disabled: false
                    },
                    {
                        type: 'states',
                        text: 'Estados',
                        disabled: false
                    },
                    {
                        type: 'delete',
                        text: 'Eliminar',
                        disabled: false
                    }
                ]
            }
        ]

        return newRow
    })



    const onActionTableHandler = async (type: string, key: string) => {
        if(type === 'states') {
            history.push(`/circuit/${circuitId}/stage/${key}/status`)
            return
        }
        if(type === 'delete') {
            setStageIdForDelete(Number(key))
            setIsOpenDeleteAlert(true)
            return
        }

        if(type === 'modify') {
            history.push(`/circuit/${circuitId}/stage/${key}`)
            return
        }

        if(type === 'assign') {
            props.appStore.showLoader()
            const result = await props.circuitStore.assignStageToCircuit(Number(key), Number(circuitId))
            props.appStore.hideLoader()
            if(result.isSuccess) history.push(`/circuit/${circuitId}/stage`)
            if(result.isError) props.appStore.openApiErrorAlert()
        }
    }


    const onConfirmDeleteStageHandler = async () => {
        setIsOpenDeleteAlert(false)
        props.appStore.showLoader()
        const result = await props.circuitStore.deleteStage(stageIdForDelete)
        props.appStore.hideLoader()
        if(result.isError) props.appStore.openApiErrorAlert()
    }

    return (
        <EaseInAnimation>


            <Modal
                size="S"
                title=""
                level="low"
                isOpen={isOpenDeleteAlert}
                onClose={() => setIsOpenDeleteAlert(false)}
            >
                <ModalContent>
                    <CenterText>
                        <Title>Confirmar</Title>
                        <Text>¿Quieres eliminar el stage #{stageIdForDelete}?</Text>
                        <br />
                        <Button data-testid="confirmDeleteElementButton" onClick={onConfirmDeleteStageHandler}>Aceptar</Button>
                    </CenterText>
                    <br />
                </ModalContent>
            </Modal>


            <br></br><br></br>
            <br></br><br></br>

            <Title>Stages para el circuito #{circuitId}</Title>

            <br></br><br></br>
            <br></br><br></br>

            <TableGeneratorComponent
                headerList={['Id', 'Nombre', 'Acciones']}
                bodyList={bodyList}
                onAction={onActionTableHandler}
                tools={[]}
                paginationList={[]}
            />

            <br></br><br></br>
            <br></br><br></br>
            <br></br><br></br>


            {/* <FloatSaveButton data-testid="FloatSaveButton" onClick={onClickSaveFloatButtonHandler} /> */}
            <FloatPlusButton data-testid="FloatPlusButton" onClick={onClickHandlerFromFloatPlusButton} />

        </EaseInAnimation>
    )
}