import styled from 'styled-components'

export const AlertCardElement = styled.div<{isOpen: boolean, status: 'danger' | 'success'}>`
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    bottom: ${props=>props.isOpen ? '25px' : '-150px'};
    left: 50px;
    background: ${ props=>props.status === 'success' ? '#8f8' : '#f88'};
    box-shadow: 5px 5px 5px grey;
    z-index: 999;
    transition: bottom 0.3s ease-in;
`

export const AlertCardText = styled.a`
    display: inline-block;
    padding: 10px 20px;
    font-size: 17px;
    color:#333;
`

export const AlertCardCloseButton = styled.button`
    background: none;
    border: none;
    padding: 10px 10px 10px 10px;
    font-size: 25px;
    color:#666;
    cursor: pointer;
    font-weight: lighter;
    &:before {
            content: "✖";
    }
`